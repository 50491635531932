import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbComponent from "./breadcrumbs";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { AuthContext } from "../../services/context/Auth/authContext";
import { getUserInfo, logoutService, removeToken } from "../../services/authService";
import { getActiveUserListForAdminService } from "../../services/aiService";


export const HeaderComponent = () => {

    const { setGlobalSearch, profileBGColor } = useContext(SearchContext)
    const { breadcrumbMenu } = useContext(BreadcrumbContext)
    const { loggedUserName, userGroups, userSchema, userMail, loggedUserMail, setUserMail } = useContext(AuthContext)
    const location = useLocation();

    const [showBar, setShowBar] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [userList, setUserList] = useState([]);
    const debounceDelay = 1000;

    useEffect(() => {
        const componentHeader = document.getElementById('componentHeader');
        const mainHeader = document.getElementById('vistaHeader');
        if (componentHeader) {
            if (mainHeader) {
                mainHeader.classList.add('bl-z-index-1031');
                mainHeader.classList.remove('shadow');
            }
        }
        else {
            if (mainHeader) {
                mainHeader.classList.add('shadow');
                mainHeader.classList.remove('bl-z-index-1031');
            }
        }
    }, [location.pathname])

    useEffect(() => {
        window.$(function () {
            window.$('[data-toggle="tooltip"]').tooltip()
        })
        if (breadcrumbMenu.length && breadcrumbMenu[0].hasSubMenu) {
            setShowBar(breadcrumbMenu[0].hasSubMenu)
        }
        else {
            setShowBar(false)
        }
    }, [breadcrumbMenu])

    useEffect(() => {
        const timer = setTimeout(() => {
            setGlobalSearch(searchTerm)
        }, debounceDelay);

        return () => {
            clearTimeout(timer);
        };
    }, [searchTerm]);

    useEffect(() => {
        getActiveUserListForAdmin(1)
    }, [])

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    const handleKeySearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setGlobalSearch(searchTerm)
        }
    }

    const getUserNameProfile = () => {
        const userInfo = JSON.parse(getUserInfo());
        const username = userInfo && userInfo.username ? userInfo.username : '';
        if (username) {
            const splitName = username.split(' ');
            if (splitName.length > 1) {
                return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`
            }
            else return `${splitName[0].charAt(0)}${splitName[0].charAt(1)}`
        }
        else {
            return ''
        }
    }

    const getActiveUserListForAdmin = async (page) => {
        const limit = 10;
        const obj = {
            page: page,
            pageSize: limit
        }
        const resp = await getActiveUserListForAdminService(obj);
        if (resp && resp.success) {
            setUserList(list => [...list, ...resp.data])
            if (resp.data.length >= limit) {
                getActiveUserListForAdmin(page + 1);
            }
        }
    }

    const logout = async () => {
        await logoutService();
        removeToken();
        window.location.href = "/#/login";
    }

    const logoutContent = () => {
        return (
            <div className="modal fade d-print-none" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary secondary-link" type="button" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary primary-link" data-dismiss="modal" onClick={() => logout()}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <nav className="navbar navbar-expand navbar-light topbar fixed-top mb-0 shadow vistaHeaderMargin vistaCollapsedHeaderMargin d-print-none" id="vistaHeader">

                {/* <!-- Sidebar Toggle (Topbar) --> */}
                {/* <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-0">
                    <i className="fa fa-window-maximize"></i>
                </button> */}
                {
                    showBar ? <button id="togglersubmenu" className="btn btn-link rounded-circle mr-0 text-muted" data-toggle="tooltip" data-placement="bottom" title="Hide / Open Nav Panel">
                        <i className="fa fa-bars"></i>
                    </button> : null
                }
                {/* <div><h6 className="font-weight-bold text-muted m-0">{componentName}</h6></div> */}
                <div className={showBar ? '' : 'ml-3'}>
                    <BreadcrumbComponent item={breadcrumbMenu}></BreadcrumbComponent>
                </div>


                {/* <!-- Topbar Navbar --> */}
                <ul className="navbar-nav ml-auto">

                    {/* <!-- Nav Item - Search Dropdown (Visible Only XS/Mobile) --> */}
                    <li className="nav-item dropdown no-arrow d-sm-none">
                        <button className="nav-link dropdown-toggle" id="searchDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-search fa-fw"></i>
                        </button>
                        {/* <!-- Dropdown - Messages --> */}
                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fa fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>

                    {/* <!-- Topbar Search --> */}
                    <li className="nav-item dropdown no-arrow mx-1 d-none">
                        <button className="nav-link dropdown-toggle" id="">
                            <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                <div className="input-group align-items-center search-bar">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                    <input type="text" className="form-control border-0 shadow-none bg-grey search" onChange={handleSearch} onKeyDown={handleKeySearch} placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" />
                                    {/* <div className="input-group-append">
                                        <button className="btn btn-dark" type="button">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div> */}
                                </div>
                            </form>
                        </button>
                    </li>

                    {/* <!-- Nav Item - Alerts --> */}
                    <li className="nav-item dropdown no-arrow mx-1 d-none">
                        <button className="nav-link dropdown-toggle" id="alertsDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-bell" aria-hidden="true"></i>
                            {/* <!-- Counter - Alerts --> */}
                            <span className="badge badge-danger badge-counter">3+</span>
                        </button>
                        {/* <!-- Dropdown - Alerts --> */}
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                            <h6 className="dropdown-header">
                                Alerts Center
                            </h6>
                            <button className="dropdown-item d-flex align-items-center" >
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fa fa-file-code-o text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </button>
                            <button className="dropdown-item d-flex align-items-center" >
                                <div className="mr-3">
                                    <div className="icon-circle bg-success">
                                        <i className="fa fa-usd text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </button>
                            <button className="dropdown-item d-flex align-items-center" >
                                <div className="mr-3">
                                    <div className="icon-circle bg-warning">
                                        <i className="fa fa-exclamation-triangle text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your account.
                                </div>
                            </button>
                            <button className="dropdown-item text-center small text-gray-500" >Show All Alerts</button>
                        </div>
                    </li>

                    <div className="topbar-divider d-none"></div>

                    {/* <!-- Nav Item - User Information panel --> */}
                    <li className="nav-item dropdown no-arrow">
                        <button className="nav-link dropdown-toggle" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small text-capitalize">{loggedUserMail.toLowerCase() === userMail.toLowerCase() ? loggedUserName : userMail}</span>
                            <span className="bl-profile-circle text-uppercase">{getUserNameProfile()}</span>
                            {/* <span className="bl-profile-circle text-uppercase border-0 text-white" style={{background: profileBGColor}}>{getUserNameProfile()}</span> */}
                        </button>
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <h6 className="dropdown-header bg-secondary border-0">
                                Profile Information
                            </h6>
                            <a className="dropdown-item d-flex align-items-center py-3">
                                <div className="dropdown-list-image mr-3">
                                    <div className="bl-profile-circle text-uppercase text-center">{getUserNameProfile()}</div>
                                    {/* <div className="bl-profile-circle text-uppercase text-center border-0 text-white" style={{background: profileBGColor}}>{getUserNameProfile()}</div> */}
                                    <div className="status-indicator bg-success"></div>
                                </div>
                                <div>
                                    <h6 className="mb-1"><b>{loggedUserName}</b></h6>
                                    <div className="text-truncate mb-1">{loggedUserMail}</div>
                                    {
                                        userGroups.map((group, i) => {
                                            return (
                                                <div key={`user-group-${i}`} className="text-gray-500">
                                                    <span className="badge badge-pill badge-light border"><i className="fa fa-users fa-sm fa-fw text-gray-400"></i> {group}</span>
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                            </a>
                            {
                                userList && userList.length ?
                                    <a className="dropdown-item p-0 bl-user-list">
                                        {
                                            userList.map((user, i) => {
                                                return (
                                                    <button key={`user-list-${i}`} className={`dropdown-item small ${userMail.toLowerCase() === user.userId.toLowerCase() ? 'active' : ''}`} onClick={() => setUserMail(user.userId)}>
                                                        <i className="fa fa-user fa-sm mr-2 text-gray-400"></i>
                                                        {user.userId}
                                                    </button>
                                                )
                                            })
                                        }
                                    </a> : null
                            }
                            <a className="dropdown-item text-center" data-toggle="modal" data-target="#logoutModal">Sign Out</a>
                        </div>
                    </li>

                </ul>

            </nav>
            {logoutContent()}
        </>
    )
}