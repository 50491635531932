import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";


export const AIListViewAnswerComponent = ({ list, columnSet }) => {

    const [tableItems, setTableItems] = useState([])
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    useEffect(() => {
        if(list && list.length){
            setTableItems(list)
        }
        
    }, [list])

    const arrangeColumnValue = (items) => {
        return (
            items.fundingRounds.map((item, i) => {
                return (
                    <div key={`find-round-${i}`} className="row">
                        <div className="col">
                            <p className="mb-0 text-gray">
                                <span>{item.round} </span>
                            </p>
                        </div>
                        <div className="col">
                            <p className="mb-0">{item.amountRaised}</p>
                        </div>
                        <div className="col">
                            <p className="mb-0">{item.date}</p>
                        </div>
                    </div>
                )
            })
        )
    }

    const onGlobalFilterChange = (e) => {
        const targetValue = e.target.value.toLowerCase();
        const itemList = [...list];
        const filteredArray = itemList.filter(obj => {
            const values = Object.values(obj);
            return values.some(value => value.toLowerCase().search(targetValue) != -1);
        });

        setTableItems(filteredArray)
        setGlobalFilterValue(targetValue);
    };

    const renderHeader = () => {
        return (
            <div className="d-flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText className="mr-2 form-control-sm" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search Table" />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <DataTable key={`items`} value={tableItems} header={header} >
            {
                columnSet.map((col, i) => {
                    let uniqueVal = Date.now() + Math.random();
                    return (
                        col.field === "fundingRounds" ? <Column key={`AI-List-view-${i}-${uniqueVal.toString()}`} header={col.header} body={(e) => arrangeColumnValue(e)}></Column>
                            : <Column key={`AI-List-view-${i}-${uniqueVal.toString()}`} header={col.header} field={col.field} sortable></Column>
                    )
                })
            }
        </DataTable>
    )
}