import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { SpeakerBriefContext } from "../../../services/context/speakerBriefContext";
import { getSBEventInformationService } from "../../../services/speakerBriefService";


export const SpeakerBriefEventsIndexComponent = () => {

    const location = useLocation();
    const { setEventBasicInfo, setEventDetails } = useContext(SpeakerBriefContext)

    const [eventId, setEventId] = useState(null)

    useEffect(() => {
        setEventDetails([])
        setEventBasicInfo(null)
        getActiveSectionInfo();
    }, [])

    const getActiveSectionInfo = () => {
        const pathArray = location.pathname.split('/');
        const eventInfoId = pathArray[3] ? pathArray[3].split('-')[1] : null;
        if (eventInfoId) {
            setEventId(parseInt(eventInfoId))
        }
    }

    useEffect(() => {
        if (eventId) {
            getEventInfo(1)
        }
    }, [eventId])

    const getEventInfo = async (page) => {
        const limit = 30;
        const obj = {
            page: page, limit: limit
        }
        const resp = await getSBEventInformationService(obj, eventId);
        getEventInfoResponse(page, resp, limit);

    };

    const getEventInfoResponse = (page, resp, limit) => {
        if (resp && resp.success) {
            if (page === 1) {
                setEventBasicInfo(resp.data.eventDetails)
            }
            const newEventInfo = resp.data.responses;
            setEventDetails((event) => [...event, ...newEventInfo]);
            if (resp.data.length >= limit) {
                getEventInfo(page + 1);
            }
        }
    };

    return (
        <Outlet />
    )
}