import React, { useContext, useEffect, useState } from "react";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation, useNavigate } from "react-router-dom";
import eventLabel from "./../../../utils/property-file/speakerBrief/speakerBrief.json";
import buttonsLabel from "./../../../utils/property-file/buttons-label.json";
import { SpeakerBriefContext } from "../../../services/context/speakerBriefContext";
import { toastError, toastInfo, toastSuccess } from "../../../services/notifyService";
import { createNewSBEventsService, getEventDetailsService } from "../../../services/speakerBriefService";
import { AnalyzeCompanyContext } from "../../../services/context/analyzeCompanyContext";
import taskIcon from "./../../../assets/icons/taskIcon.svg";
import ThemeContext from "../../../services/context/Theme/themeContext";
import { getAppBehaviour } from "../../../services/authService";
import { appendThemeProperty } from "../../../services/commonService";


export const SpeakerBriefManageEventsComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { SBModels, eventBasicInfo } = useContext(SpeakerBriefContext)
    const { setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const { theme } = useContext(ThemeContext)
    const nav = useNavigate()
    const location = useLocation();
    const [eventId, setEventId] = useState(null)

    const applyTheme = () => {
        const appThemes = getAppBehaviour();
        if (theme) {
            appendThemeProperty(theme, true, true)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme, true, true)
            }
        }
    }

    useEffect(() => {
        applyTheme()
        setEventId(null)
        setSelectedSection("EVENT")
        getActiveSectionInfo()
        setSections([
            { name: 'Event Details', val: "EVENT", selected: true },
            { name: 'Questions', val: "QUESTION", selected: false },
            { name: 'Optional Parameters', val: "OPTIONAL", selected: false }
        ])
        setAction("NEW")
        setBreadcrumbMenu([
            { slug: '/speaker-brief', menuName: 'Speaker Brief', isActive: true, hasSubMenu: true },
            { slug: `/speaker-brief/dashboard`, menuName: "Events", isActive: false, hasSubMenu: true },
            { slug: `/speaker-brief/events/manage`, menuName: "Manage", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    const getActiveSectionInfo = () => {
        const pathArray = location.pathname.split('/');
        if(pathArray && pathArray.length > 4){
            const eventInfoId = pathArray[3] ? pathArray[3].split('-')[1] : null;
            if(eventInfoId){
                setEventId(parseInt(eventInfoId))
            }
        }
        
    }

    const [sections, setSections] = useState([
        { name: 'Event Details', val: "EVENT", selected: true },
        { name: 'Questions', val: "QUESTION", selected: false },
        { name: 'Optional Parameters', val: "OPTIONAL", selected: false }
    ])
    const [action, setAction] = useState('NEW')
    const [selectedSection, setSelectedSection] = useState('EVENT')
    const [selectedSBModel, setSelectedSBModel] = useState({
        displayName: "", modelId: null
    })
    const [value, setValue] = useState(0);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);

    const [eventName, setEventName] = useState('')
    const [eventDetail, setEventDetail] = useState('')
    const [eventQuestion, setEventQuestion] = useState('')
    const [eventAdditionalInput, setEventAdditionalInput] = useState('')
    const [responseLength, setResponseLength] = useState(0)
    const [eventInfo, setEventInfo] = useState({
        eventName: "", createdUserName: null, createdOn: null, createdBy: null
    })

    useEffect(() => {
        if (eventBasicInfo) {
            setEventInfo(eventBasicInfo)
        }
    }, [eventBasicInfo, location.pathname])

    useEffect(() => {
        if(eventId){
            getEventDetails()
            applyTheme()
        }
    }, [eventId])

    const getEventDetails = async() => {
        const resp = await getEventDetailsService(eventId);
        if(resp && resp.success){
            const event = resp.data[0];
            if(event){
                setEventName(event.eventName)
                setEventDetail(event.eventDetails)
                setEventQuestion(event.questions)
                setEventAdditionalInput(event.additionalInputs)
                changeModelSelection(event.modelId)
                setResponseLength(event.responseLength)
            }
        }
    }

    useEffect(() => {
        if (SBModels && SBModels.length) {
            setSelectedSBModel(SBModels[0])
        }
    }, [SBModels])

    useEffect(() => {
        if (selectedSBModel) {
            setValue(eventId ? responseLength : selectedSBModel.responseLength)
            setMax(parseInt(selectedSBModel.maxRange))
            setMin(parseInt(selectedSBModel.minRange))
        }
    }, [selectedSBModel, responseLength])

    const changeSelectedSection = (index) => {
        const sec = [...sections];
        const secList = sec.map(item => {
            item.selected = false;
            return item;
        })
        secList[index].selected = true;
        setSelectedSection(secList[index].val)
        setSections(secList)
        applyTheme()
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const saveEvent = async () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (!eventName.trim()) {
                toastInfo("Please enter event Name")
            }
            if (!eventDetail.trim()) {
                toastInfo("Please enter event details")
            }
            if (!eventQuestion.trim()) {
                toastInfo("Please enter event questions")
            }
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const obj = {
                eventName: eventName,
                eventDetails: eventDetail,
                questions: eventQuestion,
                responseLength: parseInt(value),
                modelId: selectedSBModel && selectedSBModel.modelId ? selectedSBModel.modelId : null,
                additionalInputs: eventAdditionalInput ? eventAdditionalInput : null
            }
            const resp = await createNewSBEventsService(obj);
            if (resp && resp.success) {
                toastSuccess("Event created successfully")
                setAnalyzedCompanyInfo({
                    message: "Events Created"
                })
                nav('/speaker-brief/dashboard')
            }
            else {
                toastError(resp && resp.message ? resp.message : "Something went wrong, Please try again!")
            }
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
        catch (e) {
            toastError("Something went wrong, Please try again!")
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const changeModelSelection = (modelId) => {
        const model = SBModels.find(model => model.modelId === modelId);
        if(model && model.modelId){
            setSelectedSBModel(model)
        }
    } 

    const eventFormSectionContent = () => {
        return (
            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                <div className="card-body">
                    <h5 className="card-title">{eventLabel.EVENT_SECTION.TITLE}</h5>
                    <p className="card-text text-muted">{eventLabel.EVENT_SECTION.DESC}</p>
                </div>
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td width="30%"><div className="text-small pl-2">{'Name'}</div></td>
                            <td width="50%">
                                {
                                    eventId ? eventName : <input type="text" className="form-control" value={eventName} onChange={(e) => setEventName(e.target.value)} placeholder="" readOnly={eventId} />
                                }
                            </td>
                            <td>
                                <small id="passwordHelpInline" className="text-muted">
                                    {/* {eventLabel.FORMS.BASIC.TEMPLATE_VALID} */}
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top"><div className="text-small pt-2 pl-2">{'Event Details'}</div></td>
                            <td>
                                {
                                    eventId ? eventDetail : <textarea placeholder="Please type/paste event details here..." rows={20} className="form-control" value={eventDetail} onChange={(e) => setEventDetail(e.target.value)} readOnly={eventId}></textarea>
                                }
                            </td>
                            <td>
                                <small id="passwordHelpInline" className="text-muted">
                                    {/* {eventLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION_VALID} */}
                                </small>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    const questionSectionContent = () => {
        return (
            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                <div className="card-body">
                    <h5 className="card-title">{eventLabel.QUESTION_SECTION.TITLE}</h5>
                    <p className="card-text text-muted">{eventLabel.QUESTION_SECTION.DESC}</p>
                </div>
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td width="30%" valign="top"><div className="text-small pt-2 pl-2">{eventId ? 'Questions' : 'Enter Questions'}</div></td>
                            <td width="50%">
                                {
                                    eventId ? eventQuestion : <textarea placeholder="Please type/paste your list of questions here..." rows={20} className="form-control" value={eventQuestion} onChange={(e) => setEventQuestion(e.target.value)} readOnly={eventId}></textarea>
                                }
                            </td>
                            <td>
                                <small id="passwordHelpInline" className="text-muted">
                                    {/* {eventLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION_VALID} */}
                                </small>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    const optionalSectionContent = () => {
        return (
            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                <div className="card-body">
                    <h5 className="card-title">{eventLabel.OPTIONAL_SECTION.TITLE}</h5>
                    <p className="card-text text-muted">{eventLabel.OPTIONAL_SECTION.DESC}</p>
                </div>
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td width="30%" valign="top"><div className="text-small pt-2 pl-2">{'Additional Inputs'}</div></td>
                            <td width="50%">
                                {
                                    eventId ? eventAdditionalInput : <textarea placeholder="Type any additional input on topics, tone, exceptions etc..." rows={8} className="form-control" value={eventAdditionalInput} onChange={(e) => setEventAdditionalInput(e.target.value)} readOnly={eventId}></textarea>
                                }
                            </td>
                            <td>
                                <small id="passwordHelpInline" className="text-muted">
                                    {/* {eventLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION_VALID} */}
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <td><div className="text-small pl-2">{'Model'}</div></td>
                            <td>
                                <div className="form-group">
                                    <select className="form-control" defaultValue={selectedSBModel.modelId} onChange={(e) => changeModelSelection(e.target.value)} disabled={eventId}>
                                        {
                                            SBModels && SBModels.map((model, i) => {
                                                return (
                                                    <option key={`sb-models-${i}`} value={model.modelId}>{model.displayName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </td>
                            <td>
                                <small id="passwordHelpInline" className="text-muted">
                                    {/* {eventLabel.FORMS.BASIC.TEMPLATE_VALID} */}
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <td><div className="text-small pl-2">Adjust Response Length</div></td>
                            <td>
                                <input className="w-100 bl-h-2px" type="range" min={min} max={max} value={value} onChange={handleChange} disabled={eventId} />
                                <span className="font-smaller font-italic">Length: {value} words</span>
                            </td>
                            <td><p className="text-muted font-italic font-small mt-2">Max Range ({min} - {max} words)</p></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section>
                    <div className="d-sm-flex align-items-center justify-content-between bl-action-btn-sec">
                        <div className={"d-inline-flex flex-row bl-btn-grp-fixed"}>
                            {
                                !eventId ? <button className="d-sm-inline-block btn btn-sm btn-outline-primary mr-2" onClick={() => nav('/speaker-brief/dashboard')}><i className="fa fa-ban mr-1" aria-hidden="true"></i> {buttonsLabel.CANCEL}</button> : null
                            }
                            {
                                !eventId && selectedSection !== "EVENT" ? <button className="d-sm-inline-block btn btn-sm btn-primary mr-2" onClick={() => changeSelectedSection(selectedSection === "OPTIONAL" ? 1 : 0)}><i className="fa fa-arrow-left" aria-hidden="true"></i> {buttonsLabel.PREVIOUS}</button> : null
                            }
                            {
                                !eventId && selectedSection !== "OPTIONAL" ? <button className="d-sm-inline-block btn btn-sm btn-primary mr-2" onClick={() => changeSelectedSection(selectedSection === "EVENT" ? 1 : 2)}><i className="fa fa-arrow-right" aria-hidden="true"></i> {buttonsLabel.SAVE_PROCEED}</button> : null
                            }
                            {
                                !eventId && selectedSection === "OPTIONAL" ? <button className="d-sm-inline-block btn btn-sm btn-primary mr-2" onClick={() => saveEvent()}><i className="fa fa-floppy-o mr-1" aria-hidden="true"></i> {buttonsLabel.SAVE_GENERATE}</button> : null
                            }

                        </div>
                    </div>
                </section>
                <section>
                    <div className="row" id="bio">
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <div className="sidebar-brand-icon mb-2">
                                <img src={taskIcon} height="70px" />
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <h4 className="h4 text-gray-800 text-center">{eventId ? `${eventLabel.TITLE_UPDATE} ${eventInfo.eventName}` : eventLabel.TITLE }</h4>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <p>{action === "NEW" ? eventLabel.DESCRIPTION : eventLabel.DESCRIPTION_UPDATE}</p>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <div className="d-flex py-3">
                                {
                                    sections && sections.map((sec, i) => {
                                        return (
                                            <span key={`sections-badge-${i}`} className={`badge bl-sb-datatableTabs bl-chip-btn cp mr-2 ${sec.selected ? 'active' : ''}`} onClick={() => changeSelectedSection(i)}>{sec.name}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="row">
                        {
                            selectedSection === "EVENT" ? eventFormSectionContent() :
                                selectedSection === "QUESTION" ? questionSectionContent() :
                                    optionalSectionContent()
                        }

                    </div>
                </section>
            </div>
        </div>
    )
}