import React, { useContext, useEffect, useState } from "react";
import testIcon from "./../../../../assets/icons/testIcon.svg";
import testingFormLabel from "../../../../utils/property-file/testingHarness/formsLabel.json";
import reportForm from "../../../../utils/property-file/reports/resourceForm.json";
import buttonsLabel from "../../../../utils/property-file/buttons-label.json";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbContext from "../../../../services/context/Breadcrumb/breadcrumbContext";
import { toastInfo, toastSuccess, toastWarning } from "../../../../services/notifyService";
import { createTestPlanService, deleteTestPlanCasesService, getTestPlanInfoService, runTestPlanService, updateTestPlanCaseService, updateTestPlanService } from "../../../../services/testService";
import { getAIModelService } from "../../../../services/aiService";
import listViewLoader from "./../../../../assets/img/loading-animation.gif";


export const ManageTestPlanComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation()

    const [action, setAction] = useState('NEW')
    const [testPlanName, setTestPlanName] = useState('')
    const [thresholdPercentage, setThresholdPercentage] = useState('')
    const [testCaseQuestionSec] = useState({
        userQuery: "", expectedResult: ""
    })
    const [testCases, setTestCases] = useState([testCaseQuestionSec])
    const [testPlanId, setTestPlanId] = useState(null)
    const [defaultModel, setDefaultModel] = useState({
        aiModelId: null, displayName: '', model: ''
    })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(false)
        getAIModels()
        setTestPlanName('')
        setThresholdPercentage('')
        setTestCases([testCaseQuestionSec])
        setBreadcrumbMenu([
            { slug: '', menuName: 'Admin', isActive: true, hasSubMenu: true },
            { slug: `/admin/testing-harness`, menuName: "Testing Harness", isActive: false, hasSubMenu: true },
            { slug: `/admin/testing-harness/manage`, menuName: "Manage", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    useEffect(() => {
        const pathName = location.pathname.split('/');
        const planId = pathName[pathName.length - 1];
        if (planId !== 'manage') {
            setIsLoading(true)
            setTestPlanId(planId)
            setAction('UPDATE')
        }
        else {
            setTestPlanId(null)
            setAction('NEW')
        }
    }, [location.pathname])

    useEffect(() => {
        if (testPlanId) {
            setTestCases([])
            getTestPlanInfo(1)
        }
    }, [testPlanId])

    const getTestPlanInfo = async (page) => {
        const limit = 50;
        const obj = {
            page: page, limit: limit
        }
        const resp = await getTestPlanInfoService(obj, testPlanId);
        if (resp && resp.success && resp.data) {
            const planInfo = resp.data.data ? resp.data.data : resp.data;
            if(page === 1){
                setTestPlanName(planInfo.testPlanName)
                setThresholdPercentage(planInfo.passThresholdPercentage)
            }
            
            if (planInfo.testCases && planInfo.testCases.length) {
                const planSummary = planInfo.testCases.map(query => {
                    return {
                        testPlanMasterId: testPlanId,
                        testCaseMasterId: query.testCaseMasterId,
                        userQuery: query.question,
                        expectedResult: query.expectedAnswer
                    }
                })
                setTestCases((prevData) => [...prevData, ...planSummary])
            }
            setIsLoading(false)
            if(planInfo.testCases && planInfo.testCases.length === limit){
                getTestPlanInfo(page+1)
            }
        }
    }

    const setTestCaseQuestion = (ques, i) => {
        setTestCases(prevTestCases =>
            prevTestCases.map((testCase, index) =>
                index === i ? { ...testCase, userQuery: ques } : testCase
            )
        );
    }

    const setTestCaseExpectedResult = (expected, i) => {
        setTestCases(prevTestCases =>
            prevTestCases.map((testCase, index) =>
                index === i ? { ...testCase, expectedResult: expected } : testCase
            )
        );
    }

    const addNewQuestionSec = () => {
        const testCase = [...testCases];
        if (testCase[testCases.length - 1].userQuery && testCase[testCases.length - 1].expectedResult) {
            testCase.push(testCaseQuestionSec)
            setTestCases(testCase)
        }
        else {
            toastWarning("Please fill the information first!")
        }
    }

    const removeTestCase = (indexToRemove) => {
        if(testCases.length === 1){
            return toastInfo("At least one question and its expected answer are required. You cannot delete all questions!")
        }
        if (testCases[indexToRemove].testCaseMasterId) {
            deleteTestPlanCase(testCases[indexToRemove].testCaseMasterId)
        }
        setTestCases(prevTestCases =>
            prevTestCases.filter((_, index) => index !== indexToRemove)
        );
    };

    const saveTestCases = async (canRunJob) => {
        if (!testPlanName.trim()) {
            toastWarning("Please enter test plan name!");
            return
        }
        if (!thresholdPercentage.trim()) {
            toastWarning("Please enter threshold percentage!");
            return
        }
        const testCase = [...testCases];
        if (testCase[testCases.length - 1].userQuery && testCase[testCases.length - 1].expectedResult) {
            if (action === 'NEW') {
                const obj = {
                    testPlanName: testPlanName,
                    passThresholdPercentage: thresholdPercentage,
                    testCases: testCases
                }
                const resp = await createTestPlanService(obj);
                if (resp && resp.success) {
                    if (canRunJob) {
                        runTestPlan(resp.data.testPlanMasterId)
                    }
                    toastSuccess('Test Plan saved successfully!');
                    nav('/admin/testing-harness', { state: { jobExecuted: canRunJob, testPlanId: resp.data.testPlanMasterId } })
                }
            }
            else {
                const obj = {
                    testPlanName: testPlanName,
                    passThresholdPercentage: thresholdPercentage,
                    testPlanMasterId: testPlanId
                }
                const resp = await updateTestPlanService(obj);
                if (resp && resp.success) {
                    toastSuccess('Test Plan saved successfully!');
                    if (canRunJob) {
                        runTestPlan(testPlanId)
                    }
                    nav('/admin/testing-harness', { state: { jobExecuted: canRunJob, testPlanId: testPlanId } })
                }

            }
        }
        else {
            toastWarning("Please fill all the test questions and expected answers first!")
        }
    }

    const getAIModels = async () => {
        const resp = await getAIModelService();
        if (resp && resp.success) {
            const defaultModel = resp.data.find(model => model.default);
            if (defaultModel) {
                setDefaultModel(defaultModel)
            }
        }
    }

    const runTestPlan = async (planId) => {
        const obj = {
            testPlanMasterId: planId, aiModelId: defaultModel.model
        }
        toastInfo("Test Job is in progress, please wait untill job gets completed!");
        const resp = await runTestPlanService(obj)
        if (resp && resp.success) {
            toastSuccess("Test Job completed successfully, Please find your result!");
        }
    }

    const updateTestPlanCases = async (plan) => {
        const planMasterId = plan.testPlanMasterId ? plan.testPlanMasterId : testPlanId;
        if (planMasterId && plan.userQuery && plan.expectedResult) {
            const obj = {
                testCaseMasterId: plan.testCaseMasterId ? plan.testCaseMasterId : null,
                testPlanMasterId: planMasterId,
                question: plan.userQuery,
                expectedAnswer: plan.expectedResult
            }
            await updateTestPlanCaseService(obj);
        }

    }

    const deleteTestPlanCase = async (planCaseId) => {
        if (planCaseId) {
            const obj = {
                testCaseIds: [planCaseId]
            }
            await deleteTestPlanCasesService(obj)
        }
    }

    const validateNumbers = (e) => {
        if (
            e.key === 'Backspace' ||
            e.key === 'Delete' ||
            e.key === 'ArrowLeft' ||
            e.key === 'ArrowRight' ||
            e.key === 'Tab'
        ) {
            return;
        }
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="d-sm-flex align-items-center justify-content-between mb-2 bl-action-btn-sec">
                    <button onClick={() => nav('/admin/testing-harness')} className="d-sm-inline-block btn btn-sm btn-outline-primary"><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                    <div className={"d-inline-flex flex-row bl-btn-grp-fixed"}>
                        {/* {
                            action === "VIEW" ? <button data-toggle="modal" data-target={'#deleteReportModal'} className="d-sm-inline-block btn btn-sm btn-outline-primary mr-2"><i className="fa fa-trash fa-sm text-white-100"></i></button> : null
                        } */}
                        <button className="d-sm-inline-block btn btn-sm btn-outline-primary mr-2" onClick={() => saveTestCases(false)}>{buttonsLabel.SAVE}</button>
                        <button className="d-sm-inline-block btn btn-sm btn-primary" onClick={() => saveTestCases(true)}>{action === "NEW" ? buttonsLabel.SAVE_RUN_TEST : buttonsLabel.SAVE_RUN_TEST}</button>
                    </div>
                </div>


                <div className="row" id="bio">
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="sidebar-brand-icon mb-2">
                            <img src={testIcon} height="80px" />
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <h1 className="h3 text-gray-800">{action === "NEW" ? testingFormLabel.TITLE : testingFormLabel.TITLE_UPDATE}</h1>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <p>{action === "NEW" ? testingFormLabel.DESCRIPTION : testingFormLabel.DESCRIPTION_UPDATE}</p>
                    </div>

                    {
                        isLoading ? <div className="col text-center">
                            <img className="bl-list-loader" src={listViewLoader} />
                        </div> : (
                            <>
                                <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                                    <div className="card-body">
                                        <h5 className="card-title">{testingFormLabel.FORMS.BASIC.TITLE}</h5>
                                        <p className="card-text">{action === "NEW" ? testingFormLabel.FORMS.BASIC.DESCRIPTION : testingFormLabel.FORMS.BASIC.DESCRIPTION}</p>
                                    </div>
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td width="30%"><div className="text-small">{testingFormLabel.FORMS.BASIC.PLAN_NAME}</div></td>
                                                <td width="40%">
                                                    <input type="text" value={testPlanName} onChange={(e) => setTestPlanName(e.target.value)} className="form-control" id="planename" placeholder="" />
                                                </td>
                                                <td>
                                                    <small id="passwordHelpInline" className="text-muted">
                                                        {testingFormLabel.FORMS.BASIC.PLAN_NAME_VALID}
                                                    </small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><div className="text-small">{testingFormLabel.FORMS.BASIC.THRESHOLD}</div></td>
                                                <td>
                                                    <input type="text" value={thresholdPercentage} onKeyDown={validateNumbers} onChange={(e) => setThresholdPercentage(e.target.value)} className="form-control" id="thresholdpercentage" placeholder="" />
                                                </td>
                                                <td>
                                                    <small id="passwordHelpInline" className="text-muted">
                                                        {testingFormLabel.FORMS.BASIC.THRESHOLD_VALID}
                                                    </small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                                    <div className="card-body">
                                        <h5 className="card-title">{testingFormLabel.FORMS.QUESTION_SECTION.TITLE}</h5>
                                        <p className="card-text">{action === "NEW" ? testingFormLabel.FORMS.QUESTION_SECTION.DESCRIPTION : testingFormLabel.FORMS.QUESTION_SECTION.DESCRIPTION}</p>
                                    </div>
                                    <table className="table mb-0">
                                        <tbody>
                                            {
                                                testCases.map((query, i) => {
                                                    return (
                                                        <tr key={`test-case-query-sec-${i}`}>
                                                            {/* <td className="text-center">
                                                    <input type="checkbox" aria-label="Checkbox for following text input" />
                                                </td> */}
                                                            <td>
                                                                <input type="text" name="question" value={query.userQuery} onChange={(e) => setTestCaseQuestion(e.target.value, i)} onBlur={() => updateTestPlanCases(query)} className="form-control" placeholder={testingFormLabel.FORMS.QUESTION_SECTION.QUES_PLACEHOLDER} />
                                                            </td>
                                                            <td>
                                                                <input type="text" name="expected" value={query.expectedResult} onChange={(e) => setTestCaseExpectedResult(e.target.value, i)} onBlur={() => updateTestPlanCases(query)} className="form-control" placeholder={testingFormLabel.FORMS.QUESTION_SECTION.EXPECTED_PLACEHOLDER} />
                                                            </td>
                                                            <td className="text-center">
                                                                {
                                                                    testCases.length === i + 1 ? <i className="fa fa-plus mr-3 cp" aria-hidden="true" onClick={() => addNewQuestionSec()}></i> : null
                                                                }
                                                                <i className="fa fa-trash cp" aria-hidden="true" onClick={() => removeTestCase(i)}></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )
                    }
                </div>


            </div>
            <div className="modal fade" id="deleteReportModal" tabIndex="-1" role="dialog" aria-labelledby="deleteReportModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteReportModalLabel">{reportForm.DELETE.TITLE}</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {reportForm.DELETE.INFO} "<b>{'title'}</b>"
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary" type="button" data-dismiss="modal">{buttonsLabel.CANCEL}</button>
                            <button type="button" className="btn btn-primary" >{buttonsLabel.YES}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}