import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { addNewTableService, getDataSourceInfoService, getDataSourceTableListService } from "../../../services/modelService";
import { ModelEntityContext } from "../../../services/context/entityContext";
import { toastError, toastInfo, toastSuccess } from "../../../services/notifyService";


export const DataEntityComponent = () => {

    const { selectedDataSource, setTableList, setIsLoading } = useContext(ModelEntityContext)
    const location = useLocation()
    const [databaseName, setDatabaseName] = useState([])
    const [schema, setSchema] = useState([])
    const [selectedDatabase, setSelectedDatabase] = useState("")
    const [selectedSchema, setSelectedSchema] = useState("")
    const [tableName, setTableName] = useState("")

    useEffect(() => {
        getDataSourceInfo()
    }, [])

    useEffect(() => {
        initiateTables()
    }, [location.pathname, selectedDataSource])

    const initiateTables = () => {
        setSelectedDatabase("")
        setSelectedSchema("")
        setTableName("")
        setTableList([])
        setIsLoading(true)
        setTimeout(() => {
            getDataSourceTables(1)
        }, 300)
    }

    const getDataSourceTables = async (page) => {
        try {
            const limit = 30;
            const obj = {
                page: page, limit: limit, dataSource: selectedDataSource
            }
            const resp = await getDataSourceTableListService(obj);
            if (resp && resp.success) {
                const _tableList = resp.data;
                setTableList((tables) => [...tables, ..._tableList]);
                setIsLoading(false)
                if (resp.data.length >= limit) {
                    getDataSourceTables(page + 1);
                }
            }
        }
        catch (e) {

        }
    }

    const getDataSourceInfo = async () => {
        const resp = await getDataSourceInfoService();
        if (resp && resp.success) {
            const listItems = resp.data;
            const _databaseName = [...new Set(listItems.map(item => item.databaseName))];
            const _schema = [...new Set(listItems.map(item => item.schema))];
            setDatabaseName(_databaseName)
            setSchema(_schema)
            setSelectedDatabase(_databaseName.length > 0 ? _databaseName[0] : "")
            setSelectedSchema(_schema.length > 0 ? _schema[0] : "")
        }
    }

    const addTable = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (!tableName.trim()) {
                return toastInfo("Please enter table name!")
            }
            if (!selectedDatabase.trim()) {
                return toastInfo("Please select a database!")
            }
            if (!selectedSchema.trim()) {
                return toastInfo("Please select a schema!")
            }
            const obj = {
                tableName: tableName.trim(),
                databaseName: selectedDatabase,
                schema: selectedSchema
            }
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const resp = await addNewTableService(obj);
            if (resp && resp.success) {
                window.$("#addtablemodal").modal('toggle');
                toastSuccess("Table added successfully!");
                initiateTables()
            }
            else {
                const message = resp && resp.message ? resp.message : "An error occurred. Please try again!";
                toastError(message)
            }
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
        catch (e) {
            const message = e && e.data && e.data.message ? e.data.message : "An error occurred. Please try again!";
            toastError(message)
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const addTableModalContent = () => {
        return (
            <div className="modal fade" id="addtablemodal" tabIndex="-1" role="dialog" aria-labelledby="addTableModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addTableModalLabel">Add New Table</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Table:</label>
                                            <input type="text" id="tablename" className="form-control" value={tableName} onChange={(e) => setTableName(e.target.value)} placeholder="Enter table name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Database:</label>
                                            <select className="form-control" id="database" value={selectedDatabase} onChange={(e) => setSelectedDatabase(e.target.value)}>
                                                <option value="" disabled>-- Select Database --</option>
                                                {
                                                    databaseName && databaseName.length && databaseName.map((db, index) => {
                                                        return <option key={`db-op-${index}`} value={db}>{db}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Schema:</label>
                                            <select className="form-control" id="database" value={selectedSchema} onChange={(e) => setSelectedSchema(e.target.value)}>
                                                <option value="" disabled>-- Select Schema --</option>
                                                {
                                                    schema && schema.length && schema.map((sc, index) => {
                                                        return <option key={`schema-op-${index}`} value={sc}>{sc}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => addTable(e)}>Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Outlet />
            {addTableModalContent()}
        </>
    )
}