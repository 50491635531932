import React, { useEffect, useState } from "react";
import ThemeContext from "./themeContext";
import { getAppBehaviour } from "../../authService";
import { appendThemeProperty } from "../../commonService";

const ThemeProvider = ({ children }) => {

    const [theme, setTheme] = useState(null);
    const [appLogo, setAppLogo] = useState("");
    const [isOpenedInIframe, setIsOpenedInIframe] = useState(false)

    useEffect(() => {
        const appConfigBehaviour = getAppBehaviour();
        if (appConfigBehaviour && appConfigBehaviour !== "undefined") {
            const behaviourInfo = JSON.parse(getAppBehaviour());
            if (behaviourInfo) {
                const themeInfo = JSON.parse(behaviourInfo.behaviour);
                setTheme(JSON.parse(behaviourInfo.behaviour))
                setAppLogo(behaviourInfo.logo)
                appendThemeProperty(themeInfo)
            }
        }
    }, [])

    return (
        <ThemeContext.Provider value={{ theme, setTheme, appLogo, setAppLogo, isOpenedInIframe, setIsOpenedInIframe }}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeProvider;