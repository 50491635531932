import React from "react";
import unauth1 from "./../assets/img/unauth1.jpg";
import unauth2 from "./../assets/img/unauth2.jpg";
import unauth3 from "./../assets/img/unauth3.jpg";
import unAuthLabel from "./../utils/property-file/unauthorize.json";


export const UnauthorizeAccessComponent = () => {

  return (
    <div id="content">
      <div className="container-fluid">

        <div className="position-relative overflow-hidden p-md-5 mb-4 text-center bg-image shadow-1-strong rounded text-white unauthorize-page">
          <div className="col-md-7 p-lg-7 mx-auto my-4 ">
            <h1 className="display-4 font-weight-normal">{unAuthLabel.TITLE}</h1>
            <p className="lead font-weight-normal">{unAuthLabel.DESC}</p>
            <p>{unAuthLabel.CONTACT}</p>
          </div>
        </div>

        <br /><br />

        <div className="container pb-4">

          <div className="row">
            <div className="col-md-4">
              <div className="border-bottom-primary mr-md-3 pt-3 px-3 pt-md-4 px-md-4 text-center text-gray-800 overflow-hidden unauthorize-card">
                <div className="my-3 py-3">
                  <h2 className="display-5">{unAuthLabel.INFO_1.TITLE}</h2>
                  <p className="lead">{unAuthLabel.INFO_1.DESC}.</p>
                </div>
                <img className="img-fluid bg-light shadow-sm mx-auto" src={unauth1} alt="unauth icon" />
              </div>
            </div>

            <div className="col-md-4">
              <div className="border-bottom-primary mr-md-3 pt-3 px-3 pt-md-4 px-md-4 text-center text-gray-800 overflow-hidden unauthorize-card">
                <div className="my-3 py-3">
                  <h2 className="display-5">{unAuthLabel.INFO_2.TITLE}</h2>
                  <p className="lead">{unAuthLabel.INFO_2.DESC}</p>
                </div>
                <img className="img-fluid bg-light shadow-sm mx-auto" src={unauth2} alt="unauth icon" />
              </div>
            </div>

            <div className="col-md-4">
              <div className="border-bottom-primary mr-md-3 pt-3 px-3 pt-md-4 px-md-4 text-center text-gray-800 overflow-hidden unauthorize-card">
                <div className="my-3 py-3">
                  <h2 className="display-5">{unAuthLabel.INFO_3.TITLE}</h2>
                  <p className="lead">{unAuthLabel.INFO_3.DESC}</p>
                </div>
                <img className="img-fluid bg-light shadow-sm mx-auto" src={unauth3} alt="unauth icon" />
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  )
}