import apiInstance from "./serviceMaster";

const reportURL = "report";
const analyticsMenuURL = "report/category";


export const getReportCategoryListService = async () => {
    try{
        return await apiInstance.get(analyticsMenuURL)
    }
    catch(e){
        return e;
    }
}

export const getReportListService = async (obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${reportURL}${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const getPinnedReportListService = async (obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${reportURL}/pinned${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const getReportDetailsService = async(id) => {
    try {
        return await apiInstance.get(`${reportURL}/${id}`)
    }
    catch(e){
        return e;
    }
}

export const getEmbedTokenService = async (groupId, reportId) => {
    try {
        return await apiInstance.get(`${reportURL}/${groupId}/${reportId}`)
    }
    catch(e){
        return e;
    }
}