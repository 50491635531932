import React, { useContext, useEffect, useState } from "react";
import testIcon from "./../../../../assets/icons/testIcon.svg";
import testingFormLabel from "../../../../utils/property-file/testingHarness/formsLabel.json";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbContext from "../../../../services/context/Breadcrumb/breadcrumbContext";
import { RunSummaryComponent } from "./runSummary";
import { RunHistoryComponent } from "./runHistory";
import { ViewTestPlanComponent } from "./viewPlan";


export const TestPlanComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation()

    const [planName, setPlanName] = useState('')
    const [testPlanId, setTestPlanId] = useState('')
    const [viewType, setViewType] = useState('DETAILS')

    useEffect(() => {
        setViewType('DETAILS')
        const pathName = location.pathname.split('/');
        const planId = pathName[pathName.length - 1];
        setTestPlanId(planId)
        setBreadcrumbMenu([
            { slug: '', menuName: 'Admin', isActive: true, hasSubMenu: true },
            { slug: `/admin/testing-harness`, menuName: "Testing Harness", isActive: false, hasSubMenu: true },
            { slug: `/admin/testing-harness/summary/${planId}`, menuName: "Summary", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    const getPlanNameFromChild = (data) => {
        setPlanName(data);
    };

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="d-sm-flex align-items-center justify-content-between mb-2 bl-action-btn-sec">
                    <button onClick={() => nav('/admin/testing-harness')} className="d-sm-inline-block btn btn-sm btn-outline-primary"><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                    {viewType === 'DETAILS' ? <button onClick={() => nav(`/admin/testing-harness/manage/${testPlanId}`)} className="d-sm-inline-block btn btn-sm btn-outline-primary"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button> : null}
                </div>
                <div className="row" id="bio">
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="sidebar-brand-icon mb-2">
                            <img src={testIcon} height="80px" />
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <h1 className="h3 text-gray-800">{testingFormLabel.TEST_PLAN}{planName}</h1>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mb-3">
                        <div className="d-flex">
                            <span className={`badge bl-chip-btn cp mr-2 ${viewType === 'DETAILS' ? 'active' : ''}`} onClick={() => setViewType('DETAILS')}>Test Plan Details</span>
                            <span className={`badge bl-chip-btn cp ${viewType === 'SUMMARY' ? 'active' : ''}`} onClick={() => setViewType('SUMMARY')}>Last Run Summary</span>
                            {/* <span className={`badge bl-chip-btn cp ${viewType === 'HISTORY' ? 'active' : ''}`}>Run History</span> */}
                        </div>
                    </div>
                    {
                        viewType === 'DETAILS' ? <ViewTestPlanComponent getPlanName={getPlanNameFromChild} /> : viewType === 'SUMMARY' ? <RunSummaryComponent getPlanName={getPlanNameFromChild} /> : <RunHistoryComponent />
                    }

                </div>

            </div>
        </div>
    )
}