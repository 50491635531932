import React, { useContext, useEffect, useState } from "react";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import homeLabel from "./../../../utils/property-file/componentOverview.json";
import overviewIcon from "./../../../assets/img/overviewIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllTestPlanRunHistoryService, getAllTestPlanService, runTestPlanService, testJobStatusService } from "../../../services/testService";
import moment from "moment";
import { convertCamelCaseToSeparateWords } from "../../../services/commonService";
import { toastInfo, toastSuccess } from "../../../services/notifyService";
import { getAIModelService } from "../../../services/aiService";
import listViewLoader from "./../../../assets/img/loading-animation.gif";
import reportForm from "../../../utils/property-file/reports/resourceForm.json";
import buttonsLabel from "../../../utils/property-file/buttons-label.json";

export const TestingHarnessComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation();

    const [viewType, setViewType] = useState('PLANS')

    useEffect(() => {
        setIsTestRunning(false)
        setRunningPlan({
            testPlanMasterId: null, testPlanName:''
        })
        getAIModels()
        setTestPlans([])
        getAllTestPlan(1)
        getAllTestPlanRunHistory()
        setBreadcrumbMenu([
            { slug: '', menuName: 'Admin', isActive: true, hasSubMenu: true },
            { slug: `/admin/testing-harness`, menuName: "Testing Harness", isActive: true, hasSubMenu: true }
        ])
    }, [location.pathname])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: [],
        RECOMMEND: ""
    });
    const [testPlansRuns, setTestPlansRuns] = useState([])
    const [testPlans, setTestPlans] = useState([])
    const [testPlanKPI, setTestPlanKPI] = useState([])
    const [defaultModel, setDefaultModel] = useState({
        aiModelId: null, displayName: '', model: ''
    })
    const [planExecutionInfo, setPlanExecutionInfo] = useState(location.state || {});
    const [isLoading, setIsLoading] = useState(false)
    const [isTestRunning, setIsTestRunning] = useState(false)
    const [runningPlan, setRunningPlan] = useState({
        testPlanMasterId: null, testPlanName:''
    })

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "TESTING_HARNESS").items;
        setLabels(items)
    }, [homeLabel])

    const getAllTestPlanRunHistory = async () => {
        setIsLoading(true)
        const resp = await getAllTestPlanRunHistoryService();
        if (resp && resp.success) {
            const KPIMetrics = resp.data.metrics;
            const kpiMetricsArray = Object.entries(KPIMetrics).map(([key, value]) => {
                return { title: convertCamelCaseToSeparateWords(key), description: value };
            });
            setTestPlanKPI(kpiMetricsArray)
            let plans = resp.data && resp.data.testRuns ? resp.data.testRuns : [];
            if (planExecutionInfo.jobExecuted && planExecutionInfo.testPlanId) {
                plans = plans.map(job => job.testPlanMasterId == planExecutionInfo.testPlanId ? {
                    ...job, runStatus: "pending", percentage: 0,
                    completeCount: 0, runDate: new Date()
                } : job)
                setPlanExecutionInfo({})
            }
            plans.sort((a, b) => new Date(b.runDate) - new Date(a.runDate));
            setTestPlansRuns(plans)
        }
        setIsLoading(false)
    }

    const getAllTestPlan = async (page) => {
        setIsLoading(true)
        const limit = 50;
        const obj = {
            page: page, limit: limit
        }
        const resp = await getAllTestPlanService(obj);
        if (resp && resp.success) {
            let plans = resp.data && resp.data ? resp.data : [];
            if (planExecutionInfo.jobExecuted && planExecutionInfo.testPlanId) {
                plans = plans.map(job => job.testPlanMasterId == planExecutionInfo.testPlanId ? {
                    ...job, runStatus: "pending", percentage: 0,
                    completeCount: 0, lastDate: new Date()
                } : job)
                setPlanExecutionInfo({})
            }
            plans.sort((a, b) => new Date(b.lastDate) - new Date(a.lastDate));
            setTestPlans((prevData) => [...prevData, ...plans])
            if (plans.length === limit) {
                getAllTestPlan(page + 1)
            }
        }
        setIsLoading(false)
    }

    const getAIModels = async () => {
        const resp = await getAIModelService();
        if (resp && resp.success) {
            const defaultModel = resp.data.find(model => model.default);
            if (defaultModel) {
                setDefaultModel(defaultModel)
            }
        }
    }

    const runTestPlan = async (planId) => {
        if(isTestRunning){
            return
        }
        setIsTestRunning(true)
        const obj = {
            testPlanMasterId: planId, aiModelId: defaultModel.model
        }
        toastInfo("Test Job is in progress, please wait untill job gets completed!");
        manageRunningJobPlan(planId)
        const resp = await runTestPlanService(obj)
        if (resp && resp.success) {
            toastSuccess("Test Job completed successfully, Please find your result!");
            setTestPlans([])
            getAllTestPlan(1)
        }
    }

    const manageRunningJobPlan = (planId) => {
        const updatedPlans = testPlans.map(job => job.testPlanMasterId == planId ? {
            ...job, runStatus: "pending", percentage: 0,
            completeCount: 0, lastDate: new Date()
        } : job);
        updatedPlans.sort((a, b) => new Date(b.lastDate) - new Date(a.lastDate));
        setTestPlans(updatedPlans)
    }

    useEffect(() => {
        let interval;
        setTimeout(() => {
            const pendingJobs = testPlans.filter((job) => job.runStatus.toLowerCase() === "pending");

            if (pendingJobs.length > 0) {
                interval = setInterval(async () => {
                    for (const job of pendingJobs) {
                        try {
                            const jobInfo = await getJobRunningStatus(job.testPlanMasterId);
                            if (jobInfo.percentage === 100) {
                                setIsTestRunning(false)
                                setTestPlans((prevJobs) =>
                                    prevJobs.map((j) =>
                                        j.testPlanMasterId === job.testPlanMasterId ? { ...j, runStatus: "completed", completeCount: jobInfo.completed, percentage: jobInfo.percentage } : j
                                    )
                                );
                                setTestPlans([])
                                await getAllTestPlan(1);

                                clearInterval(interval);
                                break;
                            }
                            else {
                                setTestPlans((prevJobs) =>
                                    prevJobs.map((j) =>
                                        j.testPlanRunId === job.testPlanRunId ? { ...j, completeCount: jobInfo.completed, percentage: jobInfo.percentage } : j
                                    )
                                );
                            }
                        } catch (error) {

                        }
                    }
                }, 5000);
            }
        }, 500)

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [testPlans]);

    const getJobRunningStatus = async (id) => {
        const resp = await testJobStatusService(id);
        if (resp && resp.success) {
            const jobStatus = resp.data;
            return {
                percentage: jobStatus.completeCount * 100 / jobStatus.totalQuestions,
                completed: jobStatus.completeCount
            }
        }
    }

    const navigateToSummaryPage = (planId) => {
        nav(`/admin/testing-harness/summary/${planId}`)
    }

    const allTestPlanContent = () => {
        return (
            <table className="table mb-0">
                <thead>
                    <tr>
                        <th>Test Plan Name</th>
                        <th>Created By</th>
                        <th>Last Tested By</th>
                        <th>Last Run Date</th>
                        <th className="text-center">Total Questions</th>
                        <th className="text-center">Pass Rate(%)</th>
                        <th className="text-center">Result</th>
                        <th className="text-center">Run Test</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        testPlans.map((plan, i) => {
                            const percentage = plan.percentage ? plan.percentage : 0;
                            const completeCount = plan.completeCount ? plan.completeCount : 0;
                            return (
                                plan.runStatus.toLowerCase() === 'pending' ?
                                    <tr key={`test-plan-list-${i}`} className="cp">
                                        <td>{plan.testPlanName}</td>
                                        <td>{plan.createdUserName ? plan.createdUserName : plan.createdBy}</td>
                                        <td colSpan={5}>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: `${percentage}%` }} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
                                                    Completed: {completeCount}
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    :
                                    <tr key={`test-plan-list-${i}`} className={`${plan.runStatus === 'saved' ? 'bl-font-bold' : ''} cp`}>
                                        <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)}>{plan.testPlanName}</td>
                                        <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)}>{plan.createdUserName ? plan.createdUserName : plan.createdBy}</td>
                                        <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)}>{plan.userName ? plan.userName : '-'}</td>
                                        <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)}>{plan.runStatus !== 'saved' ? moment(plan.lastDate).format('MMM DD, YYYY HH:MM A') : '-'}</td>
                                        <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)} className="text-center">{plan.totalActiveQuestions}</td>
                                        <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)} className="text-center">{plan.runStatus === 'saved' ? '-' : `${Math.round((parseInt(plan.passCount) * 100) / parseInt(plan.totalTestRuns))}%`}</td>
                                        <td className={`${plan.result.toLowerCase().includes('pass') ? 'text-success' : 'text-danger'} text-center text-uppercase`}>{plan.runStatus === 'saved' ? 'N/A' : plan.result}</td>
                                        <td className="text-center">
                                            <button className={`btn btn-outline-secondary btn-sm ${isTestRunning ? 'disabled' : ''}`} data-toggle="modal" data-target="#testrunalertmodal" onClick={() => setRunningPlan(plan)}><i className="fa fa-fw fa-play" aria-hidden="true"></i> RUN</button>
                                        </td>
                                    </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        )
    }

    const getAllTestRunsHistoryContent = () => {
        return <table className="table mb-0">
            <thead>
                <tr>
                    <th>Test Plan Name</th>
                    <th>Last Tested By</th>
                    <th>Last Run Date</th>
                    <th className="text-center">Total Questions</th>
                    <th className="text-center">Pass Count</th>
                    <th className="text-center">Fail Count</th>
                    <th className="text-center">Pass Rate(%)</th>
                    <th className="text-center">Result</th>
                </tr>
            </thead>
            <tbody>
                {
                    testPlansRuns.map((plan, i) => {
                        return (
                            <tr key={`test-plan-list-${i}`} className="cp">
                                <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)}>{plan.testPlanName}</td>
                                <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)}>{plan.runBy}</td>
                                <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)}>{moment(plan.runDate).format('MMM DD, YYYY HH:MM A')}</td>
                                <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)} className="text-center">{plan.totalQuestions}</td>
                                <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)} className="text-center">{plan.passCount}</td>
                                <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)} className="text-center">{plan.failCount}</td>
                                <td onClick={() => navigateToSummaryPage(plan.testPlanMasterId)} className="text-center">{Math.round((parseInt(plan.passCount) * 100) / parseInt(plan.totalQuestions))}%</td>
                                <td className={`${plan.result.toLowerCase().includes('pass') ? 'text-success' : 'text-danger'} text-center text-uppercase`}>{plan.runStatus === 'saved' ? 'N/A' : plan.result}</td>
                            </tr>
                        )
                    })
                }

            </tbody>
        </table>
    }

    const testRunConfimationAlertContent = () => {
        return (
            <div className="modal fade" id="testrunalertmodal" tabIndex="-1" role="dialog" aria-labelledby="testrunalertmodallabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="testrunalertmodallabel">{reportForm.RUN.TITLE}</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {reportForm.RUN.INFO} "<b>{runningPlan.testPlanName}</b>"
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary" type="button" data-dismiss="modal">{buttonsLabel.CANCEL}</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>runTestPlan(runningPlan.testPlanMasterId)}>{buttonsLabel.YES}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-12">
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="align-self-center mr-16">
                                            <h5>{labels.WELCOME}</h5>
                                            <p>{labels.WELCOME_DESC}</p>
                                        </div>
                                        <img className="bl-overview-img" src={overviewIcon} alt="overview-Img" />
                                    </div>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => nav('/admin/testing-harness/manage')}>Create New Test Plan</button>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card-deck">
                                {
                                    testPlanKPI && testPlanKPI.map((kpi, i) => {
                                        return (
                                            <div key={`testing-harness-kpi-${i}`} className="card shadow-sm">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div><h6>{kpi.title}</h6></div>
                                                        {
                                                            kpi.icon ? <div className="icon-shape icon-md bg-light-primary text-primary rounded-2">
                                                                <i className={`fa fa-${kpi.icon} ${kpi.colorClass}`} aria-hidden="true"></i>
                                                            </div> : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <h5 className={`mb-0 ${kpi.title.toLowerCase().includes('pass') ? 'text-success' : kpi.title.toLowerCase().includes('fail') ? 'text-danger' : ''}`}><b>{kpi.description}</b></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="border-bottom">
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="d-flex">
                                                        <span className={`badge bl-chip-btn cp mr-2 ${viewType === 'PLANS' ? 'active' : ''}`} onClick={() => setViewType('PLANS')}>Active Test Plans</span>
                                                        <span className={`badge bl-chip-btn cp ${viewType === 'HISTORY' ? 'active' : ''}`} onClick={() => setViewType('HISTORY')}>All Test Run History</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isLoading ? <div className="col text-center">
                                                    <img className="bl-list-loader" src={listViewLoader} />
                                                </div> :
                                                    viewType === 'PLANS' ? allTestPlanContent() : getAllTestRunsHistoryContent()
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {testRunConfimationAlertContent()}
            </div>

        </div>
    )
}