import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FloatButton } from "./Common/floatButton";
import { testSecurityService, validateSessionService } from "../services/authService";
import { HeaderComponent } from "./Common/header";
import { MenuComponent } from "./Common/menu";
import { SubMenuComponent } from "./Common/subMenu";
import { AIAssistantModalComponent } from "./AIAssistant/AIModal";
import ThemeContext from "../services/context/Theme/themeContext";
import { AuthContext } from "../services/context/Auth/authContext";

export const PageLayout = () => {

    const { setIsOpenedInIframe } = useContext(ThemeContext)
    const { isIframeUser } = useContext(AuthContext)
    const location = useLocation();
    const nav = useNavigate();
    const [displayCommonSec, setDisplayCommonSec] = useState(true);

    useEffect(() => {
        if (isIframeUser) {
            setIsOpenedInIframe(true)
            setDisplayCommonSec(false);
            const kairosSec = document.getElementById("content-wrapper");
            if (kairosSec) {
                kairosSec.style.setProperty("margin-left", "0px", "important");
                kairosSec.style.setProperty("margin-top", "0px", "important");
            }
            nav('/kairos')
        }
    }, [isIframeUser])

    useEffect(() => {
        validateSession();
    }, [location]);

    const validateSession = async () => {
        const resp = await validateSessionService();
        if (!resp || !resp.success) {
            nav("/");
        }
        // testSecurityForServices()
    };

    const testSecurityForServices = async () => {
        const resp = await testSecurityService();
        console.log("Check resp ===>", resp);
    }

    return (
        <>
            {displayCommonSec ? <HeaderComponent /> : null}

            <div id="wrapper">
                {displayCommonSec ? (
                    <>
                        <MenuComponent />
                        <SubMenuComponent />
                    </>
                ) : null}

                <div
                    id="content-wrapper"
                    className="d-flex flex-column vistaContentMargin vistaCollapsedContentMargin"
                >
                    <div className="page-loader-container" id="pageLoaderContainer">
                        <div className="page-loader"></div>
                    </div>
                    <Outlet />
                    <FloatButton />
                </div>
                <AIAssistantModalComponent />
            </div>
        </>
    );
};
