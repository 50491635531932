import React from "react";
import testingFormLabel from "../../../../utils/property-file/testingHarness/formsLabel.json";


export const RunHistoryComponent = () => {

    return (
        <>
            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2 shadow-sm">
                <div className="card-body">
                    <h5 className="card-title">{testingFormLabel.HISTORY.TITLE}</h5>
                    <p className="card-text">{testingFormLabel.HISTORY.DESCRIPTION}</p>
                </div>
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Question</th>
                            <th>Answer</th>
                            <th>Actual Output</th>
                            <th>Result Summary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={5} className="text-center"><h5>Under Progres...</h5></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}