import React, { useContext, useEffect, useState } from "react";
import { getAppBehaviour } from "../../../../services/authService";
import { appendThemeProperty, generateSlug } from "../../../../services/commonService";
import BreadcrumbContext from "../../../../services/context/Breadcrumb/breadcrumbContext";
import ThemeContext from "../../../../services/context/Theme/themeContext";
import { useLocation, useNavigate } from "react-router-dom";
import entityLabel from "../../../../utils/property-file/adminConsole/dataEntity.json";
import buttonsLabel from "../../../../utils/property-file/buttons-label.json";
import taskIcon from "./../../../../assets/img/entity.svg";
import { ModelEntityContext } from "../../../../services/context/entityContext";
import { deleteTableColumnService, deleteTableExampleService, generateModelTableId, getTableSectionsInformationService, updateTableEntitiesInformationService } from "../../../../services/modelService";
import { validateAndFormatDate } from "../../../../utils/validation";
import moment from "moment";
import { toastError, toastSuccess } from "../../../../services/notifyService";
import snowflakeDataTypes from "./../../../../data/Admin/Database/snowflakeDataTypes.json"


export const DataEntityManageSectionsComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { theme } = useContext(ThemeContext)
    const { tableBasicInfo } = useContext(ModelEntityContext)
    const nav = useNavigate()
    const location = useLocation();

    useEffect(() => {
        console.log("tableBasicInfo ===>", tableBasicInfo);
        applyTheme()
        setSelectedSection("DICTIONARY")
        setSampleQA([])
        setSections([
            { name: "Table Configuration", val: "DICTIONARY", selected: true },
            { name: "Column Configuration", val: "COLUMN", selected: false },
            { name: "Example Questions", val: "QA", selected: false }
        ])
        if (tableBasicInfo && tableBasicInfo.tableId) {
            const tableId = generateModelTableId(tableBasicInfo.tableId);
            setBreadcrumbMenu([
                { slug: '', menuName: 'Admin Console', isActive: true, hasSubMenu: true },
                { slug: `/admin/data-entity-configuration`, menuName: "Data Entity Configuration", isActive: false, hasSubMenu: true },
                { slug: `/admin/data-entity-configuration/${tableId}`, menuName: tableBasicInfo.datasetName, isActive: false, hasSubMenu: true }
            ])
        }

    }, [location.pathname, tableBasicInfo])

    const applyTheme = () => {
        const appThemes = getAppBehaviour();
        if (theme) {
            appendThemeProperty(theme, true, true)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme, true, true)
            }
        }
    }

    const [sections, setSections] = useState([
        { name: "Table Configuration", val: "DICTIONARY", selected: true },
        { name: "Column Configuration", val: "COLUMN", selected: false },
        { name: "Example Questions", val: "QA", selected: false }
    ])
    const [entityInfo, setEntityInfo] = useState({
        tableId: null,
        datasetName: "",
        dataSourceId: null,
        status: "",
        createdOn: null,
        lastUpdatedOn: null,
        dataSourceName: "",
        databaseName: "",
        exampleCount: 0,
        columnCount: 0
    })
    const [selectedSection, setSelectedSection] = useState("DICTIONARY")
    const [description, setDescription] = useState("")
    const [definition, setDefinition] = useState("")
    const [rulesAndExceptions, setRulesAndExceptions] = useState("")
    const [columns, setColumns] = useState([
        { columnId: null, name: "", type: "", definition: "" }
    ])
    const [sampleQA, setSampleQA] = useState([
        { exampleId: null, question: "", query: "" }
    ])

    useEffect(() => {
        if (tableBasicInfo && tableBasicInfo.tableId) {
            setEntityInfo(tableBasicInfo)
            setDescription(tableBasicInfo.description ? tableBasicInfo.description : '')
            setDefinition(tableBasicInfo.definition ? tableBasicInfo.definition : '')
            setRulesAndExceptions(tableBasicInfo.rulesAndExceptions ? tableBasicInfo.rulesAndExceptions : '')
            setColumns([])
            setSampleQA([])
            setTimeout(() => {
                getTablesColumnList(1)
                getTablesSampleExampleList(1)
            })
        }
    }, [tableBasicInfo])

    const getTablesColumnList = async (page) => {
        try {
            const limit = 30;
            const obj = {
                page: page, limit: limit
            }
            const resp = await getTableSectionsInformationService(tableBasicInfo.tableId, 'columns', obj);
            if (resp && resp.success) {
                const list = resp.data;
                if (page === 1 && list.length === 0) {
                    setColumns([{ columnId: null, name: "", type: "", definition: "" }])
                }
                else {
                    const _columnList = list.map(col => {
                        col.definition = col.definition ? col.definition : '';
                        return col;
                    })
                    setColumns((column) => [...column, ..._columnList]);
                    if (resp.data.length >= limit) {
                        getTablesColumnList(page + 1);
                    }
                }
            }
            else {
                setColumns([{ columnId: null, name: "", type: "", definition: "" }])
            }
        }
        catch (e) {
            setColumns([{ columnId: null, name: "", type: "", definition: "" }])
        }

    }

    const getTablesSampleExampleList = async (page) => {
        try {
            const limit = 30;
            const obj = {
                page: page, limit: limit
            }
            const resp = await getTableSectionsInformationService(tableBasicInfo.tableId, 'examples', obj);
            if (resp && resp.success) {
                const _qaList = resp.data;
                setSampleQA((qa) => [...qa, ..._qaList]);
                if (resp.data.length >= limit) {
                    getTablesSampleExampleList(page + 1);
                }
            }
        }
        catch (e) {
        }

    }

    const changeSelectedSection = (index) => {
        const sec = [...sections];
        const secList = sec.map(item => {
            item.selected = false;
            return item;
        })
        secList[index].selected = true;
        setSelectedSection(secList[index].val)
        setSections(secList)
        applyTheme()
    }

    const validateAllColumns = () => {
        const errors = [];
        columns.forEach((col, i) => {
            if (!col.name.trim() || !col.type.trim()) {
                errors.push({ index: i })
            }
        })
        return {
            isValid: errors.length === 0, errors
        }
    }

    const validateAllSampleQA = () => {
        const errors = [];
        sampleQA.forEach((qa, i) => {
            if (!qa.question.trim() || !qa.query.trim()) {
                errors.push({ index: i })
            }
        })
        return {
            isValid: errors.length === 0, errors
        }
    }

    const saveEntity = async () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (!description.trim()) {
                changeSelectedSection(0)
                return toastError("Please enter the description for table configuration!")
            }
            if (!definition.trim()) {
                changeSelectedSection(0)
                return toastError("Please enter the definition for table configuration!")
            }
            const columnValidator = validateAllColumns();
            if (!columnValidator || !columnValidator.isValid) {
                changeSelectedSection(1)
                return toastError("Please provide all column name and their corresponding type!")
            }
            const qaValidator = validateAllSampleQA();
            if (sampleQA.length > 0 && (!qaValidator || !qaValidator.isValid)) {
                changeSelectedSection(2)
                return toastError("Please provide all questions along with their corresponding queries!")
            }
            const obj = {
                description: description.trim(),
                definition: definition.trim(),
                rulesAndExceptions: rulesAndExceptions.trim(),
                datasetName: entityInfo.datasetName,
                columns: columns,
                examples: sampleQA
            }
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const resp = await updateTableEntitiesInformationService(tableBasicInfo.tableId, obj);
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
            if (resp && resp.success) {
                toastSuccess("Table information updated successfully!")
                nav(`/admin/data-entity-configuration`)
            }
        }
        catch (e) {
            const message = e && e.data && e.data.message ? e.data.message : "An error occurred. Please try again!";
            toastError(message)
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const addNewColumn = () => {
        setColumns(prev => [...prev, { columnId: null, name: "", type: "", definition: "" }])
        applyTheme()
    }

    const removeColumn = (index) => {
        const _columns = [...columns];
        _columns.splice(index, 1)
        setColumns(_columns)
    }

    const addNewSampleQA = () => {
        setSampleQA(prev => [...prev, { exampleId: null, question: "", query: "" }])
        applyTheme()
    }

    const removeSampleQA = (index) => {
        const _sampleQA = [...sampleQA];
        _sampleQA.splice(index, 1)
        setSampleQA(_sampleQA)
    }

    const dataDictionaryContent = () => {
        return (
            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                <div className="card-body">
                    <h5 className="card-title">{entityLabel.DICTIONARY_SECTION.TITLE}</h5>
                    <p className="card-text text-muted">{entityLabel.DICTIONARY_SECTION.DESC}</p>
                </div>
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td width="30%" valign="top"><div className="text-small pl-2">{'Description'}</div></td>
                            <td width="50%">
                                <textarea rows={5} className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Please enter description here..." />
                            </td>
                            <td>
                                <small id="passwordHelpInline" className="text-muted">
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top"><div className="text-small pt-2 pl-2">{'Definition'}</div></td>
                            <td>
                                <textarea placeholder="Please type/paste definition here..." rows={5} className="form-control" value={definition} onChange={(e) => setDefinition(e.target.value)}></textarea>
                            </td>
                            <td>
                                <small id="passwordHelpInline" className="text-muted">
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top"><div className="text-small pt-2 pl-2">{'Rules & Exceptions'}</div></td>
                            <td>
                                <textarea placeholder="Please type/paste rules & exceptions here..." rows={5} className="form-control" value={rulesAndExceptions} onChange={(e) => setRulesAndExceptions(e.target.value)}></textarea>
                            </td>
                            <td>
                                <small id="passwordHelpInline" className="text-muted">
                                </small>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    const setColumnInformation = (text, index, filed) => {
        const columnList = [...columns];
        if (filed === 'name') {
            columnList[index].name = text;
        }
        else if (filed === 'type') {
            columnList[index].type = text;
        }
        else {
            columnList[index].definition = text;
        }
        setColumns(columnList)
    }

    const columnConfigurationContent = () => {
        return (
            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                <div className="card-body">
                    <h5 className="card-title">{entityLabel.COLUMN_SECTION.TITLE}</h5>
                    <p className="card-text text-muted">{entityLabel.COLUMN_SECTION.DESC}</p>
                </div>
                <table className="table mb-0">
                    <tbody>
                        {
                            columns && columns.length && columns.map((column, i) => {
                                return (
                                    <tr key={`entity-column-${i}`}>
                                        <td width="30%">
                                            <input type="text" className="form-control" value={column.name} onChange={(e) => setColumnInformation(e.target.value, i, 'name')} placeholder="Enter column name..." />
                                        </td>
                                        <td width="20%">
                                            <select className="form-control" value={column.type} onChange={(e) => setColumnInformation(e.target.value, i, 'type')}>
                                                <option value="" disabled>
                                                    -- Select a data type --
                                                </option>
                                                {Object.keys(snowflakeDataTypes).map((category) => (
                                                    <optgroup key={category} label={category}>
                                                        {snowflakeDataTypes[category].map((dataType) => (
                                                            <option key={dataType.type} value={dataType.type} data-description={dataType.description}>
                                                                {dataType.type}
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                ))}
                                            </select>
                                        </td>
                                        <td width="40%">
                                            <input type="text" className="form-control" value={column.definition} onChange={(e) => setColumnInformation(e.target.value, i, 'definition')} placeholder="Enter column definition..." />
                                        </td>
                                        <td>
                                            {
                                                columns.length !== 1 ? <button className="btn btn-circle btn-outline-primary btn-sm mr-2" onClick={() => removeEntity('Column', i)}><i className="fa fa-trash" aria-hidden="true"></i></button> : null
                                            }
                                            {
                                                columns.length - 1 === i ? <button className="btn btn-circle btn-primary btn-sm" onClick={() => addNewColumn()}><i className="fa fa-plus" aria-hidden="true"></i></button> : null
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    const setSampleQAInformation = (text, index, field) => {
        const list = [...sampleQA];
        if (field === 'question') {
            list[index].question = text;
        }
        else {
            list[index].query = text;
        }
        setSampleQA(list)
    }

    const sampleQAContent = () => {
        return (
            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                <div className="card-body d-flex justify-content-between align-items-end">
                    <div>
                        <h5 className="card-title">{entityLabel.QA_SECTION.TITLE}</h5>
                        <p className="card-text text-muted">{entityLabel.QA_SECTION.DESC}</p>
                    </div>
                    {
                        sampleQA.length === 0 ? <button className="btn btn-outline-primary btn-sm" onClick={() => addNewSampleQA()}><i className="fa fa-fw fa-plus-circle" aria-hidden="true"></i> Add Example</button> : null
                    }
                </div>
                <table className="table mb-0">
                    <tbody>
                        {
                            sampleQA && sampleQA.map((qa, i) => {
                                return (
                                    <tr key={`entity-sample-qa-${i}`}>
                                        <td width="40%">
                                            <input type="text" className="form-control" value={qa.question} onChange={(e) => setSampleQAInformation(e.target.value, i, 'question')} placeholder="Enter Example Question here..." />
                                        </td>
                                        <td width="40%">
                                            <input type="text" className="form-control" value={qa.query} onChange={(e) => setSampleQAInformation(e.target.value, i, 'query')} placeholder="Enter query here..." />
                                        </td>
                                        <td>
                                            <button className="btn btn-circle btn-outline-primary btn-sm mr-2" onClick={() => removeEntity('Example', i)}><i className="fa fa-trash" aria-hidden="true"></i></button>
                                            {
                                                sampleQA.length - 1 === i ? <button className="btn btn-circle btn-primary btn-sm" onClick={() => addNewSampleQA()}><i className="fa fa-plus" aria-hidden="true"></i></button> : null
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    const getLastUpdateDate = () => {
        return moment(entityInfo.createdOn).isSame(entityInfo.lastUpdatedOn) ? '-' : validateAndFormatDate(entityInfo.lastUpdatedOn);
    }

    const getTextColorForStatus = () => {
        if (entityInfo.status.toLowerCase() === 'pending') {
            return 'text-warning';
        }
        else if (entityInfo.status.toLowerCase() === 'newly added') {
            return 'text-info';
        }
        else {
            return 'text-success'
        }
    }

    const [deleteFor, setDeletedFor] = useState("")
    const [deleteEntityInfo, setDeleteEntityInfo] = useState({
        name: "", entityId: null, index: null
    })

    const removeEntity = (type, index) => {
        setDeletedFor(type)
        if (type === 'Column') {
            const colInfo = columns[index];
            if (!colInfo.columnId) {
                removeColumn(index)
            }
            else {
                setDeleteEntityInfo({
                    name: colInfo.name, entityId: colInfo.columnId, index: index
                })
                window.$("#deleteentitymodal").modal('toggle');
            }
        }
        else {
            const exInfo = sampleQA[index];
            if (!exInfo.exampleId) {
                removeSampleQA(index)
            }
            else {
                setDeleteEntityInfo({
                    name: exInfo.question, entityId: exInfo.exampleId, index: index
                })
                window.$("#deleteentitymodal").modal('toggle');
            }
        }
    }

    const deleteTableEntity = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex'
        }
        let resp;
        if (deleteFor === 'Column') {
            resp = await deleteTableColumnService(tableBasicInfo.tableId, deleteEntityInfo.entityId)
        }
        else {
            resp = await deleteTableExampleService(tableBasicInfo.tableId, deleteEntityInfo.entityId)
        }
        if (resp && resp.success) {
            if(deleteFor === 'Column'){
                removeColumn(deleteEntityInfo.index)
            }
            else{
                removeSampleQA(deleteEntityInfo.index)
            }
            setDeleteEntityInfo({
                name: "", entityId: null, index: null
            })
            setDeletedFor("")
            window.$("#deleteentitymodal").modal('toggle');
            toastSuccess(`${deleteFor} removed successfully!`)
        }
        if (pageLoader) {
            pageLoader.style.display = 'none'
        }
    }

    const deleteEntityModalContent = () => {
        return (
            <div className="modal fade" id="deleteentitymodal" tabIndex="-1" role="dialog" aria-labelledby="deleteCompanyEntityModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteCompanyEntityModalLabel">Delete {deleteFor}</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove {deleteFor} <b>"{deleteEntityInfo.name}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => deleteTableEntity(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section>
                    <div className="d-sm-flex align-items-center justify-content-between bl-action-btn-sec">
                        <div className={"d-inline-flex flex-row bl-btn-grp-fixed"}>
                            {
                                <button className="d-sm-inline-block btn btn-sm btn-outline-primary mr-2" onClick={() => nav('/admin/data-entity-configuration')}><i className="fa fa-ban mr-1" aria-hidden="true"></i> {buttonsLabel.CANCEL}</button>
                            }
                            {
                                selectedSection !== "DICTIONARY" ? <button className="d-sm-inline-block btn btn-sm btn-primary mr-2" onClick={() => changeSelectedSection(selectedSection === "QA" ? 1 : 0)}><i className="fa fa-arrow-left" aria-hidden="true"></i> {buttonsLabel.PREVIOUS}</button> : null
                            }
                            {
                                selectedSection !== "QA" ? <button className="d-sm-inline-block btn btn-sm btn-primary mr-2" onClick={() => changeSelectedSection(selectedSection === "DICTIONARY" ? 1 : 2)}><i className="fa fa-arrow-right" aria-hidden="true"></i> {buttonsLabel.SAVE_PROCEED}</button> : null
                            }
                            {
                                selectedSection === "QA" ? <button className="d-sm-inline-block btn btn-sm btn-primary mr-2" onClick={() => saveEntity()}><i className="fa fa-floppy-o mr-1" aria-hidden="true"></i> {buttonsLabel.SAVE_TUNING}</button> : null
                            }

                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row" id="bio">
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <div className="sidebar-brand-icon mb-2">
                                <img src={taskIcon} height="70px" />
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <h4 className="h4 text-gray-800 text-center">{entityLabel.TITLE}{entityInfo.datasetName}</h4>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <p>{entityLabel.DESCRIPTION}</p>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <div className="d-flex py-3">
                                {
                                    sections && sections.map((sec, i) => {
                                        return (
                                            <span key={`entity-sections-badge-${i}`} className={`badge bl-sb-datatableTabs bl-chip-btn cp mr-2 ${sec.selected ? 'active' : ''}`} onClick={() => changeSelectedSection(i)}>{sec.name}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-4">
                    <div className="row">
                        <div className="col-xl-8 col-md-8 card py-2 offset-2 shadow-sm">
                            <table className="table mb-0 table-borderless">
                                <tbody>
                                    <tr>
                                        <td>Number of Columns <br /><b>{entityInfo.columnCount}</b></td>
                                        <td>Number of Examples <br /><b>{entityInfo.exampleCount}</b></td>
                                        <td>Last Updated On <br /><b>{getLastUpdateDate()}</b></td>
                                        <td>Added On <br /><b>{validateAndFormatDate(entityInfo.createdOn)}</b></td>
                                        {/* <td>Model Tuning  <br /><b>{'-'}</b></td> */}
                                        <td>STATUS <br /><i className={`fa fa-circle ${getTextColorForStatus()}`} aria-hidden="true"></i><b className={`text-capitalize ml-2 ${getTextColorForStatus()}`}>{entityInfo.status}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="row">
                        {
                            selectedSection === "DICTIONARY" ? dataDictionaryContent() :
                                selectedSection === "COLUMN" ? columnConfigurationContent() :
                                    sampleQAContent()
                        }

                    </div>
                </section>
            </div>
            {deleteEntityModalContent()}
        </div>
    )

}