import React, { useState } from 'react';

const AlignmentDropdown = ({ editor, selectedAlignment, setSelectedAlignment }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAlignmentChange = (alignment) => {
    editor.chain().focus().setTextAlign(alignment).run();
    setSelectedAlignment(alignment);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button className="dropdown-toggle h-100" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {selectedAlignment === 'left' && <i className="fa fa-align-left" aria-hidden="true"></i>}
        {selectedAlignment === 'center' && <i className="fa fa-align-center" aria-hidden="true"></i>}
        {selectedAlignment === 'right' && <i className="fa fa-align-right" aria-hidden="true"></i>}
        {selectedAlignment === 'justify' && <i className="fa fa-align-justify" aria-hidden="true"></i>}
      </button>
      <div className="dropdown-menu bl-mw-28px text-center" aria-labelledby="dropdownMenuButton">
          <a className='dropdown-item p-1' onClick={() => handleAlignmentChange('left')}>
            <i className="fa fa-align-left" aria-hidden="true"></i>
          </a>
          <a className='dropdown-item p-1' onClick={() => handleAlignmentChange('center')}>
            <i className="fa fa-align-center" aria-hidden="true"></i>
          </a>
          <a className='dropdown-item p-1' onClick={() => handleAlignmentChange('right')}>
            <i className="fa fa-align-right" aria-hidden="true"></i>
          </a>
          <a className='dropdown-item p-1' onClick={() => handleAlignmentChange('justify')}>
            <i className="fa fa-align-justify" aria-hidden="true"></i>
          </a>
        </div>
    </div>
  );
};

export default AlignmentDropdown;
