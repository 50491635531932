import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authenticateByCodeService, authenticateKairosAppForIframeService, storeAppBehaviour, storeToken, storeUserInfo } from "../services/authService";
import { AuthContext } from "../services/context/Auth/authContext";
import { toastError } from "../services/notifyService";
import { getAppConfigService } from "../services/commonService";
import ThemeContext from "../services/context/Theme/themeContext";

const AuthValidationComponent = () => {

    const { setBLRoles } = useContext(AuthContext)
    const { setTheme, setAppLogo } = useContext(ThemeContext)
    const nav = useNavigate();

    const [trustedApp] = useState('https://kind-river-0da007c0f.3.azurestaticapps.net')
    // const [trustedApp] = useState('http://localhost:3001')
    const [escapeCodeAuth, setEscapeCodeAuth] = useState(false)

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop)
        });
        const code = params && params.code ? params.code : null;
        if (!code) {
            nav('/login')
        }
        else {
            setEscapeCodeAuth(false)
            authenticateSSOCode(code)
        }
    }, [])

    useEffect(() => {
        window.addEventListener("message", handleMessage);
        console.log("Get window parent --->", window.parent);
        try {
            console.log("Iframe app: Sending iframe-ready message to parent");
            window.parent.postMessage("iframe-ready", trustedApp);
        } catch (error) {
            console.error("Failed to post message to parent:", error);
            setTimeout(() => {
                nav('/login');
            }, 100);
        }

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [])

    const handleMessage = (event) => {
        try {
            console.log("Iframe app: Received event from origin:", event.origin);
            console.log("Iframe app: Full event:", event);

            if (event.origin !== trustedApp) {
                console.warn("Iframe app: Untrusted origin:", event.origin);
                setTimeout(() => {
                    nav('/login');
                }, 100);
                return;
            }
            const token = event.data?.token;
            if (token) {
                getAppInfo()
                authenticateKairosAppForIframe()
            } else {
                console.warn("Iframe app: Token is undefined");
                setTimeout(() => {
                    nav('/login');
                }, 100);
            }
        }
        catch (e) {
            console.log("Error while fetching iframe info ===>", e);
            setTimeout(() => {
                nav('/login');
            }, 100);
        }

    };

    const authenticateSSOCode = async (code) => {
        document.getElementById('indexLoaderContainer').style.display = 'flex';
        const res = await authenticateByCodeService(code);
        if (res && res.success) {
            storeLoggedUserInfo(res.data)
        }
        else {
            document.getElementById('indexLoaderContainer').style.display = 'none';
            toastError("Something went wrong, please try again!")
            window.location.href = "/#/login";
        }
    }

    const authenticateKairosAppForIframe = async () => {
        console.log("Authencti ======");
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex'
        }
        const resp = await authenticateKairosAppForIframeService();
        if (pageLoader) {
            pageLoader.style.display = 'none'
        }
        if (resp && resp.success) {
            let kairosTokenInfo = resp.data;
            kairosTokenInfo['iframeUser'] = true;
            storeLoggedUserInfo(kairosTokenInfo)
        }
    }

    const getAppInfo = async () => {
        const resp = await getAppConfigService();
        console.log("Get app info ===>", resp);
        if (resp && resp.status) {
            setOrganizationTheme(resp.response)
        }
    }

    const setOrganizationTheme = (orgTheme) => {
        // setAppLogo(orgTheme.logo)
        // setTheme(JSON.parse(orgTheme.behaviour))
        // storeAppBehaviour(JSON.stringify(orgTheme))
    }

    const storeLoggedUserInfo = (info) => {
        console.log("Check logged user info ====>", info);
        const isNewUser = info?.isNewUser || false;
        let obj = {
            username: info.displayName,
            email: info.email,
            userGroups: info.userGroups,
            userSchema: info.userSchema,
            userType: info.userType,
            BLRoles: info.BLRoles ? info.BLRoles : [],
            isNewUser: isNewUser,
            dataSource: info?.dataSource || [],
            iframeUser: info?.iframeUser
        }
        storeUserInfo(JSON.stringify(obj));
        setBLRoles(obj.BLRoles)
        storeToken(info.sessionToken);
        if (info.BLRoles && info.BLRoles.length === 0) {
            document.getElementById('indexLoaderContainer').style.display = 'none';
            window.location.href = "/#/unauthorize";
        }
        else {
            getRoleBasedApp(isNewUser);
        }
    }

    const getRoleBasedApp = async (isNewUser) => {
        document.getElementById('indexLoaderContainer').style.display = 'none';
        if (isNewUser) {
            window.location.href = `/#/tutorial`
        }
        else {
            window.location.href = `/#/home`;
        }
    }

}

export default AuthValidationComponent;