import BL_THEME_Full_Logo from "./../icons/BL/Full_Logo.svg";
import BL_THEME_Small_Logo from "./../icons/BL/Small_Logo.png";
import BL_THEME_K_Logo from "./../icons/BL/Kairos_Logo.svg";
import BL_THEME_K_B_Icon from "./../icons/BL/Kairos_B_Icon.svg";
import BL_THEME_K_Icon from "./../icons/BL/AI_Icon.png";

import VISTA_THEME_Full_Logo from "./../icons/vista/Full_Logo.svg";
import VISTA_THEME_Small_Logo from "./../icons/vista/Vista_logo_sm.png";
import VISTA_THEME_K_Logo from "./../icons/vista/Kairos_Logo.svg";
import VISTA_THEME_K_B_Icon from "./../icons/vista/Kairos_B_Icon.svg";
import VISTA_THEME_K_Icon from "./../icons/vista/Kairos_Icon.svg";

import TERRASCEND_THEME_Full_Logo from "./../icons/terrascend/Full_Logo.png";
import TERRASCEND_THEME_Small_Logo from "./../icons/terrascend/Small_Logo.png";
import TERRASCEND_THEME_K_Logo from "./../icons/terrascend/Kairos_Logo.svg";
import TERRASCEND_THEME_K_B_Icon from "./../icons/terrascend/Kairos_B_Icon.png";
import TERRASCEND_THEME_K_Icon from "./../icons/terrascend/Kairos_Icon.png";

import MGC_THEME_Full_Logo from "./../icons/BL/Full_Logo.svg";
import MGC_THEME_Small_Logo from "./../icons/BL/Small_Logo.png";
import MGC_THEME_K_Logo from "./../icons/BL/Kairos_Logo.svg";
import MGC_THEME_K_B_Icon from "./../icons/BL/Kairos_B_Icon.svg";
import MGC_THEME_K_Icon from "./../icons/BL/AI_Icon.png";

import ACP_THEME_Full_Logo from "./../icons/ACP/big_logo.png";
import ACP_THEME_Small_Logo from "./../icons/ACP/small_logo.png";
import ACP_THEME_K_Logo from "./../icons/BL/Kairos_Logo.svg";
import ACP_THEME_K_B_Icon from "./../icons/BL/Kairos_B_Icon.svg";
import ACP_THEME_K_Icon from "./../icons/BL/AI_Icon.png";

const Icons = {
    BL_THEME_Full_Logo, BL_THEME_Small_Logo, BL_THEME_K_Logo, BL_THEME_K_B_Icon, BL_THEME_K_Icon,
    MGC_THEME_Full_Logo, MGC_THEME_Small_Logo, MGC_THEME_K_Logo, MGC_THEME_K_B_Icon, MGC_THEME_K_Icon,
    VISTA_THEME_Full_Logo, VISTA_THEME_Small_Logo, VISTA_THEME_K_Logo, VISTA_THEME_K_B_Icon, VISTA_THEME_K_Icon,
    TERRASCEND_THEME_Full_Logo, TERRASCEND_THEME_Small_Logo, TERRASCEND_THEME_K_Logo, TERRASCEND_THEME_K_B_Icon, TERRASCEND_THEME_K_Icon,
    ACP_THEME_Full_Logo, ACP_THEME_Small_Logo, ACP_THEME_K_Logo, ACP_THEME_K_B_Icon, ACP_THEME_K_Icon,
}

export default Icons;