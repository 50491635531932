import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import reportsJson from "./../../data/Analytics/reports.json";
import { GridViewComponent } from "./gridView";
import { TableViewComponent } from "./tableView";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { capitalizeFirstLetter } from "../../services/commonService";
import { EmptyCardsContent } from "./../Common/emptyCard";
import { getReportCategoryListService, getReportListService } from "../../services/reportService";
import listViewLoader from "./../../assets/img/loading-animation.gif";


export const AnalyticsReportListComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation();

    useEffect(() => {
        setIsLoading(true)
        const reportCatName = getReportType();
        if (reportCatName && reportCatName.itemCount === 2) {
            setBreadcrumbMenu(
                [
                    { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                    { slug: `/analytics/reports/category${reportCatName.category}`, menuName: capitalizeFirstLetter(reportCatName.category), isActive: true, hasSubMenu: true },
                    { slug: `/analytics/reports/category/${reportCatName.subCategory}`, menuName: capitalizeFirstLetter(reportCatName.subCategory), isActive: false, hasSubMenu: true }
                ]
            )
        }
        else {
            setBreadcrumbMenu(
                [
                    { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                    { slug: `/analytics/reports/${reportCatName.category}`, menuName: capitalizeFirstLetter(reportCatName.category), isActive: false, hasSubMenu: true }
                ]
            )
        }
    }, [location])

    const [viewType, setViewType] = useState('GRID');
    const [reports, setReports] = useState([]);
    const [reportColumn] = useState([
        'Category', 'Description', 'Report Type' //, 'Shared with'
    ])
    const [reportType, setReportType] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const getReportType = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'reports' && pathArray.length === 4) {
                return {
                    category: pathArray[3], itemCount: 1
                }
            }
            else if (pathArray[2] === 'reports' && pathArray[3] === 'category' && pathArray.length === 6) {
                return {
                    category: pathArray[4],
                    subCategory: pathArray[5],
                    itemCount: 2
                }
            }
            return {
                category: pathArray[2],
                itemCount: 1
            }
        }
        return {
            category: '', itemCount: 1
        }
    }

    useEffect(() => {
        setViewType('GRID')
    }, [])

    useEffect(() => {
        let report = [];
        const reportTypeInfo = getReportType();
        if (reportTypeInfo.category === 'shared') {
            reportsJson.map((r) => {
                r.sharedWith.filter(s => {
                    if (s === 'Apurva Pandey') {
                        report.push(r)
                    }
                })
            });
            // setReports(report)
        }
        else {
            const reportCatName = reportTypeInfo.itemCount === 2 ? reportTypeInfo.subCategory : reportTypeInfo.category;
            report = reportsJson.filter(r => r.catSlug === reportCatName && r.isActive);
            // setReports(report);
        }
    }, [location, reportsJson])

    useEffect(() => {
        const reportCategory = getReportType();
        getAnalyticsReportCategory(reportCategory)
    }, [location])

    const getAnalyticsReportCategory = async (reportCategory) => {
        const resp = await getReportCategoryListService();
        if (resp && resp.success) {
            const categoryItems = resp.data;
            let categoryId = "", reportType = "";
            if (reportCategory && reportCategory.itemCount === 2) {
                reportType = "subCategory";
                categoryItems.map(items => {
                    items.list.find(item => {
                        if (item.slug === reportCategory.subCategory) {
                            categoryId = item.reportSubcategoryId
                        }
                    })
                })
            }
            else {
                reportType = "category";
                categoryId = categoryItems && categoryItems.length ? categoryItems.find(c => c.slug === reportCategory.category).reportCategoryId : null;
            }
            getReportList(reportType, categoryId)
        }
    }

    const getReportList = async (reportType, categoryId) => {
        const obj = {
            page: 1, pageSize: 10, categoryType: reportType, categoryId: categoryId
        }
        const resp = await getReportListService(obj);
        setIsLoading(false)
        if (resp && resp.success) {
            setReports(resp.data)
        }
    }

    const getEmptyScreen = () => {
        return (
            <div className="row">
                <div className="col text-center mt-5">
                    <EmptyCardsContent title='Reports not available' desc="Reports are not available under the selected category" buttonView={false} />
                </div>
            </div>
        )
    }
    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>List of available reports</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-2" onClick={() => setViewType('GRID')}><i className="fa fa-th-large" aria-hidden="true"></i></button>
                        <button className="btn btn-primary btn-sm" onClick={() => setViewType('LIST')}><i className="fa fa-list" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    {
                        isLoading ? viewType === 'GRID' ? <span className="loader"></span> :
                            <div className="col text-center">
                                <img className="bl-list-loader" src={listViewLoader} />
                            </div>
                            : null
                    }
                    {
                        !isLoading ? viewType === 'GRID' ?
                            <GridViewComponent reports={reports}></GridViewComponent>
                            :  <TableViewComponent data={reports} reportColumn={reportColumn}></TableViewComponent> : null
                    }
                    {
                        reports.length === 0 && !isLoading ? getEmptyScreen() : null
                    }
                </section>
            </div>
        </div>
    )
}