import React, { useContext, useEffect, useState } from "react";
import BreadcrumbContext from "../services/context/Breadcrumb/breadcrumbContext";
import homeLabel from "./../utils/property-file/componentOverview.json";
import listViewLoader from "./../assets/img/loading-animation.gif";
import overviewImg from "./../assets/img/logs.png";
import { TableViewComponent } from "./Analytics/tableView";

export const LogServiceComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setIsLoading(true)
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Log Service', isActive: true, hasSubMenu: false }
            ]
        )
    }, [])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        LATEST_UPDATE: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });
    const [isLoading, setIsLoading] = useState(false)
    const [reports, setReports] = useState([]);
    const [filter, setFilter] = useState([
        { type: "ACTIVITY_LOG", displayName: "Activity Logs", isActive: true },
        { type: "ERROR_LOG", displayName: "Error Logs", isActive: false },
        { type: "FEEDBACK", displayName: "Feedback", isActive: false }
    ]);
    const [reportColumn] = useState([
        'Category', 'Description', 'Report Type'
    ])

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "LOG_SERVICE").items;
        setLabels(items)
    }, [homeLabel])

    const filterLogs = (indx) => {
        let filterList = [...filter];
        filterList = filterList.map(f=> {
            f.isActive = false;
            return f;
        })
        filterList[indx].isActive = true;
        setFilter(filterList)
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <section className="mb-3">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between">
                                        <div className="align-self-center mr-16">
                                            <h5>{labels.WELCOME}</h5>
                                            <p className="mb-0">{labels.WELCOME_DESC}</p>
                                        </div>
                                        <img className="bl-overview-img" src={overviewImg} alt="overview-Img" />
                                    </div>
                                </div>
                            </section>


                            <section className="mb-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12">
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                                <div className="d-flex pb-2">
                                                    {
                                                        filter.map((f, i) => {
                                                            return (
                                                                <span key={`logs-filter-${i + 1}`} onClick={()=>filterLogs(i)} className={`badge bl-filter-chip cp ${f.isActive ? 'active' : ''}`}>{f.displayName}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    isLoading ?
                                                        <div className="col text-center">
                                                            <img className="bl-list-loader" src={listViewLoader} />
                                                        </div>
                                                        : <TableViewComponent data={reports} reportColumn={reportColumn}></TableViewComponent>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}