import React, { useContext, useEffect, useState } from "react";
import AIContext from "../../services/context/AIAssistance/aiContext";
import { AuthContext } from "../../services/context/Auth/authContext";
import { AIModalBodyComponent } from "./AIModalBody";
import { getAppBehaviour } from "../../services/authService";
import { themeIcons } from "../../services/commonService";


export const AIAssistantModalComponent = () => {

    const { aiModalType, setAIModalType } = useContext(AIContext);
    const { appliacbleApps } = useContext(AuthContext)

    const [kairosIcon, setKairosIcon] = useState('')
    const [showMinimizeIcon, setShowMinimizeIcon] = useState(true);
    const [showMaximizeIcon, setShowMaximizeIcon] = useState(false);

    const modalMinimize = () => {
        setShowMaximizeIcon(true)
        setShowMinimizeIcon(false)
        let body = document.getElementById('approot');
        body.classList.add('overflow-auto')
        let modalElement = document.getElementById('aimodalbox');
        modalElement.classList.add('bl-minimize-modal')
        let modalContent = document.getElementById('preaimodalcontent');
        modalContent.classList.add('bl-minimize-content')
        let modalBody = document.getElementById('aimodalbody');
        modalBody.classList.add('d-none')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.add('d-none')
    }

    const modalMaximize = () => {
        setShowMaximizeIcon(false);
        setShowMinimizeIcon(true)
        let body = document.getElementById('approot');
        body.classList.remove('overflow-auto')
        let modalElement = document.getElementById('aimodalbox');
        modalElement.classList.remove('bl-minimize-modal')
        let modalContent = document.getElementById('preaimodalcontent');
        modalContent.classList.remove('bl-minimize-content')
        let modalBody = document.getElementById('aimodalbody');
        modalBody.classList.remove('d-none')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.remove('d-none')
    }

    const modalClose = () => {
        setAIModalType('')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.remove('d-none')
    }

    useEffect(() => {
        modalMaximize()
    }, [aiModalType])

    useEffect(() => {
        getAppThemeImages()
    }, [getAppBehaviour])

    const getAppThemeImages = () => {
        const appThemes = getAppBehaviour();
        if (appThemes && appThemes !== "undefined") {
            const themeName = JSON.parse(appThemes).themeName;
            const kairos_icon = themeIcons(`${themeName}_K_Icon`);
            setKairosIcon(kairos_icon)
        }
    }

    return (
        <div className="modal fade aiModal p-0 overflow-hidden" id="aimodalbox" data-backdrop={false} data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="aimodalbox" aria-hidden="true">
            <div className="modal-dialog mw-100 m-0" role="document">
                <div id="preaimodalcontent" className="modal-content bl-modal-context min-vh-100">
                    {
                        showMaximizeIcon ? <div className="modal-header p-2">
                            <h6 className="mb-0 ml-2">
                                <img className="img-fluid bl-h-1-25rem mr-2" src={kairosIcon} />
                                AI Assistant
                            </h6>
                        </div> : null
                    }
                    <div className="bl-modal-action">
                        {
                            showMinimizeIcon ? <i className="fa fa-1-5x fa-minus fa-fw mr-2 cp d-none" onClick={() => modalMinimize()}></i> : null
                        }
                        {
                            showMaximizeIcon ? <i className="fa fa-1-5x fa-window-maximize fa-fw mr-2 cp modalMinimize" onClick={() => modalMaximize()}></i> : null
                        }
                        {
                            appliacbleApps && appliacbleApps.length === 1 && appliacbleApps[0].appName == "KAIROS" ? null :
                                <i className="fa fa-1-5x fa-times fa-fw cp" onClick={() => modalClose()} data-dismiss="modal" aria-label="Close"></i>
                        }

                    </div>
                    <div id="aimodalbody" className="modal-body p-0">
                        <AIModalBodyComponent />
                    </div>
                </div>
            </div>
        </div>
    )
}