import React, { useContext, useEffect, useState } from "react";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation } from "react-router-dom";
import pdfMenu from "./../../data/PDF/subMenu.json";
import pdfFilesJson from "./../../data/PDF/pdfList.json";
import amazonPdf from "./../../assets/files/AMZN-Q1-2024-Earnings-Release.pdf";
import applePdf from "./../../assets/files/Apple.pdf";
import salesforcePdf from "./../../assets/files/salesforce.pdf";

export const PDFViewerComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation();
    const [company, setCompany] = useState('')

    useEffect(() => {
        const slug = getLocationSlug();
        const companyInfo = getPdfCompanyInfoBySlug(slug);
        setCompany(companyInfo.name)
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'PDF Viewer', isActive: true, hasSubMenu: true },
                { slug: `/pdf/${companyInfo.slug}`, menuName: companyInfo.name, isActive: false, hasSubMenu: true }
            ]
        )
        getPdfFile()
    }, [location])

    const getLocationSlug = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length === 3) {
            return pathArray[2];
        }
    }

    const getPdfCompanyInfoBySlug = (slug) => {
        return pdfMenu.find(pdf => pdf.slug === slug);
    }

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        LATEST_UPDATE: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });
    const [filter, setFilter] = useState([]);
    const [pdfFiles, setPdfFiles] = useState([])
    const [selectedPdf, setSelectedPdf] = useState('')

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "PDF_VIEWER").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        let filters = [
            {
                val: 'all', name: 'All', isActive: true
            },
            {
                val: 'isRecentlyPublished', name: 'Recently Published', isActive: false
            },
            {
                val: 'createdBy', name: 'Created by you', isActive: false
            },
            {
                val: 'isRecentlyView', name: 'Recently viewed by you', isActive: false
            }
        ];
        setFilter(filters)
    }, [])

    useEffect(() => {
        if (pdfFiles && pdfFiles.length) {
            getActualPdfFile();
        }
    }, [pdfFiles])

    const getPdfFile = () => {
        const companyList = pdfFilesJson.map(files => {
            const fileItems = files.fileList.map(list => {
                const items = list.quarters.map(item => ({
                    ...item,
                    file: files.company === 'apple' ? applePdf : files.company === 'amazon' ? amazonPdf : salesforcePdf
                }));
                list.quarters = items;
                return list;
            })
            files.fileList = fileItems;
            return files;
        })
        setPdfFiles(companyList)
    }

    const getActualPdfFile = () => {
        const slug = getLocationSlug();
        let selectedFile;
        pdfFiles.find(files => {
            if (files.company === slug) {
                selectedFile = files.fileList[0].quarters[0].file;
            }
        })
        if (selectedFile) {
            setSelectedPdf(selectedFile)
        }
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-12">

                        <section className="mb-3">
                            <div className="card border-0">
                                <div className="card-header d-flex justify-content-between align-items-center border-0">
                                    <h6 className="mb-0">PDF View</h6>
                                    <div className="d-flex justify-content-between">
                                        <select className="form-control form-control-sm w-auto mr-2">
                                            <option value="1">2024</option>
                                            <option value="2">2023</option>
                                            <option value="3">2022</option>
                                        </select>
                                        <select className="form-control form-control-sm w-auto">
                                            <option value="1">Q1</option>
                                            <option value="2">Q2</option>
                                            <option value="3">Q3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="card-body border-0">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <iframe src={`${selectedPdf}#toolbar=0`} width={'100%'} className="bl-iframe-height"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>


                </div>
            </div>

        </div>
    )
}
