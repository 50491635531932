import React, { useEffect, useState } from 'react';
import { getCIMSummarizerProjectSections } from '../authService';

export const SummarizerContext = React.createContext();

export const SummarizerContextProvider = ({ children }) => {

    const [projectList, setProjectList] = useState([]);
    const [projectInfo, setProjectInfo] = useState(null);
    const [projectFiles, setProjectFiles] = useState(null);
    const [projectSections, setProjectSections] = useState(null);
    const [projectCompanyInfo, setProjectCompanyInfo] = useState({companyName:'', website:''});
    const [viewType, setViewType] = useState("ALL");
    const [isLoading, setIsLoading] = useState(false);
    const [projectMode, setProjectMode] = useState("");
    const [scorecard, setScorecard] = useState([]);

    useEffect(() => {
        const projectFullInfo = JSON.parse(getCIMSummarizerProjectSections());
        if(projectFullInfo){
            if(projectFullInfo.projectInfo && projectFullInfo.projectInfo.companyName && projectFullInfo.projectInfo.website){
                const compInfo = {
                    companyName: projectFullInfo.projectInfo.companyName, website: projectFullInfo.projectInfo.website
                }
                setProjectCompanyInfo(compInfo)
            }
            setProjectInfo(projectFullInfo.projectInfo ? projectFullInfo.projectInfo : [])
            setProjectFiles(projectFullInfo.files ? projectFullInfo.files : [])
            setProjectSections(projectFullInfo.sections ? projectFullInfo.sections : [])
        }
        
    }, [])

    return (
        <SummarizerContext.Provider value={{ projectList, setProjectList, projectInfo, setProjectInfo, projectFiles, setProjectFiles, projectSections, setProjectSections, viewType, setViewType, isLoading, setIsLoading, projectCompanyInfo, setProjectCompanyInfo, projectMode, setProjectMode, scorecard, setScorecard }}>
            {children}
        </SummarizerContext.Provider>
    );
};
