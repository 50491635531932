import { toast } from "react-toastify";


export const toastSuccess = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 3000
    })
}

export const toastError = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 3000
    })
}

export const toastInfo = (message) => {
    toast.info(message, {
        position: "top-right",
        autoClose: 3000
    })
}

export const toastWarning = (message) => {
    toast.warning(message, {
        position: "top-right",
        autoClose: 3000
    })
}
