import React, { useContext, useEffect, useState } from "react";
import { SpeakerBriefEventsTitle } from "./eventTitle";
import { SpeakerBriefContext } from "../../../services/context/speakerBriefContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation } from "react-router-dom";
import { addEventResponseFeedbackService, generateSBEventId, updateEventResponseFeedbackService, updateEventResponseQuestionSectionService } from "../../../services/speakerBriefService";
import { toastError, toastSuccess, toastWarning } from "../../../services/notifyService";
import ThemeContext from "../../../services/context/Theme/themeContext";
import { getAppBehaviour } from "../../../services/authService";
import { appendThemeProperty } from "../../../services/commonService";


export const SpeakerBriefEventInformation = () => {

    const { eventDetails, eventBasicInfo } = useContext(SpeakerBriefContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { theme } = useContext(ThemeContext)

    const location = useLocation();

    const applyTheme = () => {
        const appThemes = getAppBehaviour();
        if (theme) {
            appendThemeProperty(theme, true, true)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme, true, true)
            }
        }
    }

    useEffect(() => {
        applyTheme()
        console.log("eventBasicInfo --->", eventBasicInfo);
        if (eventBasicInfo) {
            setBreadcrumbMenu([
                { slug: '/speaker-brief', menuName: 'Speaker Brief', isActive: true, hasSubMenu: true },
                { slug: `/speaker-brief/dashboard`, menuName: "Events", isActive: false, hasSubMenu: true },
                { slug: `/speaker-brief/events/${generateSBEventId(eventBasicInfo.eventId)}/info`, menuName: eventBasicInfo.eventName, isActive: false, hasSubMenu: true }
            ])
        }
    }, [location.pathname, eventBasicInfo])

    const [eventQuestions, setEventQuestions] = useState([])
    const [eventSection, setEventSection] = useState({
        question: "", questionId: null, talkingPoints: [], response: ""
    })
    const [feedbackComment, setFeedbackComment] = useState("")
    const [canUpdateFeedback, setCanUpdateFeedback] = useState("")

    useEffect(() => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        console.log("check eventDetails ===>", eventDetails);
        if (eventDetails && Array.isArray(eventDetails) && eventDetails.length) {
            const eventInfo = eventDetails.map(info => {
                info.mode = 'read';
                return info;
            })
            setEventQuestions(eventInfo)
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
        else {
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
        }
        applyTheme()
    }, [location.pathname, eventDetails])

    const questionsConfidenceLevelDropdown = (level) => {
        if (level) {
            if (level.toLowerCase() === 'high') {
                return (
                    <>
                        <div className="dropdown-header text-success">
                            <i className="fa fa-info-circle text-success" aria-hidden="true"></i> Green:
                        </div>
                        <a className="dropdown-item">High indicates the response is derived from Vista Profile or other verified internal sources.</a>
                    </>
                )
            }
            else if (level.toLowerCase() === 'medium') {
                return (
                    <>
                        <div className="dropdown-header text-warning">
                            <i className="fa fa-info-circle text-warning" aria-hidden="true"></i> Amber:
                        </div>
                        <a className="dropdown-item">Medium indicates credible external sources not directly vetted by Vista. </a>
                    </>
                )
            }
            else {
                return (
                    <>
                        <div className="dropdown-header text-danger">
                            <i className="fa fa-info-circle text-danger" aria-hidden="true"></i> Red:
                        </div>
                        <a className="dropdown-item">Low indicates based on assumptions, limited or no data, or less-reliable sources.</a>
                    </>
                )
            }
        }
    }

    const getConfidenceLevelColor = (level) => {
        if (level) {
            if (level.toLowerCase() === 'high') {
                return 'success';
            }
            else if (level.toLowerCase() === 'medium') {
                return 'warning';
            }
            else {
                return 'danger';
            }
        }
    }

    const changeQuestionSecToUpdateMode = (index, mode) => {
        const eventQuestionSections = [...eventQuestions];
        eventQuestionSections[index].mode = mode;
        setEventQuestions(eventQuestionSections)
    }

    const saveChangedEventQuestionSection = async (info, index, mode) => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        
        const emptyPoints = info.talkingPoints.find(p => {
            if(typeof p === 'string'){
                return p.trim() === "";
            }
            else {
                return p.point.trim() === "";
            }
        })
        if(emptyPoints){
            return toastWarning("Few of the points are empty, please fill it first!")
        }
        const points = info.talkingPoints.filter(p => {
            if(typeof p === 'string'){
                return p.trim() !== "";
            }
            else {
                return p.point.trim() !== "";
            }
        })
        console.log("Check points ===>", points);
        if (pageLoader) {
            pageLoader.style.display = 'flex';
        }
        const obj = {
            questionId: info.questionId,
            response: info.response,
            talkingPoints: JSON.stringify(info.talkingPoints)
        }
        const resp = await updateEventResponseQuestionSectionService(info.eventId, [obj]);
        if (pageLoader) {
            pageLoader.style.display = 'none';
        }
        if (resp && resp.success) {
            changeQuestionSecToUpdateMode(index, mode)
            toastSuccess("Updated Successfully!")
        }
    }

    const disablePublishEventButton = () => {
        const editModeSec = eventQuestions.find(info => info.mode === 'edit');
        return !!editModeSec
    }

    const updateQuestionResponse = (text, index) => {
        const eventQuestionSections = [...eventQuestions];
        eventQuestionSections[index].response = text;
        setEventQuestions(eventQuestionSections)
    }

    const updateQuestionPoints = (text, index, secondaryIndex) => {
        const eventQuestionSections = [...eventQuestions];
        if (typeof eventQuestionSections[index].talkingPoints[secondaryIndex] === 'string') {
            eventQuestionSections[index].talkingPoints[secondaryIndex] = text;
        }
        else {
            eventQuestionSections[index].talkingPoints[secondaryIndex].point = text;
        }
        setEventQuestions(eventQuestionSections)
    }

    useEffect(() => {
        console.log("----------------->", eventSection);
        applyTheme()
        if (eventSection && eventSection.feedback && eventSection.feedback.length) {
            
            const comments = eventSection.feedback[0].feedbackText;
            setFeedbackComment(comments ? comments : '')
        }
    }, [eventSection])

    const saveFeedback = async () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            let obj = {};
            if (canUpdateFeedback === 'comment') {
                if (!feedbackComment.trim()) {
                    return toastWarning("Please enter your comment!")
                }
                obj = {
                    feedbackText: feedbackComment
                }
                if (pageLoader) {
                    pageLoader.style.display = 'flex';
                }
            }
            else {
                obj = {
                    thumbsUpDownFlag: canUpdateFeedback === "thumbsUp"
                }
                updateFeedbackInQuestionList(obj)
            }
            console.log("check feedback sec ===>", eventSection.feedback);
            const hasEventSection = !!eventSection;
            const hasFeedback = hasEventSection && Array.isArray(eventSection.feedback) && eventSection.feedback.length > 0;
            const hasValidFeedbackId = hasFeedback && !!eventSection.feedback[0].feedbackId;
            const canUpdate = hasValidFeedbackId;
            console.log("checking update or add ==>", canUpdate);
            let resp = null;
            if (canUpdate) {
                const feedbackId = eventSection.feedback[0].feedbackId;
                resp = await updateEventResponseFeedbackService(eventSection.eventId, eventSection.questionId, feedbackId, obj)
            }
            else {
                resp = await addEventResponseFeedbackService(eventSection.eventId, eventSection.questionId, obj)
            }
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
            setCanUpdateFeedback(null)
            if (resp && resp.success) {
                obj['feedbackId'] = resp.data.feedbackId;
                updateFeedbackInQuestionList(obj)
                if (canUpdateFeedback === 'comment') {
                    window.$("#eventFeedbackModal").modal('toggle');
                    toastSuccess("Thanks for your comment");
                }
            }
            else {
                toastError("Something went wrong, Please try again")
            }
        }
        catch (e) {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const updateFeedbackInQuestionList = (obj) => {
        const eventInfo = { ...eventSection };
        const questionIndex = eventQuestions.findIndex(ques => ques.questionId === eventInfo.questionId);
        if (questionIndex !== -1) {
            const questionList = [...eventQuestions];
            if (questionList[questionIndex].feedback.length === 0) {
                questionList[questionIndex] = {
                    ...questionList[questionIndex],
                    feedback: [obj]
                };
            }
            else {
                const updatedFeedback = questionList[questionIndex].feedback.map((feedbackItem, index) => {
                    if (index === 0) {
                        return {
                            ...feedbackItem,
                            ...obj
                        };
                    }
                    return feedbackItem;
                });
                questionList[questionIndex] = {
                    ...questionList[questionIndex],
                    feedback: updatedFeedback
                };
            }
            setEventQuestions(questionList);
        }
    }

    useEffect(() => {
        if (eventSection) {
            const comment = eventSection.feedback && eventSection.feedback.length && eventSection.feedback[0].feedbackText ? eventSection.feedback[0].feedbackText : "";
            setFeedbackComment(comment)
        }
        if (canUpdateFeedback && eventSection) {
            saveFeedback()
        }
    }, [canUpdateFeedback, eventSection])

    const feedbackModalContent = () => {
        return (
            <div className="modal fade" id="eventFeedbackModal" tabIndex="-1" role="dialog" aria-labelledby="feedbackModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="feedbackModalLabel">Provide Feedback</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col">
                                        <textarea className="form-control" value={feedbackComment} onChange={(e) => setFeedbackComment(e.target.value)} rows={5} placeholder="Leave your comment here!"></textarea>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col">
                                        <div className={`card bl-modal-body-card-length`}>
                                            <div className="card-header px-2">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="media">
                                                            <div><i className="fa fa-question-circle mr-2" aria-hidden="true"></i></div>
                                                            <div className="media-body font-weight-bold">
                                                                {eventSection.question}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>
                                                            <b>Points</b>
                                                        </p>
                                                        <ul>
                                                            {
                                                                eventSection.talkingPoints && eventSection.talkingPoints.map((point, j) => {
                                                                    const pointStr = typeof point === 'string' ? point : point.point;
                                                                    return (
                                                                        <li key={`sb-ques-feedback-points-${j}`} className="mb-2">{pointStr}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="card-body p-3 rounded bg-gray-100">
                                                            <p><b>Speaker Responses</b></p>
                                                            <p>{eventSection.response}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={() => setCanUpdateFeedback("comment")}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const emptyThumbsContent = (info) => {
        return (
            <>
                {emptyThumbsUpContent(info)}
                {emptyThumbsDownContent(info)}
            </>
        )
    }

    const thumbsUpFeedbackContent = (info) => {
        return (
            <>
                {thumbsUpContent(info)}
                {emptyThumbsDownContent(info)}
            </>
        )
    }

    const thumbsDownFeedbackContent = (info) => {
        return (
            <>
                {emptyThumbsUpContent(info)}
                {thumbsDownContent(info)}
            </>
        )
    }

    const thumbsUpContent = (info) => {
        return (
            <i className="fa fa-thumbs-up vista_link_Color bl-font-larger mr-3" aria-hidden="true" onClick={() => { setEventSection(info) }}></i>
        )
    }

    const thumbsDownContent = (info) => {
        return (
            <i className="fa fa-thumbs-down vista_link_Color bl-font-larger" aria-hidden="true" onClick={() => setEventSection(info)}></i>
        )
    }

    const emptyThumbsUpContent = (info) => {
        return (
            <i className="fa fa-thumbs-o-up mr-3 cp bl-font-larger" aria-hidden="true" onClick={() => { setEventSection(info); setCanUpdateFeedback("thumbsUp") }}></i>
        )
    }

    const emptyThumbsDownContent = (info) => {
        return (
            <i className="fa fa-thumbs-o-down cp bl-font-larger" aria-hidden="true" onClick={() => { setEventSection(info); setCanUpdateFeedback("thumbsDown") }}></i>
        )
    }

    const linkValidation = (link) => {
        if (link && (link.startsWith('http') || link.toLowerCase().includes('www.'))) {
            return link.startsWith('http') ? link : `https://${link}`;
        }
        return null
    }

    const [printEnable, setPrintEnable] = useState(false)
    const handlePrint = () => {
        setPrintEnable(true)
        setTimeout(() => {
            window.print();
            setPrintEnable(false)
        }, 100);
    };

    const pushNewElementToTalkingPoints = (id) => {
        setEventQuestions((prevInfos) =>
            prevInfos.map((info) =>
                info.questionId === id
                    ? {
                        ...info,
                        talkingPoints: [...info.talkingPoints, { point: '', isManualAdded: true }],
                    }
                    : info
            )
        );
    }

    const handleDeleteTalkingPoint = (id, index) => {
        setEventQuestions((prevInfos) =>
            prevInfos.map((info) =>
                info.questionId === id
                    ? {
                        ...info,
                        talkingPoints: info.talkingPoints.filter((_, i) => i !== index),
                    }
                    : info
            )
        );
    }

    const getFeedbackCommentCount = (feedback) => {
        if(feedback && feedback.length){
            const list = feedback.filter(f => f.feedbackText && f.feedbackText.trim() !== "");
            return list.length > 0 ? list.length : null;
        }
    }

    return (
        <div id="content">

            <div id="speakerbriefcontent" className="container-fluid pt-3">

                <section className="mb-3">
                    <SpeakerBriefEventsTitle />
                    <div id="comptitle" className="d-print-none">
                        <div className="align-self-center bl-btn-grp-fixed">
                            {
                                eventBasicInfo && eventBasicInfo.publishedStatus && eventBasicInfo.publishedStatus.toLowerCase() !== 'published' ?
                                    <button className="btn btn-primary btn-sm mr-2 mb-0" data-toggle="modal" data-target="#publishEventModal" disabled={disablePublishEventButton()}>
                                        <i className={`fa fa-fw fa-cloud`} aria-hidden="true"></i> Publish Now
                                    </button> : null
                            }
                            <button className="btn btn-outline-primary btn-sm mr-2 mb-0" onClick={handlePrint}>
                                <i className={`fa fa-fw fa-download`} aria-hidden="true"></i> Download
                            </button>
                            <button className="btn btn-outline-primary btn-sm mb-0" data-toggle="modal" data-target="#deleteEventModal">
                                <i className={`fa fa-fw fa-trash`} aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    {
                        eventQuestions && eventQuestions.map((info, index) => {
                            return (
                                <div key={`sb-question-set-${index}`} className="row">
                                    <div className="col-12">
                                        <div className={`card ${printEnable ? '' : `shadow-sm border-left-${getConfidenceLevelColor(info.confidenceLevel)}`} mb-4`}>
                                            <div className="card-header px-2">
                                                <div className="row">
                                                    <div className="col-11 ">
                                                        <div className="media">
                                                            <div><i className="fa fa-question-circle mr-2" aria-hidden="true"></i></div>
                                                            <div className="media-body font-weight-bold">
                                                                {info.question}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1 text-right d-print-none">
                                                        <div className="dropdown no-arrow">
                                                            {
                                                                info.mode === 'read' ? <i className="fa fa-pencil mr-2 cp" aria-hidden="true" onClick={() => changeQuestionSecToUpdateMode(index, 'edit')}></i> :
                                                                    <>
                                                                        <i className="fa fa-times mr-2 cp" aria-hidden="true" onClick={() => changeQuestionSecToUpdateMode(index, 'read')}></i>
                                                                        <i className="fa fa-check mr-2 cp" aria-hidden="true" onClick={() => saveChangedEventQuestionSection(info, index, 'read')}></i>
                                                                    </>
                                                            }
                                                            <span className="text-muted mr-2">|</span><a className="dropdown-toggle cp" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className={`fa fa-info-circle text-${getConfidenceLevelColor(info.confidenceLevel)}`} aria-hidden="true"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                                                                {
                                                                    questionsConfidenceLevelDropdown(info.confidenceLevel)
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>
                                                            <b>Points</b>
                                                        </p>
                                                        <ul className={info.mode === 'edit' ? 'pl-0' : ''}>
                                                            {
                                                                info.talkingPoints && info.talkingPoints.map((point, j) => {
                                                                    const pointStr = typeof point === 'string' ? point : point.point;
                                                                    const canDelete = typeof point !== 'string';
                                                                    return (
                                                                        info.mode === 'edit' ?
                                                                            (
                                                                                <div key={`sb-ques-input-points-${index}${j}`} className="d-flex align-items-center mb-3">
                                                                                    <textarea rows={2} className="form-control form-control-sm" value={pointStr} onChange={(e) => updateQuestionPoints(e.target.value, index, j)} placeholder="" />
                                                                                    {
                                                                                        canDelete ? <button className="btn btn-secondary btn-circle btn-sm ml-2" onClick={() => handleDeleteTalkingPoint(info.questionId, j)}><i className="fa fa-fw fa-times" aria-hidden="true"></i></button> : null
                                                                                    }

                                                                                </div>
                                                                            )
                                                                            :
                                                                            <li key={`sb-ques-points-${index}${j}`} className="mb-3">{pointStr}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                        {
                                                            info.mode === 'edit' ? <button className="btn btn-secondary btn-circle btn-sm float-right" title="Add more points" onClick={() => pushNewElementToTalkingPoints(info.questionId)}><i className="fa fa-fw fa-plus" aria-hidden="true"></i></button> : null
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="card-body p-3 rounded bg-gray-100">
                                                            <p>
                                                                <b>Speaker Responses</b>
                                                            </p>
                                                            {
                                                                info.mode === 'edit' ? <textarea className="form-control" id="exampleFormControlTextarea1" rows="14" value={info.response} onChange={(e) => updateQuestionResponse(e.target.value, index)}></textarea> :
                                                                    <p>{info.response}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        info.citations && info.citations.length ? (
                                                            <div className="col-12 mt-3 d-print-none">
                                                                <p className="text-muted">
                                                                    References ({info.citations.length})
                                                                </p>
                                                                {
                                                                    info.citations.map((reference, z) => {
                                                                        return (
                                                                            <span key={`sb-questions-citations-${index}-${z}`} className="badge badge-pill badge-light border rounded mr-2 text-left">
                                                                                {
                                                                                    linkValidation(reference.citationLink) ? (
                                                                                        <a href={linkValidation(reference.citationLink)} target="_blank">
                                                                                            <i className="fa fa-external-link mr-2" aria-hidden="true"></i> {reference.citationTitle} </a>
                                                                                    ) : <a>
                                                                                        <i className="fa fa-external-link mr-2" aria-hidden="true"></i> {reference.citationTitle} </a>
                                                                                }

                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        ) : null
                                                    }

                                                </div>
                                            </div>
                                            <div className="card-footer px-3 text-muted d-flex flex-row align-items-center justify-content-between d-print-none">
                                                <div>
                                                    {
                                                        info.feedback && info.feedback.length ? (
                                                            info.feedback[0].thumbsUpDownFlag === true ? thumbsUpFeedbackContent(info) :
                                                                info.feedback[0].thumbsUpDownFlag === false ? thumbsDownFeedbackContent(info) : emptyThumbsContent(info)
                                                        ) : emptyThumbsContent(info)
                                                    }


                                                </div>
                                                {/* <div className="card bg-gray"><div className="card-body px-2 py-1 d-flex align-items-center cp"><i className="fa fa-comment text-muted mr-2" aria-hidden="true"></i><p className="mb-0 text-muted small">Provide Feedback <span className="badge badge-dark">1</span></p></div></div> */}
                                                <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => setEventSection(info)} data-toggle="modal" data-target="#eventFeedbackModal"><i className="fa fa-fw fa-comment" aria-hidden="true"></i> Provide Feedback {info.feedback && info.feedback.length ? <span className="badge badge-primary">{getFeedbackCommentCount(info.feedback)}</span> : null}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </section>
            </div>
            {feedbackModalContent()}
        </div>
    )
}