import React, { useContext, useEffect, useRef, useState } from "react";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import { useLocation, useNavigate } from "react-router-dom";
import listViewLoader from "./../../assets/img/loading-animation.gif";
import { generateCIMId, getProjectInfoService, getProjectSectionsService, getSummarizerKPIsService, removeProjectService } from "../../services/summarizerService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SummarizerContext } from "../../services/context/summarizerContext";
import { generateSlug } from "../../services/commonService";
import { storeCIMSummarizerProjectSections } from "../../services/authService";
import moment from "moment";
import { toastInfo, toastSuccess } from "../../services/notifyService";
import logoplaceholder from "./../../assets/img/logoplaceholder.png"
import { ProgressBar } from 'primereact/progressbar';
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";
import { FilterMatchMode } from 'primereact/api';

export const DealBookDashboardComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { analyzedCompanyInfo, setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const { projectList, setProjectInfo, setProjectFiles, setProjectSections, viewType, setViewType, isLoading, setIsLoading, projectMode, setProjectMode } = useContext(SummarizerContext)
    const nav = useNavigate()
    const location = useLocation();

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/dealbook', menuName: 'DealBook', isActive: true, hasSubMenu: true },
            { slug: `/dealbook/home`, menuName: "Home", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    useEffect(() => {
        getSummarizerKPIs()
    }, [analyzedCompanyInfo])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: [],
        RECOMMEND: ""
    });

    const [dealbookKPI, setDealbookKPI] = useState([])
    const [allProjects, setAllProjects] = useState([])
    const [projects, setProjects] = useState([])
    const [dashboardKPI, setDashboardKPI] = useState(null)
    const [selectedProject, setSelectedProject] = useState({
        projectName: "", projectId: null
    })
    const [searchProject, setSearchProject] = useState("")
    const [min] = useState(0)
    const [max] = useState(20)
    const [range, setRange] = useState([min, max]);
    const [deleteProjectCount, setDeleteProjectCount] = useState(0)

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "DEALBOOK").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        setAllProjects(projectList)
    }, [projectList])

    useEffect(() => {
        setDealbookKPI(labels.ENTITY)
    }, [labels])

    useEffect(() => {
        const KPIArray = labels.ENTITY;
        if (dashboardKPI) {
            let count = 0;
            const updatedArray = KPIArray.map(entry => {
                if (entry.keyObj && entry.key) {

                    const matchingObj = dashboardKPI[entry.keyObj]?.find(obj => obj.metric === entry.key);
                    if (matchingObj) {
                        return {
                            ...entry,
                            description: entry.keyObj === 'globalKpiMetrics' ? projects.length : matchingObj.count
                        };
                    }
                }
                return entry;
            });
            setDealbookKPI(updatedArray)
        }
    }, [labels, dashboardKPI, projects])

    const getSummarizerKPIs = async () => {
        const resp = await getSummarizerKPIsService();
        if (resp && resp.success) {
            const info = resp.data;
            setDashboardKPI(info)
        }
    }

    const showFilePage = (e, project) => {
        e.stopPropagation();
        getProjectSections({ data: project }, true)
    }

    const getProjectSections = async (project, showFilePage) => {
        if (project.data.projectProcessingStatus.toLowerCase() === "pending") {
            return toastInfo("File processing in progress. Please wait")
        }
        const projectId = project.data.projectId;
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (pageLoader) {
                pageLoader.style.display = 'flex'
            }
            const projResp = await getProjectInfoService(projectId);
            if (projResp && projResp.success) {
                const resp = await getProjectSectionsService(projectId);
                if (resp && resp.success && resp.data && resp.data.length) {
                    const icons = ['building-o', 'sitemap', 'users', 'industry', 'desktop', 'bank', 'compress', 'cubes', 'usd', 'snowflake-o', 'handshake-o', 'th-list']
                    const sections = resp.data.map((sec, i) => {
                        const availableIcon = icons[i];
                        sec.slug = generateSlug(sec.categoryName);
                        sec.icon = availableIcon ? availableIcon : icons[Math.floor(Math.random() * icons.length)];
                        return sec;
                    });
                    sections.sort((a, b) => a.parentPosition - b.parentPosition);
                    const files = projResp.data.fileMappings;
                    const projectInfo = { ...projResp.data.projectInfo, totalScore: project.data.totalScore, maxScore: max };
                    const storageObj = {
                        sections: sections, files: files, projectInfo: projectInfo
                    }
                    storeCIMSummarizerProjectSections(storageObj)
                    setProjectInfo(projectInfo);
                    setProjectFiles(files);
                    setProjectSections(sections);
                    const projectGenId = generateCIMId(projectInfo.projectId)
                    setProjectMode(project.data.publishedStatus.toLowerCase() === 'published' ? '' : 'manage')
                    const path = showFilePage ? `/dealbook/project/${projectGenId}/files` : project.data.publishedStatus.toLowerCase() === 'published' ? `/dealbook/project/${sections[0].slug}` : `/dealbook/project/${sections[0].slug}/manage`
                    setAnalyzedCompanyInfo({ message: "Project Info Page", projectId: projectId })
                    nav(path)
                }
                else if (!resp.data || resp.data.length === 0) {
                    toastInfo("File processing in progress. Please wait")
                }
                if (pageLoader) {
                    pageLoader.style.display = 'none'
                }
            }

            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
        catch (e) {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }

    }

    const dateFormat = (item, field) => {
        const date = field === 'CREATE' ? item.createdOn : item.lastModifiedDate;
        if (!item.lastModifiedBy && field !== 'CREATE') {
            return ''
        }
        const dateCalendar = moment(date).calendar({
            sameDay: '[Today at] hh:mm A',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday at] hh:mm A',
            lastWeek: '[Last] dddd',
            sameElse: 'MMM DD, YYYY'
        })
        return dateCalendar;
    }

    const tableValues = (info, field, rowIndex) => {
        const project = info;

        if (project) {
            if (project.projectProcessingStatus.toLowerCase() === 'pending' && rowIndex >= 2 && rowIndex <= 8) {
                if (field === 'SCORE') {
                    return (
                        <div className="bl-progress-cell">
                            <div className="font-smaller text-muted">{project.fileStatus ? project.fileStatus : 'Processing file now....'}</div>
                            <ProgressBar value={project.processingPercentage ?? 20} color="green" height={'8px'} />
                        </div>
                    );
                } else if (['LAST_MODIFIED_BY', 'LAST_MODIFIED_DATE', 'CREATED_BY', 'CREATED_ON', 'PAGE', 'SCORE'].includes(field)) {
                    return null;
                }
            }
            else {
                if (field === 'NAME') {
                    const companyLogo = project.icon ? project.icon : logoplaceholder;
                    return (
                        <div className="d-flex align-items-center">
                            <div className="">
                                <p className="mb-0 text-nowrap">
                                    {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVJntksKr9cKXgW2iDMRwfftB72U_XGwvbSA&s" height="15px" className="mr-2 rounded-circle" /> */}
                                    <i className="fa fa-book mr-2" aria-hidden="true"></i>
                                    {project.projectName}
                                    {project.projectProcessingStatus.toLowerCase() === 'pending' ? (
                                        <img src="https://i.gifer.com/origin/4a/4a287dd4b9222ebb17dc354257d0ef79_w200.gif" className="ml-1" height="13px" />
                                    ) : project.publishedStatus.toLowerCase() === 'draft' ? (
                                        <i className="fa fa-fw fa-pencil-square-o text-danger ml-1" aria-hidden="true" title="Draft"></i>
                                    ) : null}
                                </p>
                            </div>
                        </div>
                    );
                }
                else if (field === 'SCORE') {
                    return <div className="text-center">{project.totalScore || 0}</div>
                }
                else if (field === 'STATUS') {
                    return project.projectProcessingStatus.toLowerCase() === 'completed' ? (
                        project.publishedStatus.toLowerCase() === 'published' ? (
                            <span className="badge badge-info">Published</span>
                        ) : (
                            <span className="text-danger">[ Draft ]</span>
                        )
                    ) : project.projectProcessingStatus.toLowerCase() === 'pending' ? (
                        <ProgressBar value={project.processingPercentage}></ProgressBar>
                    ) : (
                        <span className="text-danger">
                            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Failed
                        </span>
                    );
                } else if (field === 'SIZE') {
                    return <div className="text-center">{project.Files && project.Files.length ? project.Files[0].fileSize : ''}</div>
                }
                else if (field === 'PAGE') {
                    return <div className="text-center">{project.Files && project.Files.length && project.Files[0].pagesProcessed ? project.Files[0].pagesProcessed : ''}</div>
                }
                else if (field === 'LINK') {
                    const fileProcessed = project.projectProcessingStatus.toLowerCase() === 'completed';
                    const fileURL = project.Files && project.Files.length && project.Files[0].filePath ? project.Files[0].filePath : '';
                    if (fileProcessed && fileURL) {
                        return (
                            <div className="d-flex justify-content-between">
                                <a onClick={(e) => showFilePage(e, project)} title="View File"><i className="fa fa-file-pdf-o mr-2 bl-db-datable-icon" aria-hidden="true"></i></a>
                                <a onClick={(e) => openDeleteModal(e, project)} data-toggle="modal" data-target="#deleteprojectmodal" title="Delete Project"><i className="fa fa-trash bl-db-datable-icon" aria-hidden="true"></i></a>
                            </div>
                        )
                    }
                    return (
                        <div className="text-right">
                            <a onClick={(e) => openDeleteModal(e, project)} data-toggle="modal" data-target="#deleteprojectmodal"><i className="fa fa-trash" aria-hidden="true"></i></a>
                        </div>
                    );
                } else if (field === 'CREATED_BY') {
                    return (
                        <div className="d-flex align-items-center">
                            <i className="fa fa-user-circle-o mr-1" aria-hidden="true"></i>
                            <span>{project.createdUserName ? project.createdUserName : project.createdBy}</span>
                        </div>
                    )
                } else if (field === 'MODIFIED_BY') {
                    if (project.lastModifiedBy) {
                        return (
                            <div className="d-flex align-items-center">
                                <i className="fa fa-user-circle-o mr-1" aria-hidden="true"></i>
                                <span>{project.lastModifiedUserName ? project.lastModifiedUserName : project.lastModifiedBy}</span>
                            </div>
                        )
                    }
                    return null;
                }
                else if (field === 'CREATE' || field === 'MODIFY') {
                    return dateFormat(project, field)
                }
            }

        } else return '';
    };

    const openDeleteModal = (e, project) => {
        e.preventDefault();
        setSelectedProject(project)
    }

    const deleteProject = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex'
        }
        setDeleteProjectCount(deleteProjectCount+1)
        const resp = await removeProjectService(selectedProject.projectId);
        if (resp && resp.success) {
            setAnalyzedCompanyInfo({ projectInfo: {...selectedProject}, deleteProjectNumber: deleteProjectCount })
            window.$("#deleteprojectmodal").modal('toggle');
            toastSuccess("Project removed successfully!")
            nav('/dealbook/home')
        }
        if (pageLoader) {
            pageLoader.style.display = 'none'
        }
    }

    const deleteProjectModalContent = () => {
        return (
            <div className="modal fade" id="deleteprojectmodal" tabIndex="-1" role="dialog" aria-labelledby="deleteCompanyProjectModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteCompanyProjectModalLabel">Delete Project</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove <b>"{selectedProject.projectName}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => deleteProject(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        // applyTheme()
        if (allProjects && allProjects.length) {
            const filteredProject = allProjects.filter(project => {
                const matchesSearch = project.projectName.toLowerCase().includes(searchProject.toLowerCase()) ||
                    (project.createdUserName && project.createdUserName.toLowerCase().includes(searchProject.toLowerCase())) ||
                    (project.lastModifiedUserName && project.lastModifiedUserName.toLowerCase().includes(searchProject.toLowerCase()));
                const ignoreRange = range[0] === min && range[1] === max;
                const inRange = ignoreRange || (project.totalScore && parseInt(project.totalScore) >= range[0] && parseInt(project.totalScore) <= range[1]);
                return matchesSearch && inRange;
            });

            setProjects(filteredProject)
        }
        else {
            setProjects([])
        }
    }, [searchProject, allProjects, range])

    const handleChange = (event) => {
        const value = event.target.value;
        const newRange = [...range];
        if (event.target.id === 'min-range') {
            newRange[0] = Math.min(value, range[1]);
        } else {
            newRange[1] = Math.max(value, range[0]);
        }
        setRange(newRange);
    };


    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-12">
                        <section>
                            <div className="media p-2">
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src="https://img.freepik.com/free-vector/manager-prioritizing-tasks-list_74855-5272.jpg" alt="overview-Img" />
                            </div>
                        </section>

                        <section className="mb-3">
                            <div className="card-deck">
                                {
                                    dealbookKPI && dealbookKPI.map((kpi, i) => {
                                        return (
                                            <div key={`testing-harness-kpi-${i}`} className="card shadow-sm">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="text-muted"><h6>{kpi.title}</h6></div>
                                                        {
                                                            kpi.icon ? <div className="icon-shape icon-md bg-light-primary text-primary rounded-2 bl-db-kpi-fontsize">
                                                                <i className={`fa fa-${kpi.icon} ${kpi.colorClass}`} aria-hidden="true"></i>
                                                            </div> : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <h5 className={`mb-0 ${kpi.title.toLowerCase().includes('pass') ? 'text-success' : kpi.title.toLowerCase().includes('fail') ? 'text-danger' : ''}`}><b>{kpi.description}</b></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="border-bottom">
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex">
                                                        <span className={`badge bl-chip-btn cp bl-sb-datatableTabs mr-2 ${viewType === 'ALL' ? 'active' : ''}`} onClick={() => setViewType('ALL')}>All Summaries</span>
                                                        {/* <span className={`badge bl-chip-btn cp mr-2 ${viewType === 'PUBLISHED' ? 'active' : ''}`} onClick={() => setViewType('PUBLISHED')}>Published Till Now</span> */}
                                                        <span className={`badge bl-chip-btn cp bl-sb-datatableTabs ${viewType === 'DRAFT' ? 'active' : ''}`} onClick={() => setViewType('DRAFT')}><i className="fa fa-pencil-square-o text-danger" aria-hidden="true" title="Draft"></i>In Draft<i className="text-muted">(only visible to you)</i></span>
                                                    </div>
                                                    <div className="d-flex align-self-center">
                                                        <div className="dropdown mr-2">
                                                            <button className={`btn btn-outline-secondary btn-sm dropdown-toggle ${range[0] == min && range[1] == max ? '' : 'active'}`} type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fa fa-filter" aria-hidden="true"></i>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right bl-db-sliderContainer" aria-labelledby="dropdownMenu2">
                                                                <div className="p-2 mt-2">
                                                                    <div className="slider-container">
                                                                        <input
                                                                            id="min-range"
                                                                            type="range"
                                                                            min={min}
                                                                            max={max}
                                                                            value={range[0]}
                                                                            onChange={handleChange}
                                                                            className="thumb thumb-left bl-range-slider"
                                                                        />
                                                                        <input
                                                                            id="max-range"
                                                                            type="range"
                                                                            min={min}
                                                                            max={max}
                                                                            value={range[1]}
                                                                            onChange={handleChange}
                                                                            className="thumb thumb-right bl-range-slider"
                                                                        />
                                                                        <div className="slider">
                                                                            <div
                                                                                className="slider-track"
                                                                                style={{ left: `${((range[0] - min) / (max - min)) * 100}%`, right: `${100 - ((range[1] - min) / (max - min)) * 100}%` }}
                                                                            ></div>
                                                                            <div
                                                                                className="slider-range"
                                                                                style={{ left: `${((range[0] - min) / (max - min)) * 100}%`, width: `${((range[1] - range[0]) / (max - min)) * 100}%` }}
                                                                            ></div>
                                                                        </div>
                                                                        <div className="font-small pt-2">Score Range: <b>{range[0]} - {range[1]}</b></div>
                                                                        <div className="font-smaller font-italic">(Drag the slider to apply filter)</div>
                                                                    </div>
                                                                    {/* <input className="w-100 bl-h-2px" type="range" data-type="double" min={min} max={max} value={range[1]} onChange={handleChange} />
                                                                    <span className="font-smaller font-italic">Score Range: 0-15</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="input-group w-auto">
                                                            <input type="text" value={searchProject} onChange={(e) => setSearchProject(e.target.value)} className="form-control form-control-sm" placeholder="Search by name" />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="fa fa-search font-smaller"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-filter py-3 d-none">
                                                    <div className="col-xl-12 col-md-12">
                                                        <div className="form-row blc_button align-items-end">
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="inputState">Industry</label>
                                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                                    <option>All...</option>
                                                                    <option>...</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="emprangestart">Total Employee Range</label>
                                                                <div className="d-flex">
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="emprangestart" placeholder="From" />
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem blc_button" id="emprangeend" placeholder="To" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="scorerangestart">Score Range</label>
                                                                <div className="d-flex">
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="scorerangestart" placeholder="From" />
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem blc_button" id="scorerangeend" placeholder="To" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="ebitdarangestart">Adj EBITDA</label>
                                                                <div className="d-flex">
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="ebitdarangestart" placeholder="From" />
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem blc_button" id="ebitdarangeend" placeholder="To" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="ebitdarangestart">Date Range</label>
                                                                <div className="d-flex">
                                                                    <input type="date" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="ebitdarangestart" placeholder="From" />
                                                                    <input type="date" className="form-control form-control-sm bl-w-5rem blc_button" id="ebitdarangeend" placeholder="To" />
                                                                </div>
                                                            </div>

                                                            <button className="btn btn-filter btn-sm">Apply Filter</button>
                                                            <button className="btn btn-link btn-sm bl-filter-clear">Clear All</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isLoading ? <div className="col text-center">
                                                    <img className="bl-list-loader" src={listViewLoader} />
                                                </div> : (
                                                    <DataTable value={projects} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm" selectionMode="single" onRowClick={getProjectSections}>
                                                        <Column field="projectName" header="Project Name" body={(project) => tableValues(project, 'NAME', 1)} sortable></Column>
                                                        <Column field="totalScore" header="Score" className="text-center" body={(project) => tableValues(project, 'SCORE', 2)} sortable></Column>
                                                        <Column field="publishedStatus" header="Status" body={(project) => tableValues(project, 'STATUS', 3)} sortable></Column>
                                                        <Column field="lastModifiedBy" header="Last Modified By" body={(project) => tableValues(project, 'MODIFIED_BY', 4)} sortable></Column>
                                                        <Column field="lastModifiedDate" header="Last Modified On" dataType="date" body={(e) => tableValues(e, 'MODIFY', 5)} sortable></Column>
                                                        <Column field="createdBy" header="Created By" body={(project) => tableValues(project, 'CREATED_BY', 6)} sortable></Column>
                                                        <Column field="createdOn" header="Created On" dataType="date" body={(e) => tableValues(e, 'CREATE', 7)} sortable></Column>
                                                        <Column field="Files[0].pagesProcessed" header="Pages Processed" className="text-center" body={(project) => tableValues(project, 'PAGE', 8)}></Column>
                                                        <Column field="size" header="Size" className="text-center" body={(project) => tableValues(project, 'SIZE', 9)}></Column>
                                                        <Column field="files" header="" body={(project) => tableValues(project, 'LINK', 10)}></Column>
                                                    </DataTable>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {
                deleteProjectModalContent()
            }
        </div>
    )
}