import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5Percent from "@amcharts/amcharts5/percent";


export const PIEChartComponent = ({ data, id="piechartdiv", height = '150px', hideCenterText=false, categoryField, innerRadius=50 }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }


        let root = am5.Root.new(id);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root._logo.dispose();

        var chart = root.container.children.push(am5Percent.PieChart.new(root, {
            innerRadius: hideCenterText ? 0 : innerRadius,
            layout: root.verticalLayout
        }));

        let series = chart.series.push(am5Percent.PieSeries.new(root, {
            valueField: categoryField && categoryField.valueField ? categoryField.valueField : "size",
            categoryField: categoryField && categoryField.categoryField ? categoryField.categoryField : "sector",
            fillField: "color"
            // valueField: '70'
        }));

        // series.get("colors").set("colors", [
        //     am5.color("#FF3342"),
        //     am5.color("#4FFF33"),
        //     am5.color("#FFFC33")
        //   ]);

        series.data.setAll(data);

        series.appear(1000, 100);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color('#5285d8'),
            fontSize: 14,
            text: hideCenterText ? '' : ''
        }));

        series.data.setAll(data);

        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);
        setChartRoot(root);

        return () => {
            // Cleanup when unmounting the component
            if (chartRoot) {
                chartRoot.dispose();
            }

        };
    }, [data]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
};
