import React, { useEffect, useState } from "react";
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const MultiTrendChartComponent = ({ id = 'multitrendchart', height = '100px', chartInfo }) => {

  const [chartRoot, setChartRoot] = useState(null);

  useEffect(() => {
    if (chartRoot) {
      chartRoot.dispose();
    }

    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0,
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 0
      })
    );

    chart.get("colors").set("step", 3);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        maxDeviation: 0.3,
        baseInterval: {
          timeUnit: "day",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, { minorGridEnabled: true }),
        tooltip: am5.Tooltip.new(root, {})
      }));

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {})
      }));

    yAxis.get("renderer").labels.template.setAll({
      fontSize: 11
    });

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 11
    });


    let series = [];

    for(let i=0; i<chartInfo.length; i++){
        const item = chartInfo[i];
        let seriesInfo = chart.series.push(am5xy.LineSeries.new(root, {
            name: item.strain,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            stroke: item.color,
            fill: item.color,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{name}: {value}"
            })
          }));
          
          seriesInfo.strokes.template.setAll({
            strokeWidth: 2
          });
          
          seriesInfo.get("tooltip").get("background").set("fillOpacity", 0.5);
          series.push(seriesInfo)
    }

    root.dateFormatter.setAll({
        dateFormat: "yyyy-MM-dd",
        dateFields: ["valueX"]
      });

      for(let i=0; i<series.length; i++){
        series[i].data.setAll(chartInfo[i].data);
        setTimeout(() => {
            series[i].appear(1000)
        })
      }

    chart.appear(1000, 100);

    setChartRoot(root);

    return () => {
      // Cleanup when unmounting the component
      if (chartRoot) {
        chartRoot.dispose();
      }

    };
  }, []);

  return <div id={id} style={{ width: '100%', height: height }}></div>;
}