import React, { useEffect, useRef } from 'react';
import MDEditor from '@uiw/react-md-editor';

export const DisplayMarkdownContent = ({ markdownText }) => {

    const markdownRef = useRef(null);

    useEffect(() => {
        if (markdownRef.current) {
            const anchorTags = markdownRef.current.querySelectorAll('a');
            anchorTags.forEach((anchor) => {
                const text = anchor.textContent;
                const plainTextNode = document.createTextNode(text);
                anchor.replaceWith(plainTextNode);
            });
        }
    }, [markdownText]);

    return (
        <div ref={markdownRef} data-color-mode={'light'}>
            <MDEditor.Markdown
                source={markdownText}
                style={{ whiteSpace: 'normal' }}
            />
        </div>
    )
};
