import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ModelEntityContext } from "../../../../services/context/entityContext";
import { getTableInformationService } from "../../../../services/modelService";
import { toastInfo } from "../../../../services/notifyService";


export const DataEntityManageComponent = () => {

    const location = useLocation()
    const nav = useNavigate()
    const { setTableBasicInfo } = useContext(ModelEntityContext)
    const [tableId, setTableId] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex';
        }
        setIsLoaded(false)
        setTableBasicInfo(null)
        getSectionInfo();
    }, [location.pathname])

    const getSectionInfo = () => {
        const pathArray = location.pathname.split('/');
        const id = pathArray[3] ? pathArray[3].split('-')[1] : null;
        if (id) {
            setTableId(parseInt(id))
        }
    }

    useEffect(() => {
        if (tableId) {
            getTableBasicInformation()
        }
    }, [tableId])

    const getTableBasicInformation = async () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            const resp = await getTableInformationService(tableId);
            if (resp && resp.success) {
                setTableBasicInfo(resp.data)
            }
            setIsLoaded(true)
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
        catch (e) {
            setIsLoaded(true)
            toastInfo("An error occured, please try again!")
            nav(`/admin/data-entity-configuration`)
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    return (
        isLoaded ?
            <Outlet /> : null
    )
}