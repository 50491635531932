import moment from "moment";
import Icons from "../assets/files/imageList";
import apiInstance from "./serviceMaster";


export const capitalizeFirstLetter = (string) => {
    const str = string.trim();
    return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const convertCamelCaseToSeparateWords = (camelCaseWord) => {
    if (!camelCaseWord) {
        return ''
    }
    const separateWords = camelCaseWord.replace(/([a-z])([A-Z])/g, '$1 $2');
    let words = separateWords.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    return words.replace(/_/g, ' ')
}

export const replaceUnderscoreFromWord = (words) => {
    return words.replace(/_/g, ' ')
}

export const getRandomColor = () => {
    const colors = ["#0b4b4c", "#76b7ab", "#ebdbce", "#bdb0a5", "#f3eae3", "#ddc0aa", "#bb987b"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex]
}

export const getRandomRGBAColor = () => {
    // Generate random values for red, green, blue, and alpha
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    const alpha = Math.random().toFixed(2); // Fixed to two decimal places

    // Create an RGBA-formatted color string
    const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;

    return rgbaColor;
}

export const truncateFileName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) {
        return fileName;
    } else {
        const extensionIndex = fileName.lastIndexOf('.');
        if (extensionIndex !== -1) {
            const extension = fileName.slice(extensionIndex);
            const truncatedName = fileName.slice(0, maxLength - extension.length);
            return truncatedName + '...' + extension;
        } else {
            return fileName.slice(0, maxLength);
        }
    }
}

export const getFormatedReportTime = (reportdate) => {
    if (reportdate) {
        let now = moment();
        let reportTime = moment(reportdate);
        let monthDiff = now.diff(reportTime, 'months');
        if (monthDiff === 0) {
            let dateDiff = now.diff(reportTime, 'days');
            if (dateDiff === 0) {
                let hoursDiff = now.diff(reportTime, 'hours');
                if (hoursDiff === 0) {
                    let mintDiff = now.diff(reportTime, 'minutes');
                    if (mintDiff === 0) {
                        return 'Updated now'
                    }
                    else return `Updated ${mintDiff} mins ago`
                }
                else return `Updated ${hoursDiff} hours ago`
            }
            else if (dateDiff === 1) {
                let dateCalendar = reportTime.calendar({
                    sameDay: '[Today at] hh:mm A',
                    nextDay: '[Tomorrow]',
                    nextWeek: 'dddd',
                    lastDay: '[Yesterday at] hh:mm A',
                    lastWeek: '[Last] dddd',
                    sameElse: 'MMM DD, YYYY'
                })
                return `Updated ${dateCalendar}`
            }
            else return `Updated ${dateDiff} days ago`
        }
        else return `Updated ${monthDiff} months ago`
    }
}

export const reportTypeBackground = (type) => {
    if (type) {
        let reportType = type.toLowerCase();
        if (reportType === 'powerbi') {
            return 'badge-warning'
        }
        else if (reportType === 'tableau') {
            return 'badge-primary'
        }
        else if (reportType === 'domo') {
            return 'badge-info'
        }
        else {
            return 'badge-secondary'
        }
    }
    else {
        return 'badge-light'
    }
}

export const getRelativeTime = (time) => {
    return moment(time).startOf('hour').fromNow();
}

export const getViewReportPath = (report) => {
    const categorySlug = report.catSlug ? report.catSlug :
        report.categoryInfo && report.categoryInfo.length ?
            report.categoryInfo[0].subcategorySlug ? report.categoryInfo[0].categorySlug ? `${report.categoryInfo[0].categorySlug}/${report.categoryInfo[0].subcategorySlug}`
                : `${report.categoryInfo[0].subcategorySlug}` : report.categoryInfo[0].categorySlug ? `${report.categoryInfo[0].categorySlug}` : '' : ''
    return `/analytics/reports/${categorySlug}/${report.reportNameSlug}`
}


export const appendStylesOnDangerBtn = (theme) => {
    const elements = document.getElementsByClassName('btn-danger');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        element.setAttribute('style', `
        background-color: ${theme.btnDangerBGColor} !important; border-color: ${theme.btnDangerBorderColor} !important; color: ${theme.btnDangerTextColor} !important
        `);
        element.addEventListener('mouseenter', () => {
            element.setAttribute('style', `
          background-color: ${theme.btnDangerHoverBGColor} !important; color: ${theme.btnDangerHoverTextColor} !important; border-color: ${theme.btnDangerBorderColor} !important;
          `);
        });

        element.addEventListener('mouseleave', () => {
            element.setAttribute('style', `
          background-color: ${theme.btnDangerBGColor} !important; border-color: ${theme.btnDangerBorderColor} !important; color: ${theme.btnDangerTextColor} !important
          `);
        });

    }
}

export const appendStylesOnPrimaryBtn = (theme) => {
    const elements = document.getElementsByClassName('btn-primary');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        element.setAttribute('style', `
        background-color: ${theme.btnPrimaryBGColor} !important; border-color: ${theme.btnPrimaryBorderColor} !important; color: ${theme.btnPrimaryTextColor} !important
        `);
        element.addEventListener('mouseenter', () => {
            element.setAttribute('style', `
          background-color: ${theme.btnPrimaryHoverBGColor} !important; border-color: ${theme.btnPrimaryBorderColor} !important; color: ${theme.btnPrimaryHoverTextColor} !important
          `);
        });

        element.addEventListener('mouseleave', () => {
            element.setAttribute('style', `
          background-color: ${theme.btnPrimaryBGColor} !important; border-color: ${theme.btnPrimaryBorderColor} !important; color: ${theme.btnPrimaryTextColor} !important
          `);
        });

    }
}

export const appendStylesOnOutlineBtn = (theme) => {
    const elements = document.getElementsByClassName('btn-outline-primary');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const classList = element.classList;
        const activeavail = classList.contains('active');
        if (activeavail) {
            element.setAttribute('style', `
        background-color: ${theme.btnOutlineHoverBGColor} !important; border-color: ${theme.btnOutlineBorderColor} !important; color: ${theme.btnOutlineHoverTextColor} !important;
        `);
        }
        else {
            element.setAttribute('style', `
            background-color: ${theme.btnOutlineBGColor} !important; border-color: ${theme.btnOutlineBorderColor} !important; color: ${theme.btnOutlineTextColor} !important;
            `);
        }

        element.addEventListener('mouseenter', () => {
            element.setAttribute('style', `
          background-color: ${theme.btnOutlineHoverBGColor} !important; border-color: ${theme.btnOutlineBorderColor} !important; color: ${theme.btnOutlineHoverTextColor} !important;
          `);
        });

        element.addEventListener('mouseleave', () => {
            if (activeavail) {
                element.setAttribute('style', `
            background-color: ${theme.btnOutlineHoverBGColor} !important; border-color: ${theme.btnOutlineBorderColor} !important; color: ${theme.btnOutlineHoverTextColor} !important;
            `);
            }
            else {
                element.setAttribute('style', `
                background-color: ${theme.btnOutlineBGColor} !important; border-color: ${theme.btnOutlineBorderColor} !important; color: ${theme.btnOutlineTextColor} !important;
                `);
            }
        });

    }
}

export const appendStyleOnAIAssistentText = (theme) => {
    const elements = document.getElementsByClassName("bl-assistant-sec");
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        element.setAttribute('style', `
        background-color: ${theme.assistantSecBGColor} !important; border-color: ${theme.assistantSecBorderColor} !important;
        `);
    }
}

export const appendStylesOnBadge = (theme) => {
    const elements = document.getElementsByClassName('bl-filter-chip');
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            element.setAttribute('style', `
            background-color: ${theme.btnPrimaryBGColor} !important;
            `);

            element.addEventListener('mouseenter', () => {
                element.setAttribute('style', `
              background-color: ${theme.btnOutlineHoverBGColor} !important; border-color: ${theme.btnOutlineBorderColor} !important; color: ${theme.btnOutlineHoverTextColor} !important;
              `);
            });
    
            element.addEventListener('mouseleave', () => {
                element.setAttribute('style', `
                    background-color: ${theme.btnPrimaryBGColor} !important; 
                    `);
            });
        }
}

export const appendStylesOnDashboard = (theme) => {
    const elements = document.getElementsByClassName('bg-dark');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        element.setAttribute('style', `
        background-color: ${theme.dashboardTopBGColor} !important;
        `);
    }
    const subMenuElement = document.getElementById("submenucontent");
    if (subMenuElement) {
        subMenuElement.setAttribute('style', `
        background-color: ${theme.dashboardTopBGColor} !important;
        `);
    }
}

export const appendStyleOnFloatBtn = (theme) => {
    const elements = document.getElementById("floatAIBtn");
    if (elements) {
        elements.setAttribute('style', `
        background-color: ${theme.floatBtnBGColor} !important;
        `)
    }
}

export const appendStylesOnActiveMenu = (theme) => {
    setTimeout(() => {
        const elements = document.querySelectorAll(".mainmenu .nav-item.active .active-menu");
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            element.setAttribute('style', `
                background-color: ${theme.menuActiveBGColor} !important;
            `);
        }
    }, 500)
}

export const appendThemeProperty = (theme) => {
    setTimeout(() => {
        appendStylesOnDangerBtn(theme)
        appendStylesOnPrimaryBtn(theme)
        appendStylesOnOutlineBtn(theme)
        appendStylesOnBadge(theme)
        appendStylesOnDashboard(theme)
        appendStyleOnFloatBtn(theme)
        appendStylesOnActiveMenu(theme)
        appendStyleOnAIAssistentText(theme)
    })
}

export const themeIcons = (type) => {
    const iconKeys = Object.keys(Icons);
    const iconList = [];
    for (let i = 0; i < iconKeys.length; i++) {
        iconList.push({
            icon: Icons[iconKeys[i]], name: iconKeys[i]
        })
    }
    const iconInfo = iconList.find(icon => icon.name === type);
    if (iconInfo && iconInfo.icon) {
        return iconInfo.icon;
    }
    else return ''
}

export const accordionDirection = (id, targetId) => {
    const element = document.getElementById(id);
    const targetElement = document.getElementById(targetId);
    if (element.classList.contains('show')) {
        targetElement.classList.add('fa-angle-down')
        targetElement.classList.remove('fa-angle-up')
    }
    else {
        targetElement.classList.add('fa-angle-up')
        targetElement.classList.remove('fa-angle-down')
    }
}

export const closeModal = (modalId) => {
    window.$(modalId).modal('hide');
}

export const generateSlug = (input) => {
    let inputString = "" + input;
    inputString = inputString.toLowerCase().trim();
    inputString = inputString.replace(/\s+/g, '-');
    return inputString;
}

const fileUpload = 'ai/data/upload';
const text = 'ai/question';
const uploadedFilesUrl = 'ai/file/audit';
const canabisRadditUrl = 'reddit/question';
const AIFileURL = "ai/file";

export const fileUploadService = async (file) => {
    try {
        return apiInstance.post(fileUpload, file)
    }
    catch (e) {
        return e;
    }
}

export const cannabisFileUploadService = async (file) => {
    try {
        return apiInstance.post(`${AIFileURL}/upload`, file)
    }
    catch (e) {
        return e;
    }
}

export const questionInputForServerService = async (question) => {
    try {
        return apiInstance.post(text, question)
    }
    catch (e) {
        return e;
    }
}

export const canabisQuestionInputForServerService = async (question) => {
    try {
        return apiInstance.post(canabisRadditUrl, question)
    }
    catch (e) {
        return e;
    }
}

export const getUploadedFilesService = async (obj) => {
    try {
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return apiInstance.get(`${uploadedFilesUrl}${queryParams}`)
    }
    catch (e) {
        return e;
    }
}

export const cannabisQueryService = async (fileName, query) => {
    try {
        return apiInstance.get(`${AIFileURL}/question/${fileName}/${query}`)
    }
    catch (e) {
        return e;
    }
}

export const getAppConfigService = async () => {
    try {
        return apiInstance.get(`application/config`)
    }
    catch (e) {
        return e;
    }
}

export const getOrganizationThemeService = async () => {
    try {
        return apiInstance.get(`application/theme`)
    }
    catch (e) {
        return e;
    }
}

export const getDashboardInfoService = async () => {
    try {
        return apiInstance.get(`dashboard`)
    }
    catch (e) {
        return e;
    }
}

export const getNewsFeedService = async (obj) => {
    try {
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return apiInstance.get(`dashboard/feed${queryParams}`)
    }
    catch (e) {
        return e;
    }
}

export const generateBriefingAppTransscriptService = async (obj) => {
    try {
        return apiInstance.post(`application/generate/transcript`, obj)
    }
    catch (e) {
        return e;
    }
}