import React, { useState } from 'react';

export const SpeakerBriefContext = React.createContext();

export const SpeakerBriefContextProvider = ({ children }) => {

    const [eventList, setEventList] = useState([]);
    const [eventBasicInfo, setEventBasicInfo] = useState(null);
    const [eventDetails, setEventDetails] = useState([]);
    const [viewType, setViewType] = useState("ALL");
    const [isLoading, setIsLoading] = useState(false);
    const [SBModels, setSBModels] = useState([]);

    return (
        <SpeakerBriefContext.Provider value={{ eventList, setEventList, viewType, setViewType, isLoading, setIsLoading, eventBasicInfo, setEventBasicInfo, eventDetails, setEventDetails, SBModels, setSBModels }}>
            {children}
        </SpeakerBriefContext.Provider>
    );
};
