import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";
import { SummarizerContext } from "../../services/context/summarizerContext";
import { getAnalyzedProjectListService, getProjectProcessingStatusService } from "../../services/summarizerService";
import moment from "moment";

export const DealBookComponent = () => {

    const { projectList, setProjectList, viewType, setIsLoading } = useContext(SummarizerContext)
    const { analyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const [retryCount, setRetryCount] = useState(0);
    const [intervalDelay, setIntervalDelay] = useState(5000);

    useEffect(() => {
        fetchCompanyList();
    }, [analyzedCompanyInfo, viewType]);

    const fetchCompanyList = async () => {
        setIsLoading(true)
        setProjectList([]);
        setTimeout(() => {
            getProjectList(1);
        }, 300)
    };

    const getProjectList = async (page) => {
        const limit = 30;
        const obj = {
            page: page, limit:limit, publishedStatus: viewType === "ALL" ? null : viewType
        }
        const resp = await getAnalyzedProjectListService(obj);
        getProjectListResponse(page, resp, limit);
        setIsLoading(false)
    };

    const getProjectListResponse = (page, resp, limit) => {
        if (resp && resp.success) {
            const newCompanyList = resp.data;
            setProjectList((companies) => [...companies, ...newCompanyList]);
            if (resp.data.length >= limit) {
                getProjectList(page + 1);
            }
        }
    };

    const getProjectProcessingStatus = async(id) => {
        try{
            const resp = await getProjectProcessingStatusService(id);
            if(resp && resp.success){
                return resp.data
            }
            return null
        }
        catch(e){
            console.log("Error ====>", e);
        }
    }

    useEffect(() => {
        // if (retryCount >= 15) {
        //     console.log("Max retry count reached. Stopping further retries.");
        //     return;
        // }

        const fetchCompanyData = async () => {
            let allInfoAvailable = true;
            const updatedCompanyList = await Promise.all(
                projectList.map(async (item) => {
                    const compDate = item.updated_at ? item.updated_at : moment();
                    const diffInHours = moment().diff(moment(compDate), 'hours', true)
                    if (Math.abs(diffInHours) < 1 && item.projectProcessingStatus.toLowerCase() === 'pending') {
                        allInfoAvailable = false;
                        try {
                            const info = await getProjectProcessingStatus(item.projectId);
                            if (info) {
                                return { ...item, ...info, reachedMaxLimit: retryCount === 4 };
                            }
                            if (retryCount === 4) {
                                return { ...item, reachedMaxLimit: true };
                            }
                        } catch (error) {
                            console.error(`Error fetching info for ID: ${item.id}`, error);
                        }
                    }
                    return item;
                })
            );
            if(retryCount >= 4){
                // setProjectList([])
                setTimeout(() => {
                    setProjectList(updatedCompanyList)
                }, 500)
            }
            else{
                setProjectList(updatedCompanyList);
            }            

            if (!allInfoAvailable) {
                setRetryCount((prev) => prev + 1);
                // setIntervalDelay((prev) => prev + 3000);
            }
        };

        const intervalId = setInterval(() => {
            fetchCompanyData();
        }, intervalDelay);

        return () => clearInterval(intervalId);
    }, [retryCount, intervalDelay, projectList]);




    return (
        <Outlet />
    )
}