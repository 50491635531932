import moment from "moment";

export const validateNumberOnTextInput = (event) => {
  if (
    !(
      (event.key >= '0' && event.key <= '9') ||
      event.key === '.' ||
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight'
    )
  ) {
    event.preventDefault();
  }
}

export const getValueIgnoreCase = (obj, key) => {
  const normalizedKey = key.toLowerCase();
  for (const objKey in obj) {
    if (obj.hasOwnProperty(objKey) && objKey.toLowerCase() === normalizedKey) {
      return { originalKey: objKey, value: obj[objKey] };
    }
  }
  return undefined;
}

export const validateAndFormatDate = (date) => {
  if (date) {
    const dateCalendar = moment(date).calendar({
      sameDay: '[Today at] hh:mm A',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday at] hh:mm A',
      lastWeek: '[Last] dddd',
      sameElse: 'MMM DD, YYYY hh:mm A'
    })
    return dateCalendar;
  }
  return null;
}