import React, { useEffect, useState } from "react";
import testingFormLabel from "../../../../utils/property-file/testingHarness/formsLabel.json";
import { getTestPlanInfoService } from "../../../../services/testService";
import { useLocation } from "react-router-dom";


export const ViewTestPlanComponent = ({ getPlanName }) => {

    const location = useLocation()
    const [testPlanId, setTestPlanId] = useState('')
    const [testPlanName, setTestPlanName] = useState('')
    const [thresholdPercentage, setThresholdPercentage] = useState('')
    const [testCases, setTestCases] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const pathName = location.pathname.split('/');
        const planId = pathName[pathName.length - 1];
        setTestPlanId(planId)
    }, [location.pathname])

    useEffect(() => {
        if (testPlanId) {
            getTestPlanInfo(1)
        }
    }, [testPlanId])

    const getTestPlanInfo = async (page) => {
        const limit = 50;
        const obj = {
            page: page, limit: limit
        }
        const resp = await getTestPlanInfoService(obj, testPlanId);
        if (resp && resp.success && resp.data) {
            const planInfo = resp.data.data ? resp.data.data : resp.data;
            getPlanName(planInfo.testPlanName)
            setTestPlanName(planInfo.testPlanName)
            setThresholdPercentage(planInfo.passThresholdPercentage)
            if (planInfo.testCases && planInfo.testCases.length) {
                const planSummary = planInfo.testCases.map(query => {
                    return {
                        testPlanMasterId: testPlanId,
                        testCaseMasterId: query.testCaseMasterId,
                        userQuery: query.question,
                        expectedResult: query.expectedAnswer
                    }
                })
                setTestCases((prevData) => [...prevData, ...planSummary])
            }
            setIsLoading(false)
            if(planInfo.testCases && planInfo.testCases.length === limit){
                getTestPlanInfo(page+1)
            }
        }
    }

    return (
        <>
            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2 shadow-sm">
                <div className="card-body">
                    <h5 className="card-title">{testingFormLabel.FORMS.BASIC.TITLE}</h5>
                    <p className="card-text">{testingFormLabel.FORMS.BASIC.DESCRIPTION}</p>
                </div>
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td width="30%"><div className="text-small">{testingFormLabel.FORMS.BASIC.PLAN_NAME}</div></td>
                            <td width="40%">
                                {testPlanName}
                            </td>
                        </tr>
                        <tr>
                            <td><div className="text-small">{testingFormLabel.FORMS.BASIC.THRESHOLD}</div></td>
                            <td>
                                {thresholdPercentage}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                <div className="card-body">
                    <h5 className="card-title">{testingFormLabel.FORMS.QUESTION_SECTION.TITLE}</h5>
                    <p className="card-text">{testingFormLabel.FORMS.QUESTION_SECTION.DESCRIPTION}</p>
                </div>
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Expected Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            testCases.map((query, i) => {
                                return (
                                    <tr key={`test-case-query-sec-${i}`}>
                                        <td>
                                            {query.userQuery}
                                        </td>
                                        <td>
                                            {query.expectedResult}
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
        </>
    )
}