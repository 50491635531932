import React, { useState } from 'react';
import SearchContext from './searchContext';
import { getRandomColor } from '../../commonService';

const SearchContextProvider = ({ children }) => {
  const [globalSearch, setGlobalSearch] = useState('');
  const [profileBGColor, setProfileBGColor] = useState(getRandomColor())

  return (
    <SearchContext.Provider value={{ globalSearch, setGlobalSearch, profileBGColor, setProfileBGColor }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;