import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getProjectSummaryInfoService } from "../../../services/summarizerService";
import { SummarizerContext } from "../../../services/context/summarizerContext";
import { AnalyzeCompanyContext } from "../../../services/context/analyzeCompanyContext";
import { getValueIgnoreCase } from "../../../utils/validation";

export const DealBookProjectIndexComponent = () => {

    const location = useLocation();
    const { projectSections, projectInfo, setProjectCompanyInfo, setScorecard } = useContext(SummarizerContext)
    const { analyzedCompanyInfo } = useContext(AnalyzeCompanyContext)

    const [summaryId, setSummaryId] = useState(null)
    const [companyName, setCompanyName] = useState("")
    const [website, setWebsite] = useState("")

    useEffect(() => {
        getActiveSectionInfo();
    }, [location.pathname, projectSections])

    const getActiveSectionInfo = () => {
        const pathArray = location.pathname.split('/');
        const secSlug = pathArray[3];
        const secInfo = projectSections && projectSections.find(sec => sec.slug === secSlug);
        setSummaryId(secInfo && secInfo.summaryId ? secInfo.summaryId : projectSections && projectSections.length ? projectSections[0].summaryId : null)
    }

    useEffect(() => {
        if (summaryId && projectInfo && projectInfo.projectId) {
            getProjectSummaryInfo()
        }
    }, [summaryId, projectInfo, analyzedCompanyInfo])

    useEffect(() => {
        getProjectScorecard()
    }, [projectSections, analyzedCompanyInfo])

    useEffect(() => {
        const compInfo = {
            companyName:companyName, website:website
        }
        setProjectCompanyInfo(compInfo)
    }, [companyName, website])

    const getProjectSummaryInfo = async () => {
        try {
            const resp = await getProjectSummaryInfoService(projectInfo.projectId, summaryId);
            if (resp && resp.success) {
                const headerInfoSec = resp.data[0].subCategories.find(sub => sub.subcategoryName.toLowerCase().includes('header details'));
                const headerObject = headerInfoSec ? headerInfoSec.manualOverrideText ? headerInfoSec.manualOverrideText : headerInfoSec.kairosOutputText ? headerInfoSec.kairosOutputText : null : null;
                const isJsonContent = headerObject && !headerObject.trim().startsWith('<');
                if (headerObject && isJsonContent) {
                    const headerInfo = JSON.parse(headerObject);
                    if (headerInfo) {
                        const companyName = getValueIgnoreCase(headerInfo, 'company name');
                        if (headerInfo.companyName || companyName.value) {
                            setCompanyName(headerInfo.companyName || companyName.value)
                        }
                        const companyWebsite = getValueIgnoreCase(headerInfo, 'website');
                        if (headerInfo.website || companyWebsite.value) {
                            setWebsite(headerInfo.website || companyWebsite.value)
                        }
                    }
                }
            }
        }
        catch (e) {
        }

    }

    const getProjectScorecard = async() => {
        const scorecardSec = projectSections && projectSections.find(sec => sec.sectionIdentifier && sec.sectionIdentifier === "scoreCard");
        if(scorecardSec && scorecardSec.summaryId){
            const resp = await getProjectSummaryInfoService(projectInfo.projectId, scorecardSec.summaryId);
            if(resp && resp.success && resp.data){
                if(resp.data.length && resp.data[0].subCategories && resp.data[0].subCategories.length){
                    const scoresSummaryId = resp.data[0].subCategories[0].summaryId;
                    const scorecardCategories = resp.data[0].subCategories[0].manualOverrideText ? resp.data[0].subCategories[0].manualOverrideText : resp.data[0].subCategories[0].kairosOutputText;
                    const scorecardInfo = scorecardCategories ? JSON.parse(scorecardCategories) : [];
                    const scorecardData = scorecardInfo.data && scorecardInfo.data.map(s => {
                        s.summaryId = scoresSummaryId
                        return s;
                    })
                    setScorecard(scorecardData ? scorecardData : [])
                }
                
            }
        }
    }

    return (
        <Outlet />
    )
}