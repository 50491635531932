import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import reportThumbnail1 from "./../../assets/img/report/thumbnail/Rep1_Thumb.png"
import reportThumbnail2 from "./../../assets/img/report/thumbnail/Rep2_Thumb.png"
import reportThumbnail3 from "./../../assets/img/report/thumbnail/Rep3_Thumb.png"
import reportThumbnail4 from "./../../assets/img/report/thumbnail/Rep4_Thumb.png"
import reportThumbnail5 from "./../../assets/img/report/thumbnail/Rep5_Thumb.png"
import reportThumbnail6 from "./../../assets/img/report/thumbnail/Rep6_Thumb.png"
import reportThumbnail7 from "./../../assets/img/report/thumbnail/Rep7_Thumb.png"
import { getFormatedReportTime, getViewReportPath, reportTypeBackground } from "../../services/commonService";


export const GridViewComponent = ({ reports }) => {

    const [thumbnail] = useState([
        {
            id:1,
            icon:reportThumbnail1
        },
        {
            id:2,
            icon:reportThumbnail2
        },
        {
            id:3,
            icon:reportThumbnail3
        },
        {
            id:4,
            icon:reportThumbnail4
        },
        {
            id:5,
            icon:reportThumbnail5
        },{
            id:6,
            icon:reportThumbnail6
        },{
            id:7,
            icon:reportThumbnail7
        },
        {
            id:8,
            icon:reportThumbnail1
        },
        {
            id:9,
            icon:reportThumbnail2
        },
        {
            id:10,
            icon:reportThumbnail3
        },
        {
            id:11,
            icon:reportThumbnail4
        },
        {
            id:12,
            icon:reportThumbnail5
        },{
            id:13,
            icon:reportThumbnail6
        },{
            id:14,
            icon:reportThumbnail7
        }
    ]);
    const [reportList, setReportList] = useState(reports);
    const nav = useNavigate();

    useEffect(()=>{
        let repo = reports.map((r)=>{
            let icons = thumbnail.find(t=>t.id === r.reportId);
            if(icons){
                r.icon = icons.icon;
            }
            else {
                r.icon = thumbnail[0].icon;
            }
            return r;
        });
        setReportList(repo)
        
    }, [reports])

    const openReport = (report) => {
        const path = getViewReportPath(report);
        if(path){
            nav(path)
        }
        
    }

    return (
        <div className="row">
            {
                reportList.map((report, i) => {
                    return (
                        <div key={`grid-report-${i + 1}`} className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="card shadow-sm mb-4">
                                <img className="img-fluid card-img-top bl-grid-img" src={report.thumbnailUrl ? report.thumbnailUrl : report.icon} alt="Card cap" />
                                {/* <!-- Card Body --> */}
                                <div className="card-body">
                                    <h6 className="card-title">{report.reportName}</h6>
                                    <p className="card-text vista_text h-45px">{report.reportDescription}</p>
                                    <p className="card-text"><span className={`badge badge-pill ${reportTypeBackground(report.reportType ? report.reportType : 'PowerBI')}`}>{report.reportType ? report.reportType : 'PowerBI'}</span>&nbsp;&nbsp;<small className="text-muted">{getFormatedReportTime(report.updatedAt)}</small></p>
                                    <div className="d-flex justify-content-between mb-0">
                                        <div>
                                            {!report.hasAccess ? (
                                                <a className="cp" rel="nofollow" onClick={()=>openReport(report)}>Open Report &rarr;</a>
                                            ) : (<a className="cp" rel="nofollow">Request Access &rarr;</a>)}
                                        </div>
                                        <div>
                                            {
                                                report.pinned ? (<i className="fa fa-thumb-tack" aria-hidden="true"></i>) : (<i className="fa fa-thumb-tack text-gray" aria-hidden="true"></i>)
                                            }
                                        </div>
                                        {/* <div className="star-rating">
                                            <input type="checkbox" checked={report.pinned} id={`mark-fav${report.id}`} value="1" />
                                            <label htmlFor={`mark-fav${report.id}`} className="star mb-0" title="Mark Favorite">&#9733;</label>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}