import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { SpeakerBriefContext } from "../../services/context/speakerBriefContext";
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";
import { getEventStatusService, getSBEventListService, getSBModelsService } from "../../services/speakerBriefService";


export const SpeakerBriefIndexComponent = () => {

    const { eventList, setEventList, viewType, setIsLoading, setSBModels } = useContext(SpeakerBriefContext)
    const { analyzedCompanyInfo } = useContext(AnalyzeCompanyContext)

    useEffect(() => {
        getSBModels(1)
    }, [])

    useEffect(() => {
        fetchEventList();
    }, [analyzedCompanyInfo, viewType]);

    const fetchEventList = async () => {
        setIsLoading(true)
        setEventList([]);
        setTimeout(() => {
            getEventList(1);
        }, 300)
    };

    const getEventList = async (page) => {
        const limit = 30;
        const obj = {
            page: page, limit:limit, publishedStatus: viewType === "ALL" ? null : viewType
        }
        const resp = await getSBEventListService(obj);
        getEventListResponse(page, resp, limit);
        setIsLoading(false)
    };

    const getEventListResponse = (page, resp, limit) => {
        if (resp && resp.success) {
            const newEventList = resp.data;
            setEventList((event) => [...event, ...newEventList]);
            if (resp.data.length >= limit) {
                getEventList(page + 1);
            }
        }
    };

    const getSBModels = async(page) => {
        const limit = 10;
        const obj = {
            page: page, limit: limit
        }
        const resp = await getSBModelsService(obj)
        if(resp && resp.success){
            const models = resp.data;
            setSBModels((model) => [...model, ...models]);
            if (resp.data.length >= limit) {
                getSBModels(page + 1);
            }
        }
    }

    useEffect(() => {
        if(eventList && eventList.length){
            const processingEvent = eventList.find(event => event.processingStatus.toLowerCase() === 'pending');
            if(processingEvent){
                getEventStatusById(processingEvent.eventId)
            }
        }
    }, [eventList])

    const getEventStatusById = async(id) => {
        const resp = await getEventStatusService(id);
        if(resp && resp.success){
            const eventInfo = resp.data;
            if(eventInfo && eventInfo.processingStatus.toLowerCase() === 'completed'){
                fetchEventList()
            }
            else {
                setTimeout(() => {
                    getEventStatusById(id)
                }, 3000)
            }
        }
    }

    return (
        <Outlet />
    )
}