import React, { useEffect, useState } from "react";


export const InnerHTMLComponent = ({ htmlContent, contentId = 'innerhtmlcontent', isChartElement = false }) => {

    const [content, setContent] = useState('');

    useEffect(() => {
        if (htmlContent) {
            setTimeout(() => {
                const bodyElement = document.getElementById(contentId);
                if (!isChartElement && bodyElement) {
                    let elementsInBody = bodyElement.querySelectorAll('*');
                    if (elementsInBody) {
                        elementsInBody.forEach(function (element) {
                            if (element.childNodes.length === 1 && element.childNodes[0].nodeType === Node.TEXT_NODE) {
                                let textContent = element.textContent;
                                if (textContent.includes('_')) {
                                    textContent = textContent.replace(/_/g, ' ');
                                    element.textContent = capitalizeFirstLetter(textContent);
                                }
                            }
                        });
                    }
                }

                if (isChartElement) {
                    const blob = new Blob([htmlContent], { type: 'text/html' });
                    const fileUrl = window.URL.createObjectURL(blob);
                    setContent(`<iframe id=iframeElement${contentId} src="${fileUrl}" width="100%"></iframe>`);
                }
                else {
                    setContent(htmlContent)
                }

                const parentElement = document.getElementById(contentId);
                if (parentElement) {
                    let tableElement = parentElement.querySelector('table');
                    if (tableElement) {
                        tableElement.classList.add('table', 'table-sm', 'border');
                    }
                }
                if (bodyElement && isChartElement) {
                    setTimeout(() => {
                        const iframeElement = bodyElement.querySelector('iframe');
                        if (iframeElement) {
                            const iframeDocument = iframeElement.contentDocument || iframeElement.contentWindow.document;
                            const canvas = iframeDocument.querySelector('canvas');
                            if (canvas) {
                                const canvasStyle = window.getComputedStyle(canvas);
                                if (canvasStyle && canvasStyle.height) {
                                    const currentHeight = parseInt(canvasStyle.height.replace('px', ''), 10);
                                    const newHeight = currentHeight + 20;
                                    iframeElement.style.height = newHeight+'px';
                                }
                            }
                        }

                    }, 800)
                }

            }, 1000)
        }

    }, [htmlContent, contentId])

    function capitalizeFirstLetter(text) {
        return text.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
    }

    return (
        <div id={contentId} className="bl-inner-html-content">
            {
                isChartElement && !content ?
                    <div className="d-flex align-items-center bl-minh-15rem">
                        <div className="col">
                            <span className="loader"></span>
                        </div>
                    </div>
                    : null
            }
            <div id="" dangerouslySetInnerHTML={{ __html: isChartElement ? content : htmlContent }} />
        </div>
    )

}