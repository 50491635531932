import axios from "axios";
import { getToken, removeToken } from "./authService";
const apiKey = process.env.REACT_APP_BASE_URL;
const { protocol, hostname } = window.location;

const apiGateway = "/bl-pt-api-gateway/";
const baseUrl = hostname.includes('localhost') ? apiKey : `${protocol}//${hostname}${apiGateway}`

const apiInstance = axios.create({
    baseURL: baseUrl
})

apiInstance.interceptors.request.use((config) => {
    if (getToken()) {
        config.headers['Authorization'] = getToken();
    }
    return config;
}, (err) => {
    return Promise.reject(err);
})

apiInstance.interceptors.response.use((res) => {
    return res.data;
}, (err) => {
    if (!err || !err.response) {
        window.location.href = '/#/system-down'
    }
    else if (err.response.status === 401) {
        removeToken()
        window.location.href = "/";
    }
    else if (err.response.status >= 500) {
        window.location.href = "/#/error";
    }

    return Promise.reject(err.response);
})

export default apiInstance;