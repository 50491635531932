import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { SpeakerBriefContext } from "../../../services/context/speakerBriefContext";
import { AnalyzeCompanyContext } from "../../../services/context/analyzeCompanyContext";
import taskIcon from "./../../../assets/icons/taskIcon.svg";
import { deleteSBEventInformationService, publishEventResponseService } from "../../../services/speakerBriefService";
import { toastSuccess } from "../../../services/notifyService";

export const SpeakerBriefEventsTitle = () => {

    const { eventBasicInfo } = useContext(SpeakerBriefContext)
    const { setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const nav = useNavigate();
    const location = useLocation();
    const [eventInfo, setEventInfo] = useState({
        eventName: "", createdUserName: null, createdOn: null, createdBy: null, publishedStatus: ""
    })

    useEffect(() => {
        if (eventBasicInfo) {
            setEventInfo(eventBasicInfo)
        }
    }, [eventBasicInfo, location.pathname])

    const deleteEvent = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex'
        }
        const resp = await deleteSBEventInformationService(eventBasicInfo.eventId);
        if (resp && resp.success) {
            setAnalyzedCompanyInfo({ message: "Event removed" })
            window.$("#deleteEventModal").modal('toggle');
            toastSuccess("Event removed successfully!")
            nav('/speaker-brief/dashboard')
        }
        if (pageLoader) {
            pageLoader.style.display = 'none'
        }
    }

    const publishEvent = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex'
        }
        const resp = await publishEventResponseService(eventBasicInfo.eventId);
        if (resp && resp.success) {
            setAnalyzedCompanyInfo({ message: "Event published" })
            window.$("#publishEventModal").modal('toggle');
            toastSuccess("Event published successfully!")
            nav('/speaker-brief/dashboard')
        }
        if (pageLoader) {
            pageLoader.style.display = 'none'
        }
    }

    const deleteCompanyProjectModalContent = () => {
        return (
            <div className="modal fade" id="deleteEventModal" tabIndex="-1" role="dialog" aria-labelledby="deleteEventModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteEventModalLabel">Delete Event</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove <b>"{eventInfo.eventName}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => deleteEvent(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const publishEventModalContent = () => {
        return (
            <div className="modal fade" id="publishEventModal" tabIndex="-1" role="dialog" aria-labelledby="publishEventModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="publishEventModalLabel">Publish Event</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to publish <b>"{eventInfo.eventName}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => publishEvent(e)}>Yes, Publish Now</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center d-print-none">
                <div className="sidebar-brand-icon mb-2">
                    <img src={"https://cdn-icons-png.flaticon.com/512/2497/2497043.png"} height="70px" />
                </div>
            </div>
            <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                <h4 className="h4 text-gray-800 text-center">Speaker Brief - {eventInfo.eventName}</h4>
            </div>
            <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                <p><span className="text-muted mr-2">Created By</span>{eventInfo.createdUserName ? eventInfo.createdUserName : eventInfo.createdBy} on {moment(eventInfo.createdOn).format('MMM DD, YYYY')} {eventInfo.publishedStatus.toLowerCase() === 'published' ? <span className="badge badge-info ml-2 d-print-none">Published</span> : <span className="text-danger ml-2 d-print-none">[ Draft ]</span>}</p>
            </div>
            {/* <div className="col-xl-12 col-md-12 d-flex align-items-center"> */}
                
                {/* <div className="sidebar-brand-icon mb-2">
                    <img src={taskIcon} height="80px" />
                </div> */}
                {/* <div className="d-flex">
                    <img src={taskIcon} alt="event logo" className="bl-h-4rem mr-3" />
                    <div>
                        <h5>{eventInfo.eventName}</h5>
                        <p className="mb-0 font-small"><i><span className="text-muted mr-2">Created By</span>{eventInfo.createdUserName ? eventInfo.createdUserName : eventInfo.createdBy} on {moment(eventInfo.createdOn).format('MMM DD, YYYY')}</i></p>
                    </div>
                </div> */}
            {/* </div> */}
            {deleteCompanyProjectModalContent()}
            {publishEventModalContent()}
        </div>
    )
}