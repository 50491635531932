import React, { useContext, useEffect, useState } from "react";
import { DealbookCompanyTitle } from "./Common/companyTitle";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SummarizerContext } from "../../../services/context/summarizerContext";
import { getProjectSummaryInfoService } from "../../../services/summarizerService";
import { convertCamelCaseToSeparateWords } from "../../../services/commonService";
import { ExportProjectSummaryPDF } from "./summaryPdf";
import { DisplayMarkdownContent } from "../../../utils/markdownContent";
import { getValueIgnoreCase } from "../../../utils/validation";


export const DealbookCompanyProfile = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { projectSections, projectInfo, setProjectMode, scorecard } = useContext(SummarizerContext)
    const location = useLocation();
    const nav = useNavigate()

    useEffect(() => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex'
        }
        const secInfo = getActiveSectionInfo();
        setBreadcrumbMenu([
            { slug: '/dealbook', menuName: 'DealBook', isActive: true, hasSubMenu: true },
            { slug: `/dealbook/project/${secInfo.slug}`, menuName: secInfo.categoryName, isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname, projectSections])

    const [projectId, setProjectId] = useState(null)
    const [summaryId, setSummaryId] = useState(null)
    const [isHeaderVisible, setIsHeaderVisible] = useState(false)
    const [subSections, setSubSections] = useState([])
    const [aboutCompany, setAboutCompany] = useState({
        description: "", location: "N/A", employee: "N/A", founded: "N/A"
    })
    const [keyHighlights, setKeyHighlights] = useState([])
    const [headerRef, setHeaderRef] = useState(null)
    const [secReference, setSecReference] = useState([])
    const [selectedSectionSummary, setSelectedSectionSummary] = useState(null)
    const [headerSummaryId, setHeaderSummaryId] = useState(null)
    const [selectedHeader, setSelectedHeader] = useState("ABOUT")

    const getActiveSectionInfo = () => {
        const pathArray = location.pathname.split('/');
        const secSlug = pathArray[3];
        const secInfo = projectSections && projectSections.find(sec => sec.slug === secSlug);
        setSummaryId(secInfo && secInfo.summaryId ? secInfo.summaryId : '')
        return secInfo ? secInfo : { categoryName: '', slug: '' };
    }

    useEffect(() => {
        setIsHeaderVisible(false)
        setSubSections([])
        setAboutCompany({
            description: "", location: "N/A", employee: "N/A", founded: "N/A"
        })
        setKeyHighlights([])
    }, [location.pathname])

    useEffect(() => {
        if (summaryId) {
            getProjectSummaryInfo()
        }
    }, [summaryId])

    useEffect(() => {
        if (projectInfo && projectInfo.projectId) {
            setProjectId(projectInfo.projectId)
        }
    }, [projectInfo])

    const getProjectSummaryInfo = async () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex';
        }
        try {
            const resp = await getProjectSummaryInfoService(projectId, summaryId);
            if (resp && resp.success) {
                const headerInfoSec = resp.data[0].subCategories.find(sub => sub.subcategoryName && sub.subcategoryName.toLowerCase().includes('header details'));
                setIsHeaderVisible(!!headerInfoSec)
                const headerObject = headerInfoSec ? headerInfoSec.manualOverrideText ? headerInfoSec.manualOverrideText : headerInfoSec.kairosOutputText ? headerInfoSec.kairosOutputText : null : null;
                const isJsonContent = headerObject && !headerObject.trim().startsWith('<');
                if (headerObject && isJsonContent) {
                    setHeaderRef(headerInfoSec)
                    setSecReference(headerInfoSec.summaryReferences ? headerInfoSec.summaryReferences : [])
                    setHeaderSummaryId(headerInfoSec.summaryId)
                    setSelectedSectionSummary(headerInfoSec.summaryId)
                    const predefinedKeys = ['website', 'numberofemployees', 'number of employees', 'location', 'foundationyear', 'foundation year', 'aboutthecompany', 'about the company']
                    const headerInfo = JSON.parse(headerInfoSec.kairosOutputText);
                    if (headerInfo) {
                        const matched = {};
                        const entries = [];
                        Object.keys(headerInfo).forEach(key => {
                            if (key && predefinedKeys.includes(key.toLowerCase())) {
                                matched[key] = headerInfo[key];
                            } else {
                                entries.push({ [key]: headerInfo[key] });
                            }
                        });
                        setAboutCompany(matched)
                        const headerArray = [];
                        for (let i = 0; i < entries.length; i += 2) {
                            let obj = { ...entries[i] };
                            if (entries[i + 1]) {
                                obj = { ...obj, ...entries[i + 1] };
                            }
                            headerArray.push(obj);
                        }
                        setKeyHighlights(headerArray)

                    }
                    // if (headerInfo && headerInfo.AboutTheCompany) {
                    //     const aboutComp = headerInfo.AboutTheCompany;
                    //     setAboutCompany({
                    //         description: aboutComp.Description ? aboutComp.Description : "",
                    //         location: aboutComp.Location ? aboutComp.Location : "N/A",
                    //         employee: aboutComp.Employees ? aboutComp.Employees : "N/A",
                    //         founded: aboutComp.Founded ? aboutComp.Founded : "N/A"
                    //     })
                    // }
                }
                const subSectionList = resp.data[0].subCategories.filter(sub => sub.subcategoryName && sub.subcategoryName.toLowerCase() !== "header details");
                subSectionList.sort((a, b) => a.positionInParent - b.positionInParent);
                setSubSections(subSectionList)
                if (!(headerObject && isJsonContent)) {
                    setSecReference(subSectionList[0].summaryReferences)
                    setSelectedSectionSummary(subSectionList[0].summaryId)
                }
            }

            setTimeout(() => {
                if (pageLoader) {
                    pageLoader.style.display = 'none'
                }
            }, 500)
        }
        catch (e) {
            if (pageLoader) {
                pageLoader.style.display = 'none'
            }
        }

    }

    const sectionReferenceInfo = (referenceInfo) => {
        if (referenceInfo.summaryId !== selectedSectionSummary) {
            setSecReference([])
            setTimeout(() => {
                setSelectedSectionSummary(referenceInfo.summaryId)
                setSecReference(referenceInfo.summaryReferences ? referenceInfo.summaryReferences : [])
            }, 300)
        }

    }

    const getTotalScore = () => {
        let score = 0;
        scorecard && scorecard.forEach(s => {
            score += parseInt(s.score);
        })
        return score;
    }

    const getAboutCompany = () => {
        const aboutComp = getValueIgnoreCase(aboutCompany, 'about the company');
        return aboutCompany.aboutTheCompany ? aboutCompany.aboutTheCompany : aboutComp && aboutComp.value ? aboutComp.value : '';
    }

    const getCompanyLocation = () => {
        const aboutComp = getValueIgnoreCase(aboutCompany, 'Location');
        return aboutCompany.location ? aboutCompany.location : aboutComp && aboutComp.value ? aboutComp.value : 'N/A';
    }

    const getCompanyFoundationYear = () => {
        const aboutComp = getValueIgnoreCase(aboutCompany, 'Foundation Year');
        return aboutCompany.foundationyear ? aboutCompany.foundationyear : aboutComp && aboutComp.value ? aboutComp.value : 'N/A';
    }

    const getCompanyEmployeesCount = () => {
        const aboutComp = getValueIgnoreCase(aboutCompany, 'Number of Employees');
        return aboutCompany.numberofemployees ? aboutCompany.numberofemployees : aboutComp && aboutComp.value ? aboutComp.value : 'N/A';
    }

    const profileSection = () => {
        return (
            <div>
                <section className="mb-4">
                    {
                        scorecard && scorecard.length ? (
                            <div className="row justify-content-md-center mb-4">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="card border-0">
                                        <div className="card-body bg-light rounded pb-0">
                                            <h6><i className="fa fa-calculator mr-2" aria-hidden="true"></i> Investment Attributes</h6>
                                            <table className="table bl-row-border-none pb-0 mb-0" border={0}>
                                                <tbody>
                                                    {
                                                        scorecard && scorecard.map((score, index) => {
                                                            return (
                                                                <tr key={`db-scorecard-index-${index}`}>
                                                                    <td className="pl-0 text-muted">{score.name}</td>
                                                                    <td><b>{score.score}</b></td>
                                                                    <td>{score.criteria_desc}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {/* @TODO: Get Clarification for total score */}
                                                    <tr className="border-top pb-0">
                                                        <td className="pl-0 text-muted"><b>Total</b></td>
                                                        <td><b>{getTotalScore()}</b></td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ) : null
                    }

                    <div className="row justify-content-md-center bl-profile-heighlights1">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="card-deck">
                                <div className={`card ${selectedSectionSummary === headerSummaryId && selectedHeader === 'ABOUT' ? 'shadow-sm border border-secondary' : ''}`} onClick={() => { sectionReferenceInfo(headerRef); setSelectedHeader('ABOUT') }}>
                                    <div className="card-header pl-2">
                                        <h6 className="pb-0 mb-0"><i className="fa fa-fw fa-info-circle" aria-hidden="true"></i> About
                                            {
                                                headerRef && headerRef.summaryReferences && headerRef.summaryReferences.length ? (
                                                    <span className="text-primary ml-2">
                                                        {
                                                            headerRef.summaryReferences.map((reference, index) => {
                                                                const images = headerRef.summaryReferences.map(sec => {
                                                                    return {
                                                                        page: sec.pageNumber,
                                                                        image: sec.imageUrl
                                                                    }
                                                                });
                                                                return (
                                                                    <a title="Reference page number from your file" key={`page-reference-header-${index}`} className="open-reference-modal cp px-1" data-toggle="modal" data-target="#pageReferanceImageModal" data-images={JSON.stringify(images)} data-info={reference.pageNumber}><span className="badge  px-1 py-1 badge-pill font-weight-light bl-db-pageBadge">P{reference.pageNumber}</span></a>
                                                                )
                                                            })
                                                        }
                                                    </span>
                                                ) : null
                                            }
                                        </h6>
                                    </div>
                                    <div className="card-body">
                                        <p className="bl-truncate-line-5">{getAboutCompany()}</p>
                                        <p><i className="fa fa-fw fa-location-arrow" aria-hidden="true"></i> {getCompanyLocation()}</p>
                                        <p><i className="fa fa-fw fa-users" aria-hidden="true"></i> {getCompanyEmployeesCount()}</p>
                                        <p><i className="fa fa-fw fa-calendar" aria-hidden="true"></i> {getCompanyFoundationYear()}</p>
                                    </div>
                                </div>
                                <div className={`card ${selectedSectionSummary === headerSummaryId && selectedHeader === 'DETAILS' ? 'shadow-sm border border-secondary' : ''}`} onClick={() => { sectionReferenceInfo(headerRef); setSelectedHeader('DETAILS') }}>
                                    <div className="card-header pl-2">
                                        <h6 className="mb-0 pb-0"><i className="fa fa-fw fa-bolt" aria-hidden="true"></i> Key Highlights
                                            {
                                                headerRef && headerRef.summaryReferences && headerRef.summaryReferences.length ? (
                                                    <span className="text-primary ml-2">
                                                        {
                                                            headerRef.summaryReferences.map((reference, index) => {
                                                                const images = headerRef.summaryReferences.map(sec => {
                                                                    return {
                                                                        page: sec.pageNumber,
                                                                        image: sec.imageUrl
                                                                    }
                                                                });
                                                                return (
                                                                    <a title="Reference page number from your file" key={`page-reference-header-${index}`} className="open-reference-modal cp px-1" data-toggle="modal" data-target="#pageReferanceImageModal" data-images={JSON.stringify(images)} data-info={reference.pageNumber}><span className="badge  px-1 py-1 badge-pill font-weight-light bl-db-pageBadge">P{reference.pageNumber}</span></a>
                                                                )
                                                            })
                                                        }
                                                    </span>
                                                ) : null
                                            }
                                        </h6>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                keyHighlights && keyHighlights.map((info, i) => {
                                                    return (
                                                        info && Object.entries(info).map(([key, value], j) => {
                                                            return (
                                                                <div key={`key-highlights-view-${i}${j}`} className="col-xl-6 col-md-6 col-6">
                                                                    <div className="text-muted">{key}</div>
                                                                    <p>{value}</p>
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }

    const navigateForModify = () => {
        setProjectMode('manage')
        const path = location.pathname.endsWith("/") ? `${location.pathname}manage` : `${location.pathname}/manage`;
        nav(path)
    }

    const downloadSummary = () => {
        const element = document.getElementById('downloadSummary');
        element.classList.remove('d-none');
        setTimeout(() => {
            const originalTitle = document.title;
            document.title = projectInfo && projectInfo.projectName ? projectInfo.projectName : originalTitle;
            window.print();
            document.title = originalTitle;
            setTimeout(() => {
                element.classList.add('d-none');
            }, 200)
        }, 500)
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3 d-print-none" >
                <div className="row bl-db-summaryContainerMargin">
                    <div className="col-12">
                        <div className="align-self-center bl-btn-grp-fixed position-absolute bl-top-0">
                            <button className="btn btn-outline-primary btn-sm mr-2 mb-0" onClick={downloadSummary}>
                                <i className={`fa fa-fw fa-download`} aria-hidden="true"></i> Download Summary
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0" onClick={() => navigateForModify()}>
                                <i className={`fa fa-fw fa-pencil`} aria-hidden="true"></i> Edit
                            </button>
                            <button className="btn btn-outline-primary btn-sm mb-0" data-toggle="modal" data-target="#deletecompanyprojectmodal">
                                <i className={`fa fa-fw fa-trash`} aria-hidden="true"></i>
                            </button>

                        </div>
                        <section className="mb-3">
                            <div id="comptitle">
                                <DealbookCompanyTitle />
                            </div>
                        </section>
                        {isHeaderVisible ? profileSection() : null}
                        {
                            subSections && subSections.map((sec, i) => {
                                return (
                                    <section key={`project-sub-sections-${i}`} className="mb-4">
                                        <div className="row justify-content-md-center">
                                            <div className="col-xl-12 col-md-12">
                                                <div className={`card ${selectedSectionSummary === sec.summaryId ? 'shadow-sm border border-secondary' : ''}`} onClick={() => sectionReferenceInfo(sec)}>
                                                    <div className="card-header">
                                                        <h6 className="mb-0 align-self-center">
                                                            <i className="fa fa-briefcase mr-2" aria-hidden="true"></i> {sec.subcategoryName}
                                                            {
                                                                sec.summaryReferences && sec.summaryReferences.length ? (
                                                                    <span className="text-primary ml-2">
                                                                        {
                                                                            sec.summaryReferences && sec.summaryReferences.length ? (
                                                                                <span className="text-primary ml-2">
                                                                                    {
                                                                                        sec.summaryReferences.map((reference, index) => {
                                                                                            const images = sec.summaryReferences.map(sec => {
                                                                                                return {
                                                                                                    page: sec.pageNumber,
                                                                                                    image: sec.imageUrl
                                                                                                }
                                                                                            });
                                                                                            return (
                                                                                                <a title="Reference page number from your file" key={`page-reference-header-${index}`} className="open-reference-modal cp px-1" data-toggle="modal" data-target="#pageReferanceImageModal" data-images={JSON.stringify(images)} data-info={reference.pageNumber}><span className="badge  px-1 py-1 badge-pill font-weight-light bl-db-pageBadge">P{reference.pageNumber}{sec.summaryReferences.length - 1 !== index ? '' : ''}</span></a>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </span>
                                                                            ) : null
                                                                        }
                                                                    </span>
                                                                ) : null
                                                            }
                                                        </h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <DisplayMarkdownContent markdownText={sec.manualOverrideText ? sec.manualOverrideText === 'N/A'? '' : sec.manualOverrideText : sec.kairosOutputText}></DisplayMarkdownContent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )
                            })
                        }
                    </div>
                    <div className="bl-fixed-right-panel p-4 rounded bg-light mt-n3">
                        <h6 className="mb-4">Linked Page Preview</h6>
                        {
                            secReference && secReference.length ? secReference.map((preview, index) => {
                                const images = secReference.map(sec => {
                                    return {
                                        page: sec.pageNumber,
                                        image: sec.imageUrl
                                    }
                                });
                                return (
                                    <div key={`reference-preview-${index}`} className="text-center">
                                        <img src={preview.imageUrl} className="img-fluid p-1 mb-2 shadow-sm border border-secondary open-reference-modal cp" alt="reference preview" data-toggle="modal" data-target="#pageReferanceImageModal" data-images={JSON.stringify(images)} data-info={preview.pageNumber} />
                                        <a><span className="badge px-1 py-1 badge-pill font-weight-light bl-db-pageBadge mb-3">P{preview.pageNumber}</span></a>
                                    </div>
                                )
                            }) : <h6 className="text-muted mt-3"><i>No linked pages to show</i></h6>
                        }
                    </div>
                </div>

            </div>
            <div id="downloadSummary" className="d-none mt-5">
                <ExportProjectSummaryPDF />
            </div>

        </div>
    )
}