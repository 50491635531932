import React, { useEffect, useState } from "react";
import { getAppBehaviour } from "../../services/authService";
import { themeIcons } from "../../services/commonService";

export const CardDropdownContent = ({ cardId = 'carddropdown', cardType, onChildCardEvent }) => {

    const [kairosIcon, setKairosIcon] = useState('')

    useEffect(() => {
        getAppThemeImages()
    }, [getAppBehaviour])

    const getAppThemeImages = () => {
        const appThemes = getAppBehaviour();
        if(appThemes && appThemes !== "undefined"){
            const themeName = JSON.parse(appThemes).themeName;
            const kairos_icon = themeIcons(`${themeName}_K_Icon`);
            setKairosIcon(kairos_icon)
        }
    }

    const onChildCall = (e) => {
        e.preventDefault();
        onChildCardEvent(cardType)
    }

    return (
        <div className="align-self-center">
            <button className="btn btn-link p-0 openAIModal" id={cardId} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div className="dropdown-menu" aria-labelledby={cardId}>
                <button className="dropdown-item bl_H1_Color pl-3">
                    <i className="fa fa-arrows-alt fa-sm mr-2"></i>
                    Expand Widget
                </button>
                <button className="dropdown-item bl_H1_Color pl-3">
                    <i className="fa fa-handshake-o fa-sm mr-2"></i>
                    Personalize
                </button>
                <button className="dropdown-item bl_H1_Color pl-3">
                    <i className="fa fa-download fa-sm mr-2"></i>
                    Download
                </button>
                <button className="dropdown-item bl_H1_Color pl-3">
                    <i className="fa fa-share fa-sm mr-2"></i>
                    Share
                </button>
                <div className="dropdown-divider m-0"></div>
                {
                    cardType ?
                        <button onClick={(e) => onChildCall(e)} className="dropdown-item text-danger pl-3" data-toggle="modal" data-target="#aimodalbox">
                            <img className="img-fluid mr-2" src={kairosIcon} />
                            Ask a question
                        </button> :
                        <button className="dropdown-item text-danger pl-3">
                            <img className="img-fluid mr-2" src={kairosIcon} />
                            Ask a question
                        </button>
                }

            </div>
        </div>
    )
}