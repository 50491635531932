import React, { useContext, useEffect, useState } from "react";
import testIcon from "./../../assets/icons/testIcon.svg";
import templateLabel from "./../../utils/property-file/dealbook/template.json";
import buttonsLabel from "../../utils/property-file/buttons-label.json";
import { useLocation, useNavigate } from "react-router-dom";
import listViewLoader from "./../../assets/img/loading-animation.gif";
import { getTemplateInfoService, updateTemplateSubCategoryPromptsService } from "../../services/summarizerService";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { toastSuccess } from "../../services/notifyService";


export const DealbookConfigTemplateComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation()

    const [action, setAction] = useState('NEW')
    const [templateName, setTemplateName] = useState('')
    const [templateDescription, setTemplateDescription] = useState('')
    const [keyPromptsSec] = useState({
        key: "", prompts: ""
    })
    const [keyPrompts, setKeyPrompts] = useState([keyPromptsSec])

    const [isLoading, setIsLoading] = useState(false)
    const [sections, setSections] = useState([])
    const [subSections, setSubSections] = useState([])
    const [templateId, setTemplateId] = useState(43)

    useEffect(() => {
        setIsLoading(false)
        setTemplateName('')
        setTemplateDescription('')
        getTemplateInfo()
        setBreadcrumbMenu([
            { slug: '/dealbook', menuName: 'DealBook', isActive: true, hasSubMenu: true },
            { slug: `/dealbook/templates`, menuName: "Configure Template", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    const getTemplateInfo = async () => {
        setIsLoading(true)
        const resp = await getTemplateInfoService(templateId);
        setIsLoading(false)
        if (resp && resp.success) {
            const templateInfo = resp.data.template;
            setTemplateName(templateInfo.templateName)
            setTemplateDescription(templateInfo.templateDescription)
            templateInfo.categories[0].selected = true;
            setSections(templateInfo.categories)
            setSubSections(templateInfo.categories[0].subCategories)
        }
    }

    const updateTemplateList = async() => {
        const prevSecPrompts = subSections.map(subSec => ({ ...subSec }));
        const updatedSections = sections.map(secItem => {
            return {
                ...secItem,
                subCategories: secItem.categoryId === prevSecPrompts[0]?.parentId ? prevSecPrompts : secItem.subCategories
            };
        });
        const updateInfo = updatedSections.flatMap(sec => 
            sec.subCategories.map(sub => ({
                categoryId: sub.categoryId,
                prompt: sub.prompt
            }))
        );
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex';
        }
        const resp = await updateTemplateSubCategoryPromptsService({prompts: updateInfo}, templateId)
        if(resp && resp.success){
            toastSuccess("Prompts updated successfully!")
        }
        if (pageLoader) {
            pageLoader.style.display = 'none';
        }
    };

    const changeSelectedSection = (sec, index) => {
        const prevSecPrompts = subSections.map(subSec => ({ ...subSec }));
        let updatedSections = sections.map(secItem => {
            return {
                ...secItem,
                selected: false,
                subCategories: secItem.categoryId === prevSecPrompts[0]?.parentId ? prevSecPrompts : secItem.subCategories
            };
        });
        updatedSections[index].selected = true;
        const subSectionsInfo = updatedSections[index].subCategories || [];
        setSections(updatedSections);
        setSubSections([])
        setTimeout(() => {
            setSubSections([...subSectionsInfo]);
        }, 400)    
    };
    

    const handleInputChange = (sectionIndex, promptIndex, value) => {
        setSubSections((prevSubSections) => {
          const updatedSubSections = [...prevSubSections];    
          if (promptIndex !== undefined) {
            updatedSubSections[sectionIndex] = {
              ...updatedSubSections[sectionIndex],
              prompt: updatedSubSections[sectionIndex].prompt.map((info, i) =>
                i === promptIndex ? { ...info, prompt: value } : info
              ),
            };
          } else {
            updatedSubSections[sectionIndex] = {
              ...updatedSubSections[sectionIndex],
              prompt: value,
            };
          }
          return updatedSubSections;
        });
      };

    return (
        <div id="content">
            <div className="container-fluid pt-3">           
                <div className={"d-inline-flex flex-row bl-btn-grp-fixed"}>
                    {/* {
                        action === "VIEW" ? <button data-toggle="modal" data-target={'#deleteReportModal'} className="d-sm-inline-block btn btn-sm btn-outline-primary mr-2"><i className="fa fa-trash fa-sm text-white-100"></i></button> : null
                    } */}
                    <button className="d-sm-inline-block btn btn-sm btn-outline-primary mr-2" onClick={() => nav('/dealbook/home')}><i className="fa fa-ban mr-1" aria-hidden="true"></i> Cancel</button>
                    <button className="d-sm-inline-block btn btn-sm btn-primary mr-2" onClick={() => updateTemplateList()}><i className="fa fa-floppy-o mr-1" aria-hidden="true"></i> {buttonsLabel.SAVE}</button>
                </div>
                
                <div className="row" id="bio">
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="sidebar-brand-icon mb-2">
                            <img src={testIcon} height="70px" />
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <h4 className="h4 text-gray-800">{action === "NEW" ? templateLabel.TITLE : templateLabel.TITLE_UPDATE}</h4>
                    </div>

                    
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <p>{action === "NEW" ? templateLabel.DESCRIPTION : templateLabel.DESCRIPTION_UPDATE}</p>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="d-flex py-3">
                            {
                                sections && sections.map((sec, i) => {
                                    return (
                                        <span key={`sections-badge-${i}`} className={`badge bl-sb-datatableTabs bl-chip-btn cp mr-2 ${sec.selected ? 'active' : ''}`} onClick={() => changeSelectedSection(sec, i)}>{sec.name}</span>
                                    )
                                })
                            }
                        </div>
                    </div>

                    {
                        isLoading ? <div className="col text-center">
                            <img className="bl-list-loader" src={listViewLoader} />
                        </div> : (
                            <>
                                <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                                    <div className="card-body">
                                        <h5 className="card-title">{templateLabel.FORMS.BASIC.TITLE}</h5>
                                        <p className="card-text">{action === "NEW" ? templateLabel.FORMS.BASIC.DESCRIPTION : templateLabel.FORMS.BASIC.DESCRIPTION}</p>
                                    </div>
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td className="w-25"><div className="text-small pt-2 pl-2">{templateLabel.FORMS.BASIC.TEMPLATE_NAME}</div></td>
                                                <td width="40%">
                                                    <input type="text" readOnly value={templateName} onChange={(e) => setTemplateName(e.target.value)} className="form-control" id="planename" placeholder="" />
                                                </td>
                                                <td>
                                                    <small id="passwordHelpInline" className="text-muted">
                                                        {templateLabel.FORMS.BASIC.TEMPLATE_VALID}
                                                    </small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><div className="text-small pt-2 pl-2">{templateLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION}</div></td>
                                                <td>
                                                    <textarea type="text" readOnly value={templateDescription} onChange={(e) => setTemplateDescription(e.target.value)} className="form-control" id="thresholdpercentage" placeholder="" />
                                                </td>
                                                <td>
                                                    <small id="passwordHelpInline" className="text-muted">
                                                        {templateLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION_VALID}
                                                    </small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                                    <div className="card-body">
                                        <h5 className="card-title">{templateLabel.FORMS.SECTION.TITLE}</h5>
                                        <p className="card-text">{action === "NEW" ? templateLabel.FORMS.SECTION.DESCRIPTION : templateLabel.FORMS.SECTION.DESCRIPTION}</p>
                                    </div>
                                    {
                                        subSections && subSections.map((secInfo, index) => {
                                            const canHeaderVisible = secInfo && secInfo.prompt && Array.isArray(secInfo.prompt);
                                            return (
                                                canHeaderVisible ? (
                                                    <div key={`category-sec-${index}`} className="card rounded-0">
                                                        <div className="card-header">
                                                            <h6 className="mb-0">{secInfo.name}</h6>
                                                        </div>
                                                        <div className="card-body p-0">
                                                            <table className="table">
                                                                <tbody>
                                                                    {
                                                                        secInfo.prompt.map((info, j) => {
                                                                            return (
                                                                                <tr key={`template-key-prompt-sec-${index}${j}`}>
                                                                                    <td className="w-25">
                                                                                        <div className="text-small pt-2 pl-2">{info.name}</div>
                                                                                        {/* <input type="text" name="key" value={info.name} onChange={(e) => setTemplateKey(e.target.value, j)} onBlur={() => updateTemplateList(info)} className="form-control" placeholder={templateLabel.FORMS.SECTION.KEY_PLACEHOLDER} /> */}
                                                                                    </td>
                                                                                    <td>
                                                                                        <textarea name={`prompt header ${secInfo.categoryId} ${index} ${j}`} className="form-control form-control-sm" rows={8} defaultValue={info.prompt} onChange={(e) => handleInputChange(index, j, e.target.value)}></textarea>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    < table key={`template-table-${index}`} className={`table mb-0`}>
                                                        <tbody>
                                                            <tr key={`template-key-prompt-sec-${index}`}>
                                                                <td className="w-25">
                                                                <div className="text-small pt-2 pl-2">{secInfo.name}</div>
                                                                    {/* <input type="text" name="key" value={secInfo.name} onChange={(e) => setTemplateKey(e.target.value, index)} onBlur={() => updateTemplateList(secInfo)} className="form-control" placeholder={templateLabel.FORMS.SECTION.KEY_PLACEHOLDER} /> */}
                                                                </td>
                                                                <td>
                                                                    <textarea className="form-control form-control-sm" id={`prompt${secInfo.categoryId}${index}`} name={`prompt ${secInfo.categoryId} ${index}`} rows={8} defaultValue={secInfo.prompt} onChange={(e) => handleInputChange(index, undefined, e.target.value)}></textarea>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                )

                                            )
                                        })
                                    }
                                </div>
                            </>
                        )
                    }
                </div>


            </div>
        </div >
    )
}