import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const ColumnChart = ({ data, height = '10rem', id = 'columnchartdiv', categoryField = 'Year', seriesItem = [] }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }

        let root = am5.Root.new(id);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root._logo.dispose();

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 5
        }));

        chart.children.unshift(am5.Label.new(root, {
            fontSize: 10
        }))

        let legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
                fontSize: 11,
                visible: false
            })
        );

        let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 10 });
        xRenderer.labels.template.setAll({
            // rotation: -30,
            centerY: am5.p50,
            centerX: am5.p50,
            paddingRight: 5,
            paddingLeft: 5,
            paddingTop: 5
        });

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: categoryField,
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        xRenderer.grid.template.setAll({
            location: 1
        })

        xAxis.data.setAll(data);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1
            })
        }));

        yAxis.get("renderer").labels.template.setAll({
            fontSize: 11
        });

        xAxis.get("renderer").labels.template.setAll({
            fontSize: 11
        });

        function makeSeries(name, fieldName) {
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: categoryField,
                fontSize: 11
            }));
            series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
            series.columns.template.adapters.add("fill", function (fill, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
              });
              
              series.columns.template.adapters.add("stroke", function (stroke, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
              });

            series.columns.template.setAll({
                tooltipText: "[fontSize: 10px;verticalAlign: super;]{valueYField}: {categoryX} ({valueY})",
                width: am5.percent(50),
                tooltipX: 0,
                tooltipY: 0,
                strokeOpacity: 0
            });
            series.data.setAll(data);
            series.appear();
            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        // fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.percent(50),
                        populateText: true,
                        fontSize: 11
                    })
                });
            });

            legend.data.push(series);
        }

        seriesItem.map(s => {
            makeSeries(s.name, s.val)
        })


        setChartRoot(root);

        return () => {
            if (chartRoot) {
                chartRoot.dispose();
            }
        }
    }, [data])

    return <div id={id} style={{ width: '100%', height: height }}></div>;
}