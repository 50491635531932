import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { SummarizerContext } from "../../../services/context/summarizerContext";


export const DealbookCompanyFiles = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { projectFiles, projectSections } = useContext(SummarizerContext)
    const location = useLocation();

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/dealbook', menuName: 'DealBook', isActive: true, hasSubMenu: true },
            { slug: `/dealbook/project/${'b2w-software'}`, menuName: 'B2W Software', isActive: false, hasSubMenu: true },
            { slug: `/dealbook/project/${'b2w-software'}/files`, menuName: 'Files', isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname, projectSections])

    const [metaInfo, setMetaInfo] = useState({
        fileName: "", fileType: "", totalPages: "N/A", fileSize: "", filePath: ""
    })

    useEffect(() => {
        if (projectFiles && projectFiles.length && projectFiles[0].fileDetails) {
            const fileName = getFileName(projectFiles[0].fileDetails.fileName);
            setMetaInfo({ ...projectFiles[0].fileDetails, fileName: fileName })
        }
    }, [projectFiles])

    const getFileName = (file) => {
        const fileNameArray = file.split('____');
        return fileNameArray[1]
    }

    const downloadFile = () => {
        let fileUrl = metaInfo.filePath; 
        const link = document.createElement("a");
        link.href = fileUrl;
        link.target = '_blank';
        link.download = metaInfo.fileName; 
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    

    return (
        <div id="content">

            <div className="container-fluid pt-3">
                <section>
                    <div className="row">
                        <div className="col-xl-10 col-md-10 col-12">
                            <embed src={metaInfo.filePath} type="application/pdf" className="bl-embed-file" width={'100%'}></embed>
                        </div>
                        <div className="col-2">
                            <h6>File details</h6>
                            <table className="table bl-row-border-none" border={'0'}>
                                <tbody>
                                    <tr>
                                        <td className="text-muted p-0">
                                            Name
                                            <p className="mb-4 p-0 text-dark"><a>{metaInfo.fileName}</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-0">
                                            Type
                                            <p className="mb-4 p-0 text-dark">{metaInfo.fileType === 'application/pdf' ? 'PDF' : metaInfo.fileType}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-0">
                                            Pages
                                            <p className="mb-4 p-0 text-dark">{metaInfo.totalPages}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-0">
                                            Size
                                            <p className="mb-4 p-0 text-dark">{metaInfo.fileSize}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-0">
                                            {/* <button onClick={() => downloadFile()} className="btn btn-outline-primary btn-sm btn-block"><i className="fa fa-download mr-2" aria-hidden="true"></i> Download File</button> */}
                                            {/* <a href={`${metaInfo.filePath}`} target="_blank" download={metaInfo.fileName} className="btn btn-outline-primary btn-sm btn-block"><i className="fa fa-download mr-2" aria-hidden="true"></i> Download File</a> */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </section>
            </div>
        </div>
    )
}