import React, { useEffect, useState } from "react";
import testingFormLabel from "../../../../utils/property-file/testingHarness/formsLabel.json";
import { useLocation } from "react-router-dom";
import { getTestPlanLastSummaryService } from "../../../../services/testService";
import moment from "moment";


export const RunSummaryComponent = ({ getPlanName }) => {

    const location = useLocation()
    const [testPlanId, setTestPlanId] = useState('')
    const [totalQuestions, setTotalQuestions] = useState(0)
    const [thresholdPercentage, setThresholdPercentage] = useState('0%')
    const [questionPassed, setQuestionPassed] = useState(0)
    const [questionFailed, setQuestionFailed] = useState(0)
    const [result, setResult] = useState('')
    const [lastRunDate, setLastRunDate] = useState(moment())
    const [testedBy, setTestedBy] = useState('')
    const [testSummary, setTestSummary] = useState([])

    useEffect(() => {
        const pathName = location.pathname.split('/');
        const planId = pathName[pathName.length - 1];
        setTestPlanId(planId)
    }, [location.pathname])

    useEffect(() => {
        if (testPlanId) {
            getPlanSummary()
        }
    }, [testPlanId])

    const getPlanSummary = async () => {
        const obj = {
            testPlanMasterId: testPlanId
        }
        const resp = await getTestPlanLastSummaryService(obj);
        if (resp && resp.success && resp.data) {
            getPlanName(resp.data.testPlanName)
            setTotalQuestions(resp.data.totalQuestions || 0)
            setQuestionPassed(resp.data.QuestionsPassed || 0)
            setQuestionFailed(resp.data.QuestionsFailed || 0)
            const thresholdPer = resp.data.passThresholdPercentage ? resp.data.passThresholdPercentage.includes('%') ? resp.data.passThresholdPercentage : `${resp.data.passThresholdPercentage}%` : '0%';
            setThresholdPercentage(thresholdPer)
            setResult(resp.data.result)
            if (resp.data.summary && resp.data.summary.length) {
                setLastRunDate(resp.data.summary[0].runStartDate)
                setTestedBy(resp.data.summary[0].runBy)
                setTestSummary(resp.data.summary)
            }

        }
    }

    const getResultTestColor = (str) => {
        if (str.toLowerCase().includes('pass')) {
            return 'text-success'
        }
        else if (str.toLowerCase().includes('fail')) {
            return 'text-danger'
        }
        else {
            return 'text-warning'
        }
    }

    return (
        <>
            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2 shadow-sm">
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td>Last Run Date <br /><b>{moment(lastRunDate).format('MMM DD, YYYY')}</b></td>
                            <td>Total Question <br /><b>{totalQuestions}</b></td>
                            <td>Pass Threshold <br /><b>{thresholdPercentage}</b></td>
                            <td>Question Passed <br /><b>{questionPassed}</b></td>
                            <td>Question Failed <br /><b>{questionFailed}</b></td>
                            <td>Pass Rate(%) <br /><b>{Math.round((parseInt(questionPassed)*100)/parseInt(totalQuestions))}%</b></td>
                            <td>Result <br /><b className={`${getResultTestColor(result)}`}>{result.toUpperCase()}</b></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <h6><span className="text-muted">Last Run User:</span><span className="ml-3">{testedBy}</span></h6>
                            </td>
                            <td colSpan={4}>
                                <h6><span className="text-muted">Last Initiated On:</span><span className="ml-3">{moment(lastRunDate).format('MMM DD, YYYY')}</span></h6>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2 shadow-sm">
                <div className="card-body">
                    <h5 className="card-title">{testingFormLabel.SUMMARY.TITLE}</h5>
                    <p className="card-text">{testingFormLabel.SUMMARY.DESCRIPTION}</p>
                </div>
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                            <th>Actual Output</th>
                            <th>Result Summary</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            testSummary.map((summary, i) => {
                                return (
                                    <tr key={`test-summary-list-${i}`}>
                                        <td>{summary.question}</td>
                                        <td>{summary.expectedAnswer}</td>
                                        <td>{summary.actualOutput}</td>
                                        <td><i className={`fa fa-circle mr-2 ${summary.passStatus ? 'text-success' : 'text-danger'}`} aria-hidden="true"></i>{summary.passStatus ? 'Pass' : 'Fail'}</td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
        </>
    )
}