import React, { useContext, useEffect, useState } from "react";
import HeatmapChart from "./Graph/heatmap";
import heatmapJson from './../data/heatmap.json';
import heatmapCanabisJson from './../data/heatmapCanabis.json';
import { RightPanelComponent } from "./Common/rightPanel";
import c1Img from "./../assets/img/c1.png";
import c2Img from "./../assets/img/c2.png";
import { ColumnChart } from "./Graph/columnChart";
import { TrendChartComponent } from "./Graph/trendChart";
import SearchContext from "../services/context/Search/searchContext";
import BreadcrumbContext from "../services/context/Breadcrumb/breadcrumbContext";
import { AuthContext } from "../services/context/Auth/authContext";
import { PIEChartComponent } from "./Graph/piechart";
import { BoldPieChart } from "./Graph/boldPieChart";
import AIContext from "../services/context/AIAssistance/aiContext";
import errascend_market from "./../assets/img/terrascend-market-around.png";
import { CardDropdownContent } from "./Common/cardDropdown";
import componentOverView from "./../utils/property-file/componentOverview.json"
import { getAppBehaviour } from "../services/authService";
import { appendThemeProperty, capitalizeFirstLetter, getDashboardInfoService, getRandomColor, themeIcons } from "../services/commonService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import listViewLoader from "./../assets/img/loading-animation.gif";
import reviewsIcon from "./../assets/img/AIModel/reviews.jpg";
import storesDefaultIcon from "./../assets/img/stores-default.png";
import { MultiTrendChartComponent } from "./Graph/multiLineChart";
import { MapChartComponent } from "./Graph/mapchart";
import ThemeContext from "../services/context/Theme/themeContext";

export const DashboardComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { userGroups, isCanabisApp } = useContext(AuthContext)
    const { setAIModalType } = useContext(AIContext);
    const { theme } = useContext(ThemeContext)

    useEffect(() => {
        setBreadcrumbMenu([{ slug: '/dashboard', menuName: 'Dashboard', isActive: true, hasSubMenu: false }])
    }, [])

    window.scrollTo(0, 0)

    const [kairosIcon, setKairosIcon] = useState('')

    useEffect(() => {
        getAppThemeImages()
    }, [getAppBehaviour])

    useEffect(() => {
        if (isCanabisApp) {
            getCanabisDashboardInfo()
        }
        else {
            getPEDashboardInfo()
        }
    }, [isCanabisApp])

    const getAppThemeImages = () => {
        const appThemes = getAppBehaviour();
        if (appThemes && appThemes !== "undefined") {
            const themeName = JSON.parse(appThemes).themeName;
            const kairos_icon = themeIcons(`${themeName}_K_Icon`);
            setKairosIcon(kairos_icon)
        }
    }

    const [panelVisibility, setPanelVisibility] = useState(true);
    const [heatMapData, setHeatMapData] = useState([])
    const [incomePeriodData, setIncomePeriodData] = useState([
        {
            "date": "Jun 2022",
            "revenue": 120,
            "netIncome": 110
        },
        {
            "date": "Sep 2022",
            "revenue": 130,
            "netIncome": 80
        },
        {
            "date": "Dec 2022",
            "revenue": 150,
            "netIncome": 100
        }
    ])
    const [incomeSeries, setIncomeSeries] = useState([
        {
            name: "Revenue", val: 'revenue'
        },
        {
            name: 'Net Income', val: 'netIncome'
        }
    ])
    const [mainKPI, setMainKPI] = useState([])
    const [SubKPI, setSubKPI] = useState([])
    const [filter, setFilter] = useState([
        { name: 'All Stores', val: 'all', isActive: true }
    ]);
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });
    const [isLoaded, setIsLoaded] = useState(false)
    const [companyList, setCompanyList] = useState([])

    const applyTheme = () => {
        const appThemes = getAppBehaviour();
        if (theme) {
            appendThemeProperty(theme)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme)
            }
        }
    }

    useEffect(() => {
        setHeatMapData(heatmapJson)
    }, [heatmapJson])

    const heatYAxisData = [
        { weekday: "ALTADIA" },
        { weekday: "GLOVIS" },
        { weekday: "FLENDER" },
        { weekday: "JIO" },
        { weekday: "SEPSA" },
        { weekday: "GCI" }
    ];

    const dashHeat = (item) => {
        // nav(`/dashboard/company`, { state: item.weekday })
    }

    const changePageContentSize = () => {
        setPanelVisibility(!panelVisibility);
    }

    const [pieItem, setPieItem] = useState([
        { sector: "Flowers", size: 45 },
        { sector: "Edibles", size: 30 },
        { sector: "Concentrates", size: 15 },
        { sector: "Accessories", size: 10 },
    ])
    const [topProducts, setTopProducts] = useState([])
    const [customerFeedback, setCustomerFeedback] = useState([])
    const [storeFeedback, setStoreFeedback] = useState([])
    const [competitor, setCompetitor] = useState([])
    const [storeSentiment, setStoreSentiment] = useState([])
    const [allStoreSentiment, setAllStoreSentiment] = useState([])
    const [trendData, setTrendData] = useState([])

    const getPEDashboardInfo = () => {
        const dashboardElement = componentOverView.find(ele => ele.FEATURE === 'DASHBOARD');
        let elementItem;
        if (userGroups.find(grp => grp === "bl.cloud.int.group1") !== -1) {
            elementItem = dashboardElement.items.find(item => item.APPLICABLE_FOR === "bl.cloud.int.group1")
        }
        else {
            elementItem = dashboardElement.items.find(item => item.APPLICABLE_FOR === "bl.cloud.int.group2")
        }
        setMainKPI(elementItem.MAIN_KPI)
        setSubKPI(elementItem.SUB_KPI)
    }

    const getCanabisDashboardInfo = async () => {
        const resp = await getDashboardInfoService();
        console.log("dashboard ====>", resp);
        setIsLoaded(true)
        if (resp && resp.success) {
            const data = resp.data;
            const topProductList = data?.topProducts?.results?.topProducts || [];
            const storeFeedbackAnalysis = data?.storeFeedbackAnalysis?.results?.storeFeedbackAnalysis || [];
            const competitorList = data?.competitorAnalysis?.results?.competitorAnalysis || [];
            let storesList = data?.storeSentimentOverview?.results?.storeSentimentOverview || [];
            if (storesList.length) {
                storesList = storesList.map((s, i) => {                    
                    s.position = i + 1;
                    return s;
                })
            }
            const uniqueLocations = [...new Set(storesList.map(store => store.Location))];
            let filterItems = [{ name: 'All Stores', val: 'all', isActive: true }];
            uniqueLocations.forEach(s=>{
                filterItems.push({ name: s, val: s, isActive: false })
            })
            setFilter(filterItems)
            setTopProducts(topProductList);
            setStoreFeedback(storeFeedbackAnalysis);
            setCompetitor(competitorList);
            setStoreSentiment(storesList)
            setAllStoreSentiment(storesList)

            let mainKpiItems = data?.analysis?.results?.analysis || [];
            mainKpiItems = mainKpiItems.map(kpi => {
                return {
                    key: kpi.keyName,
                    value: kpi.result
                }
            })
            setMainKPI(mainKpiItems)
            let subKpiItems = data?.storeInfo?.results?.storeInfo || [];
            subKpiItems = subKpiItems.map(kpi => {
                return {
                    title: kpi.keyName,
                    data: [
                        { value: Array.isArray(kpi.result) ? kpi.result.join(', ') : kpi.result }
                    ]

                }
            })
            setSubKPI(subKpiItems)
            let trendPrdItems = [];
            const colors = ["#0b4b4c", "#76b7ab", "#ebdbce", "#bdb0a5", "#f3eae3", "#ddc0aa", "#bb987b"]
            topProductList.forEach((prd, i) => {
                trendPrdItems.push({
                    strain: prd.strainName,
                    data: [{
                        date: new Date(2024, 4, 12).getTime(),
                        value: Math.round(Math.random() * 100),
                    }, {
                        date: new Date(2024, 4, 20).getTime(),
                        value: Math.round(Math.random() * 100)
                    }, {
                        date: new Date(2024, 4, 30).getTime(),
                        value: Math.round(Math.random() * 100)
                    }, {
                        date: new Date(2024, 5, 5).getTime(),
                        value: Math.round(Math.random() * 100)
                    }],
                    color: colors[i]
                })
            })
            setTrendData(trendPrdItems)
            applyTheme()
        }
    }

    const getFilteredList = (indx) => {
        let filterItems = [...filter];
        filterItems = filterItems.map(item => {
            item.isActive = false;
            return item;
        })
        filterItems[indx].isActive = true;
        const filterBy = filterItems[indx].val;
        const allItems = [...allStoreSentiment];
        if(filterBy === "all"){
            setStoreSentiment(allItems)
        }
        else {
            const filteredItems = allItems.filter(item => item.Location === filterBy);
            setStoreSentiment(filteredItems)
        }
        applyTheme()
    }

    const canabisCardContent = () => {
        return (
            <>
                <div className="grid-item item2">
                    {
                        topProducts && topProducts.length ? (
                            <div className="card blc_card h-100">
                                <div className="card-body px-0 py-3">
                                    <div className="mb-2 font-weight-600 px-2">
                                        <p className="mb-0">Top Products</p>
                                    </div>
                                    <div>
                                        <table width="100%" cellPadding="0" cellSpacing="0" border="0" className="table table-sm mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="border-top-0">Name</th>
                                                    <th scope="col" className="border-top-0">Mentions</th>
                                                    <th scope="col" className="border-top-0 text-center">Sentiment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    topProducts.map((prod, i) => {
                                                        return (
                                                            <tr key={`canabis-top-products-${i}`}>
                                                                <td className={i === 0 ? 'border-top-0' : ''}>{prod.strainName}</td>
                                                                <td className={i === 0 ? 'border-top-0' : ''}>{prod.mentions}</td>
                                                                <td className={`text-center${i === 0 ? ' border-top-0' : ''}`}>
                                                                    <i className={`fa fa-1x ${getSentimentIcon(prod.sentimentScore)}`}></i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }

                </div>
                <div className="grid-item item2">
                    <div className="card blc_card h-100">
                        {/* <div className="card-header d-flex justify-content-between">
                            <div className="d-flex align-self-center">
                                <h6 className="mb-0">Sales Trend</h6>
                            </div>
                            <CardDropdownContent cardId="salestrend" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
                        </div> */}
                        <div className="card-body pl-2 pr-0 pt-3 pb-0">
                            <div className="mb-3 font-weight-600 px-2">
                                <p className="mb-0">Sales Trend</p>
                            </div>
                            {
                                trendData && trendData.length ? <MultiTrendChartComponent chartInfo={trendData} id="sourceprofilevaluationdash" height="13rem" /> : null
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="grid-item item2">
                    {
                        storeFeedback && storeFeedback.length ? (
                            <div className="card blc_card h-100">
                                <div className="card-body px-0 py-3">
                                    <div className="mb-2 font-weight-600 px-2">
                                        <p>Store Feedback Analysis</p>
                                    </div>
                                    <div>
                                        <table width="100%" cellPadding="0" cellSpacing="0" border="0" className="table table-sm mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="border-top-0">Feedback</th>
                                                    <th scope="col" className="border-top-0">Sentiment</th>
                                                    <th scope="col" className="border-top-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    storeFeedback.map((feedback, i) => {
                                                        const infoId = `storeFeedbackAnalysis${i}`
                                                        return (
                                                            <tr key={`canabis-customer-feedback-${i}`}>
                                                                <td className={`text-nowrap${i === 0 ? ' border-top-0' : ''}`}>{feedback.concern}</td>
                                                                <td className={`${i === 0 ? "border-top-0" : ""}`}>
                                                                    <i className={`fa fa-1x ${getSentimentIcon(feedback.sentiment)}`}></i>
                                                                </td>
                                                                <td className={`${i === 0 ? "border-top-0" : ""}`}>
                                                                    {storeAnalysisDropdownContent(infoId, feedback)}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }

                </div>

                <div className="grid-item item2">
                    {
                        competitor && competitor.length ? (
                            <div className="card blc_card h-100">
                                <div className="card-body px-0 py-3">
                                    <div className="mb-2 font-weight-600 px-2">
                                        <p>Competitor Analysis</p>
                                    </div>
                                    <div>
                                        <table width="100%" cellPadding="0" cellSpacing="0" border="0" className="table table-sm mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="border-top-0">Name</th>
                                                    <th scope="col" className="border-top-0">Sentiment</th>
                                                    <th scope="col" className="border-top-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    competitor.map((comp, i) => {
                                                        const infoId = `competitorAnalysis${i}`
                                                        return (
                                                            <tr key={`canabis-competitor-${i}`}>
                                                                <td className={i === 0 ? "border-top-0" : ""}>{comp.competitor}</td>
                                                                <td className={`${i === 0 ? ' border-top-0' : ''}`}>
                                                                    <i className={`fa fa-1x ${getSentimentIcon(comp.sentiment)}`}></i>
                                                                </td>
                                                                <td className={`${i === 0 ? "border-top-0" : ""}`}>
                                                                    {competitorAnalysisDropdownContent(infoId, comp)}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }

                </div>

            </>
        )
    }

    const getSentimentIcon = (score) => {
        if (score.toLowerCase() === "positive") {
            return "fa-smile-o bl-positive-sentiment"
        }
        else if (score.toLowerCase() === "negative") {
            return "fa-frown-o bl-negative-sentiment"
        }
        else return "fa-meh-o bl-neutral-sentiment"
    }

    const defaultCardContent = () => {
        return (
            <>
                <div className="grid-item item2">
                    <div className="card blc_card h-100">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                                <TrendChartComponent id="sourceprofilevaluationdash" height="9rem"></TrendChartComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item3">
                    <div className="card blc_card h-100">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Valuation Performance</p>
                                {
                                    incomePeriodData.length ? <ColumnChart id="incomestatementfinancedash" data={incomePeriodData} categoryField={'date'} seriesItem={incomeSeries} height="9rem"></ColumnChart> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item4">
                    <div className="card blc_card h-100">
                        <div className="card-body p-3">
                            <div className="mb-2 font-weight-600">
                                <p>Alerts that need your attention</p>
                            </div>
                            <div>
                                <table width="100%" cellPadding="0" cellSpacing="0" border="0" className="table mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="border-top-0">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center bg-warning text-white">
                                                    3
                                                </div>
                                            </td>
                                            <td className="border-top-0">New Monthly Financial datasets released</td>
                                            <td className="border-top-0"><i className="fa fa-angle-right" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td className="border-top-0">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center bg-danger text-white">
                                                    5
                                                </div>
                                            </td>
                                            <td>Data Exception(s) detected that has impacted the gener...</td>
                                            <td><i className="fa fa-angle-right" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td className="border-top-0">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center bg-warning text-white">
                                                    4
                                                </div>
                                            </td>
                                            <td>Company Financial(s) Overdue</td>
                                            <td><i className="fa fa-angle-right" aria-hidden="true"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const storeAnalysisDropdownContent = (id, info) => {
        return (
            <div className="">
                <button className="icon text-secondary btn-navigate" id={id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-info-circle" aria-hidden="true"></i></button>
                <div className="dropdown-menu px-2" aria-labelledby={id}>
                    <div className="row">
                        <div className="col-3 align-content-center">
                            <p className="text-muted mb-0 text-nowrap"><small>Stores</small></p>
                        </div>
                        <div className="col align-content-center">
                            <h6 className="mb-0 small text-black">{info.storeNames.join(', ')}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <p className="text-muted mb-0 text-nowrap"><small>Mentions</small></p>
                        </div>
                        <div className="col align-content-center">
                            <h6 className="mb-0 small text-black">{info.mentions}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <p className="text-muted mb-0 text-nowrap"><small>Insights</small></p>
                        </div>
                        <div className="col align-content-center">
                            <h6 className="mb-0 small text-black">{info.insight}</h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const competitorAnalysisDropdownContent = (id, info) => {
        return (
            <div className="">
                <button className="icon text-secondary btn-navigate" id={id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-info-circle" aria-hidden="true"></i></button>
                <div className="dropdown-menu px-2" aria-labelledby={id}>
                    <div className="row">
                        <div className="col-3 align-content-center">
                            <p className="text-muted mb-0 text-nowrap"><small>Competitor</small></p>
                        </div>
                        <div className="col align-content-center">
                            <h6 className="mb-0 small text-black">{info.competitor}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <p className="text-muted mb-0 text-nowrap"><small>Rating</small></p>
                        </div>
                        <div className="col align-content-center">
                            <h6 className="mb-0 small text-black">{info.storeAverageRating}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <p className="text-muted mb-0 text-nowrap"><small>Insights</small></p>
                        </div>
                        <div className="col align-content-center">
                            <h6 className="mb-0 small text-black">{info.insight}</h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const cardDropdownContent = (id) => {
        return (
            <div className="">
                <button className="icon text-secondary btn-navigate" id={id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></button>
                <div className="dropdown-menu" aria-labelledby={id}>
                    <button className="dropdown-item" >
                        <i className="fa fa-user fa-sm mr-2 text-gray-400"></i>
                        View
                    </button>
                    <button className="dropdown-item" >
                        <i className="fa fa-edit fa-sm mr-2 text-gray-400"></i>
                        Update
                    </button>
                    <button className="dropdown-item" >
                        <i className="fa fa-list fa-sm mr-2 text-gray-400"></i>
                        Activity Log
                    </button>
                    <button className="dropdown-item text-danger pl-3" onClick={() => setAIModalType('REDDIT')} data-toggle="modal" data-target="#aimodalbox">
                        <img className="img-fluid mr-2" src={kairosIcon} />
                        Ask a question
                    </button>
                </div>
            </div>
        )
    }

    const cardGridContainer = () => {
        return (
            <div className="grid-container">
                <div className="grid-item item1">
                    <div className="card blc_card h-100">
                        {
                            !isCanabisApp ? (
                                <div className="card-header d-flex justify-content-between">
                                    <div className="d-flex align-self-center">
                                        <h6 className="mb-0">{'Data Collection Overview'}</h6>
                                    </div>
                                    <CardDropdownContent cardId="storefootfall" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
                                    {/* {cardDropdownContent('storefootfall')} */}
                                </div>
                            ) : null
                        }

                        {
                            isCanabisApp ?
                                <div className="card-body">
                                    <div className="mb-3 font-weight-600 px-2">
                                        <p className="mb-0">Product Demand by Region</p>
                                    </div>
                                    <MapChartComponent id="canabisusmap" height="30rem" />
                                </div> :
                                <div className="card-body">
                                    <HeatmapChart data={heatMapData} yaxisData={heatYAxisData} yAxisVisiblity={true} height={'350px'} dashHeat={(item) => dashHeat(item)}></HeatmapChart>
                                </div>
                        }
                    </div>
                </div>
                {
                    isCanabisApp ? canabisCardContent() : defaultCardContent()
                }
                <div className="grid-item item5 d-none">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item6 d-none">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                            </div>
                            {/* <BarChart height="15rem"></BarChart> */}
                        </div>
                    </div>
                </div>
                <div className="grid-item item7 d-none">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                            </div>
                            {/* <BarChart height="15rem"></BarChart> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function concatenateWithCommas(street, locality, country) {
        let address = [];
        if (street) {
            address.push(street)
        }
        if (locality) {
            address.push(locality)
        }
        if (country) {
            address.push(country)
        }
        const resultString = address.join(', ');

        return resultString;
    }

    const handleImageError = (e) => {
        e.target.src = storesDefaultIcon;
        e.target.alt = 'Alternative Image';
    }

    const getSentimentColor = (sentiment) => {
        if (sentiment.toLowerCase() === "positive") {
            return "#4CAF50"
        }
        else if (sentiment.toLowerCase() === "negative") {
            return "#F44336"
        }
        else return "#FFC107"
    }

    const arrangeColumnValue = (item, column) => {
        const data = item;
        if (column === 'ICON') {
            const icon = data.img ? data.img : '';
            return (
                <div className="d-flex align-items-center p-0">
                    <img src={icon} onError={(e) => handleImageError(e)} alt="logo" className="img-fluid bl-report-thumbnail rounded" />
                </div>
            )
        }
        else if (column === 'STORE_NAME') {
            const address = data.address ? data.address.replace(/undefined,?/g, '').trim() : null;
            return (
                <>
                    <h6 className="text-nowrap">{data.storeName}</h6>
                    <div className="d-flex align-self-center mr-2 mb-1">
                        {
                            address || concatenateWithCommas(data.street, data.locality, data.country) ? <i className="fa fa-location-arrow align-self-center mr-2" aria-hidden="true"></i> : null
                        }

                        {
                            address ? <p className="mb-0">{address}</p> : <p className="mb-0">{concatenateWithCommas(data.street, data.locality, data.country)}</p>
                        }

                    </div>
                </>
            )
        }
        else if (column === 'REVIEWS') {
            const reviews = data.reviews.slice(0, 3);
            return (
                <div className="card bg-light border-0">
                    <div className="card-body p-2">
                        <div className="row">
                            <div className="col-6">
                                <p className="bl-font-size-12px mb-1 font-small text-muted">Top 3 reviews:</p>
                                {
                                    reviews.map((ex, i) => {
                                        return (
                                            <div key={`executives-${i}`} className="d-flex mb-1 font-small">
                                                <img className="align-self-center mr-2" src={reviewsIcon} alt="Generic placeholder image" height="12px" />
                                                <p className="mb-0 bl-truncate-line-1">{ex.comment}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-6 mt-3">
                                <div className="row mb-1 font-small">
                                    <div className="col">
                                        <i className={`fa fa-1x ${getSentimentIcon('positive')} mr-2`}></i><span className="text-muted">Positive Words:</span>
                                    </div>
                                    <div className="col">
                                        <p className="mb-1 bl-truncate-line-1 text-capitalize" title={data.positiveWords.join(', ')}>{data.positiveWords.join(', ')}</p>
                                    </div>
                                </div>
                                <div className="row mb-1 font-small">
                                    <div className="col">
                                        <i className={`fa fa-1x ${getSentimentIcon('negative')} mr-2`}></i><span className="text-muted">Negative Words:</span>
                                    </div>
                                    <div className="col">
                                        <p className="bl-font-size-12px mb-1 bl-desc-truncate-line-1 text-capitalize" title={data.negativeWords.join(', ')}>{data.negativeWords.join(', ')}</p>
                                    </div>
                                </div>
                                <div className="row font-small">
                                    <div className="col">
                                        <i className={`fa fa-1x ${getSentimentIcon('neutral')} mr-2`}></i><span className="text-muted">Neutral Words:</span>
                                    </div>
                                    <div className="col">
                                        <p className="bl-font-size-12px mb-0 bl-desc-truncate-line-1 text-capitalize" title={data.neutralWords.join(', ')}>{data.neutralWords.join(', ')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        // else if (column === 'SEED') {
        //     return (
        //         <>
        //             <div className="row">
        //                 <div className="col-6 p-0">
        //                     <p className="mb-0 text-gray">
        //                         <i className="fa fa-fw fa-money mr-1" aria-hidden="true"></i>
        //                         <span>Seed Round: </span>
        //                     </p>
        //                 </div>
        //                 <div className="col-6 p-0">
        //                     <p className="mb-0">$2M(2018)</p>
        //                 </div>
        //             </div>

        //             <div className="row">
        //                 <div className="col-6 p-0">
        //                     <p className="mb-0 text-gray">
        //                         <i className="fa fa-fw fa-money mr-1" aria-hidden="true"></i>
        //                         <span>Series A: </span>
        //                     </p>
        //                 </div>
        //                 <div className="col-6 p-0">
        //                     <p className="mb-0">$10M(2019)</p>
        //                 </div>
        //             </div>
        //             <div className="row">
        //                 <div className="col-6 p-0">
        //                     <p className="mb-0 text-gray">
        //                         <i className="fa fa-fw fa-money mr-1" aria-hidden="true"></i>
        //                         <span>Series B: </span>
        //                     </p>
        //                 </div>
        //                 <div className="col-6 p-0">
        //                     <p className="mb-0">$12M(2022)</p>
        //                 </div>
        //             </div>
        //         </>
        //     )
        // }
        else if (column === 'CHART') {
            const pieItem = { valueField: 'score', categoryField: 'type' };
            const time = moment().unix();
            const min = 1;
            const max = 100;
            const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min + parseInt(time);
            const chartItem = data.overAllSentiment.map(d => {
                return {
                    type: capitalizeFirstLetter(d.sentiment),
                    score: d.count,
                    color: getSentimentColor(d.sentiment)
                }
            })
            return (
                <div className="text-center position-relative">
                    <PIEChartComponent id={`storepiechart${data.position}${randomInteger}`} height="60px" data={chartItem} categoryField={pieItem} hideCenterText={false} innerRadius={15} />
                    <p className="mb-0 small">Overall Sentiment</p>
                </div>
            )
        }
        else {
            return (
                <span></span>
            )
        }

    }

    const storeListView = () => {
        return (
            <section className="mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    <h6 className="mb-3">{'Store Sentiment Analysis'}</h6>
                                    <div className="d-flex pb-2">
                                        {
                                            filter.map((f, i) => {
                                                return (
                                                    <span key={`risk-cate-filter-${i + 1}`} onClick={()=> getFilteredList(i)} className={`badge bl-filter-chip cp ${f.isActive ? 'active bg-dark text-white' : ''}`}>{f.name}</span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    !isLoaded ? (
                                        <div className="col text-center">
                                            <img className="bl-list-loader" src={listViewLoader} />
                                        </div>
                                    ) : (
                                        <DataTable value={storeSentiment} rowClassName={"bl-source-overview-list"} className="cp" selectionMode="single">
                                            <Column className="p-0" style={{ width: '5%' }} body={(e) => arrangeColumnValue(e, 'ICON')}></Column>
                                            <Column field="storeName" body={(e) => arrangeColumnValue(e, 'STORE_NAME')}></Column>
                                            <Column field="Location"></Column>
                                            <Column body={(e) => arrangeColumnValue(e, 'REVIEWS')}></Column>
                                            {/* <Column body={(e) => arrangeColumnValue(e, 'SEED')} style={{ width: '17%' }}></Column> */}
                                            <Column body={(e) => arrangeColumnValue(e, 'CHART')} style={{ width: '15%' }}></Column>
                                        </DataTable>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const defaultListView = () => {
        return (
            <section>
                <div className="col-12 my-3 text-black p-0">
                    <h5 className="h5 mb-0 text-black font-weight-600">My Tasks</h5>
                </div>
                <div className="col-xl-12 col-md-12 p-0 mb-2">
                    <div className="form-row blc_button">
                        <div className="form-group col-md-2">
                            <select id="inputState" className="form-control blc_button text-black" defaultValue={''}>
                                <option>Category</option>
                                <option>...</option>
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <select id="inputState" className="form-control blc_button text-black" defaultValue={''}>
                                <option>All...</option>
                                <option>...</option>
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <select id="inputState" className="form-control blc_button text-black" defaultValue={''}>
                                <option>Submitted</option>
                                <option>...</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-md-12 p-0 mb-4 table-responsive font-small">
                    <table className="table" id="dataTable" width="100%" cellSpacing="0">
                        <thead>
                            <tr>
                                <th><input type={'checkbox'} /></th>
                                <th>Company</th>
                                <th>Latest Comments</th>
                                <th></th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type={'checkbox'} /></td>
                                <td className="dash-tab-comp">
                                    <img src={c1Img} alt="c1" />
                                    <p>XYZ Technologies Inc.</p>
                                    <p className="small">Request ID: VAL-005</p>
                                </td>
                                <td>The Valuation Team has submitted a valuation request</td>
                                <td className="text-gray">Today</td>
                                <td><span className="badge badge-pill badge-secondary">Pending Approval</span></td>
                            </tr>
                            <tr>
                                <td><input type={'checkbox'} /></td>
                                <td className="dash-tab-comp">
                                    <img src={c2Img} alt="c2" />
                                    <p>DEF Energy Corp</p>
                                    <p className="small">Request ID: VAL-008</p>
                                </td>
                                <td>Escalated to the CEO for final approval.</td>
                                <td className="text-gray">2 days ago</td>
                                <td><span className="badge badge-pill badge-secondary">IMMEDIATE</span></td>
                            </tr>
                            <tr>
                                <td><input type={'checkbox'} /></td>
                                <td className="dash-tab-comp">
                                    <img src={c1Img} alt="c1" />
                                    <p>XYZ Technologies Inc.</p>
                                    <p className="small">Request ID: VAL-009</p>
                                </td>
                                <td>Escalated to the CEO for final approval.</td>
                                <td className="text-gray">2 days ago</td>
                                <td><span className="badge badge-pill badge-secondary">Pending Approval</span></td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </section>
        )
    }

    return (
        <div id="content">
            <div className="row">
                <div className={panelVisibility ? 'col-md-9 pr-0' : 'col-12'}>
                    <div className="bg-dark h-13rem border-right dash-title">
                        <div className="container-fluid">
                            <div className="row pt-3">
                                <div className="col-12 dash-header-info d-flex">
                                    {
                                        mainKPI.length && mainKPI.map((kpi, i) => {
                                            return (
                                                <div key={`main-kpi-${i}`} className={`${i === 0 ? 'pl-0' : ''} ${mainKPI.length === i + 1 ? 'border-0' : ''}`}>
                                                    <p>{kpi.key}</p>
                                                    <h4>{kpi.value}</h4>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                {/* <div className="col-2 mob-hide">
                                    <div className="text-right">
                                        <i className="fa fa-sliders mr-3" aria-hidden="true"></i>
                                        <i className="fa fa-window-maximize transform-270 cp" aria-hidden="true" onClick={() => changePageContentSize()}></i>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">


                        <div className="card-visibility font-weight-600">
                            <div className="row bl-dash-card">
                                {
                                    SubKPI.length && SubKPI.map((kpi, i) => {
                                        return (
                                            <div key={`sub-kpi-${i}`} className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                                <div className="card mb-4 blc_card"><div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <p className="mb-0">{kpi.title}</p>
                                                    </div>
                                                    {
                                                        kpi.data.map((item, j) => {
                                                            return (
                                                                <h5 key={`sub-kpi-item-${i}-${j}`} className="fw-bold text-capitalize">{item.key ? `${item.key}: ` : ''}{item.value}</h5>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                        <section className="mb-4">
                            {cardGridContainer()}
                        </section>
                        {
                            isCanabisApp ? storeListView() : defaultListView()
                        }


                    </div>
                </div>
                {
                    panelVisibility ? <div className="col-xl-3 col-md-3 col-12 bg-gray">
                        <RightPanelComponent></RightPanelComponent>
                    </div> : null
                }
            </div>
        </div>
    )
}