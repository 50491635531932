import React, { useContext, useEffect, useState } from "react";
import help_dashIcon from "./../assets/img/helper/help_dashIcon.svg";
import help_adhIcon from "./../assets/img/helper/help_adhIcon.svg";
import help_KairosIcon from "./../assets/img/helper/help_KairosIcon.svg";
import helpScreen2 from "./../assets/img/helper/HelpScreen2.svg";
import helpScreen3 from "./../assets/img/helper/HelpScreen3.svg";
import helpScreen4 from "./../assets/img/helper/HelpScreen4.svg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../services/context/Auth/authContext";
import tutorial from "./../utils/property-file/tutorial.json";
import blLogo from "./../assets/icons/BL/Small_Logo.png"
import { getAppBehaviour } from "../services/authService";
import { appendThemeProperty, themeIcons } from "../services/commonService";
import ThemeContext from "../services/context/Theme/themeContext";


export const TutorialComponent = () => {

  const { loggedUserName } = useContext(AuthContext)
  const { theme } = useContext(ThemeContext)
  const nav = useNavigate();

  const [tutorialInfo, setTutorialInfo] = useState({
    "WELCOME": "Welcome to",
    "BL_BUSINESS_CLOUD": "Brownloop Business Cloud",
    "APPLICABLE_FOR": "for Private Equity",
    "TITLE1": "With your AI companion",
    "APP_NAME": "Kairos",
    "TITLE2": "",
    "TITLE3": "",
    "TUTORIAL_INFO": {
      "TITLE": "",
      "ITEMS": [
        {
          "TYPE": "",
          "TITLE": "",
          "DESC": ""
        }
      ]
    }
  })
  const [orgIcon, setOrgIcon] = useState('')
  const [slideCount, setSlideCount] = useState(0)

  useEffect(() => {
    setSlideCount(0)
    applyTheme()
    document.getElementsByTagName('body')[0].classList.add('bg-dark');
    window.$('#myCarousel').on('slid.bs.carousel', function () {
      const activeIndex = window.$(this).find('.carousel-inner .active').index();
      changeSlide(activeIndex)
    });
    return () => {
      const bodyElement = document.getElementById('approot');
      bodyElement.removeAttribute('style');
      document.getElementsByTagName('body')[0].classList.remove('bg-dark')
    }
  }, [])

  useEffect(() => {
    const appThemes = getAppBehaviour();
    let themeName = "BL_THEME";
    if (appThemes && appThemes !== "undefined") {
      themeName = JSON.parse(appThemes).themeName;
    }
    const appSmallLogo = themeIcons(`${themeName}_Small_Logo`);
    setOrgIcon(appSmallLogo)
    const tutorialInfo = tutorial.find(t => themeName === "VISTA_THEME" ? themeName === t.THEME : !t.THEME)
    const info = addImageToFile(tutorialInfo);
    setTutorialInfo(info)
  }, [tutorial])

  const applyTheme = () => {
    const appThemes = getAppBehaviour();
    if (theme) {
        appendThemeProperty(theme)
    }
    else if (appThemes && appThemes !== "undefined") {
        const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
        if (appTheme) {
            appendThemeProperty(appTheme)
        }
    }
}

  const addImageToFile = (jsonData) => {
    jsonData.TUTORIAL_INFO.ITEMS.forEach(item => {
      switch (item.TYPE) {
        case "DASHBOARD":
          item.IMAGE = help_dashIcon
          break;
        case "ADH":
          item.IMAGE = help_adhIcon
          break;
        case "KAIROS":
          item.IMAGE = help_KairosIcon
          break;
        default:
          break;
      }
    });
    return jsonData
  }

  const changeSlide = (count) => {
    setTimeout(() => {
      setSlideCount(count)
    }, 10)
  }

  return (
    <div className="container-fluid pl-0">
      <div className="row">
        <nav className="col-md-3 d-none d-md-block bg-light sidebar bl-z-index-1">
          <div className="sidebar-sticky p-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3">
              <img src={blLogo} height="50px" alt="bl logo" />
              <img src={orgIcon} height="50px" alt="org logo" />
            </div>
            <h5 className="text-dark">Hi <b>{loggedUserName}</b></h5>
            <p dangerouslySetInnerHTML={{ __html: tutorialInfo.WELCOME }}></p>
            {
              slideCount < 2 ? <button className="btn btn-danger mb-1" href="#myCarousel" role="button" data-slide="next">View Next</button>
              : <button className="btn btn-danger mb-1" onClick={() => nav('/home')}>Get Started Now »</button>
            }            
            <div className="mt-3 bl-helper-content">
              <h6 className="border-gray pb-2 mb-0">{tutorialInfo.TUTORIAL_INFO.TITLE}</h6>
              {
                tutorialInfo.TUTORIAL_INFO.ITEMS.map((item, i) => {
                  return (
                    <div key={`tutorial-info-${i}`} className="media text-muted pt-3">
                      <img className="mr-2 rounded align-self-center1" src={item.IMAGE} data-holder-rendered="true" alt="dashboard icon" />
                      <p className="media-body pb-3 mb-0 lh-125">
                        <strong className="d-block text-gray-dark">{item.TITLE}</strong>
                        {item.DESC}
                      </p>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </nav>

        <main role="main" className="col-md-9 ml-sm-auto col-lg-9">
          <div id="myCarousel" className="carousel slide" data-ride="carousel" data-interval="false">
            <ol className="carousel-indicators">
              <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
              <li data-target="#myCarousel" data-slide-to="1"></li>
              <li data-target="#myCarousel" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner bl-overflow-unst">
              <div className="carousel-item active" data-slide-number="0">
                <div className="container px-1">
                  <div className="kai_carousel-caption">
                    <img className="first-slide img-fluid bl-w-80" src={helpScreen2} alt="First Slide" />
                  </div>
                </div>
              </div>
              <div className="carousel-item" data-slide-number="1">
                <div className="container px-1">
                  <div className="kai_carousel-caption">
                    <img className="second-slide img-fluid bl-w-80" src={helpScreen3} alt="Second Slide" />
                  </div>
                </div>
              </div>
              <div className="carousel-item" data-slide-number="2">
                <div className="container px-1">
                  <div className="kai_carousel-caption">
                    <img className="second-slide img-fluid bl-w-80" src={helpScreen4} alt="Second Slide" />
                  </div>
                </div>
              </div>
            </div>
            <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>

        </main>
      </div>
    </div>
  )
}