import React, { useContext, useEffect, useState } from "react";
import animateGif from "./../assets/img/animate.gif";
import { useNavigate } from "react-router-dom";
import { getAppBehaviour, getRoleBasedAppService } from "../services/authService";
import { AuthContext } from "../services/context/Auth/authContext";
import applicationApps from "./../data/Menu/apps.json";
import ThemeContext from "../services/context/Theme/themeContext";
import { appendThemeProperty, themeIcons } from "../services/commonService";
import vistaIcon from "./../assets/icons/vista/Vista_logo_sm.png";

export const HomePageComponent = () => {

  const { setAppliacbleApps } = useContext(AuthContext)
  const { theme } = useContext(ThemeContext)
  const nav = useNavigate()

  const [companySmallLogo, setCompanySmallLogo] = useState(null)

  useEffect(() => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    if (parts.includes('vistaequitypartners')) {
      const imageElement = document.getElementById('homeLoadingIcon');
      imageElement.src = vistaIcon;
    }
    getRoleBasedApp()
  }, [])

  const getRoleBasedApp = async () => {
    const resp = await getRoleBasedAppService();
    if (resp && resp.success && resp.data && resp.data.length) {
      const apps = resp.data.sort((a, b) => a.position - b.position);
      const appliacbleApps = applicationApps.filter(app => apps.some(a => a.appName === app.appName));
      nav(appliacbleApps[0].path)
      const appThemes = getAppBehaviour();
      const themeName = JSON.parse(appThemes).themeName;
      const appSmallLogo = themeIcons(`${themeName}_Small_Logo`);
      setCompanySmallLogo(appSmallLogo)
      if (theme) {
        appendThemeProperty(theme)
      }
      else if (appThemes && appThemes !== "undefined") {
        const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
        appendThemeProperty(appTheme)
      }

    }
    else {
      nav('/unauthorize')
    }
  }

  return (
    <div id="homepagecontent">
      <div className="container">
        <div className="row">
          <div className="col text-center vh-100 align-content-center">
            <div className="media">
              <img id="homeLoadingIcon" className="align-self-center mr-3 shadow rounded-circle" height="150px" src={animateGif} alt="Generic placeholder image" />
              <div className="media-body vh-100 align-content-center">
                <h1 className="h1 text-gray-900">Please wait while we prepare the system for you</h1>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}