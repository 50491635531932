import React, { useEffect, useState } from "react";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5geodata_usaNewYorkLow from "@amcharts/amcharts5-geodata/usaLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from '@amcharts/amcharts5';
import * as am5map from "@amcharts/amcharts5/map";

export const MapChartComponent = ({ id = 'mapchart', height = '100px' }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }

        let data = [
            {
                id: "US",
                name: "United States",
                value: 100
            }, {
                id: "GB",
                name: "United Kingdom",
                value: 100
            }, {
                id: "CN",
                name: "China",
                value: 100
            }, {
                id: "IN",
                name: "India",
                value: 100
            }, {
                id: "AU",
                name: "Australia",
                value: 100
            }, {
                id: "CA",
                name: "Canada",
                value: 100
            }, {
                id: "BR",
                name: "Brasil",
                value: 100
            }, {
                id: "ZA",
                name: "South Africa",
                value: 100
            }
        ];

        var cities = [
            { title: "Juciee J", latitude: 40.7127837, longitude: -73.935242, count: 100 },
            { title: "Trilla", latitude: 42.88644679999999, longitude: -78.8783689, count: 20 },
            { title: "Slurricane IX", latitude: 43.16103, longitude: -77.6109219, count: 55 },
            { title: "Black Cherry Runtz", latitude: 41.03398620000001, longitude: -73.7629097, count: 62 },
            // { title: "Philadelphia", latitude: 39.9525839, longitude: -75.1652215, count: 66 },
            // { title: "Phoenix", latitude: 33.4483771, longitude: -112.0740373, count: 51 },
            // { title: "Pristina", latitude: 42.666667, longitude: 21.166667 },
            
          ];
          

        let root = am5.Root.new(id);
        root.setThemes([am5themes_Animated.new(root)]);
        root._logo.dispose();
        let chart = root.container.children.push(am5map.MapChart.new(root, {
            // rotationX: -160,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0
        }));

        let polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                // geoJSON: am5geodata_worldLow,
                geoJSON: am5geodata_usaNewYorkLow,
                // exclude: ["US-NY"],
                // include: ["US"]
                include: ["US-NY"]
            })
        );

        polygonSeries.mapPolygons.template.setAll({
            fill:am5.color("#76b7ab")
          });

        //   let pointSeries = chart.series.push(am5map.ClusteredPointSeries.new(root, {}));

        //   pointSeries.set("clusteredBullet", function(root) {
        //     let container = am5.Container.new(root, {
        //       cursorOverStyle:"pointer"
        //     });
          
        //     let circle1 = container.children.push(am5.Circle.new(root, {
        //       radius: 8,
        //       tooltipY: 0,
        //       fill: am5.color(0xff8c00)
        //     }));
          
        //     let circle2 = container.children.push(am5.Circle.new(root, {
        //       radius: 12,
        //       fillOpacity: 0.3,
        //       tooltipY: 0,
        //       fill: am5.color(0xff8c00)
        //     }));
          
        //     let circle3 = container.children.push(am5.Circle.new(root, {
        //       radius: 16,
        //       fillOpacity: 0.3,
        //       tooltipY: 0,
        //       fill: am5.color(0xff8c00)
        //     }));
          
        //     let label = container.children.push(am5.Label.new(root, {
        //       centerX: am5.p50,
        //       centerY: am5.p50,
        //       fill: am5.color(0xffffff),
        //       populateText: true,
        //       fontSize: "8",
        //       text: "{value}"
        //     }));
          
        //     container.events.on("click", function(e) {
        //       pointSeries.zoomToCluster(e.target.dataItem);
        //     });
          
        //     return am5.Bullet.new(root, {
        //       sprite: container
        //     });
        //   });
          
        //   // Create regular bullets
        //   pointSeries.bullets.push(function() {
        //     let circle = am5.Circle.new(root, {
        //       radius: 6,
        //       tooltipY: 0,
        //       fill: am5.color(0xff8c00),
        //       tooltipText: "{title}"
        //     });
          
        //     return am5.Bullet.new(root, {
        //       sprite: circle
        //     });
        //   });

        let pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
        pointSeries.bullets.push(function() {
            let container = am5.Container.new(root, {});
            var circle = container.children.push(am5.Circle.new(root, {
                radius: 15,
                tooltipText: "{title}: {count}",
                fill: am5.color("#0b4b4c"),
                stroke: am5.color("#ebdbce"),
                strokeWidth: 2
            }));

            circle.states.create("hover", {
                scale: 1.5
            });

            let label = container.children.push(am5.Label.new(root, {
                text: "{count}",
                centerX: am5.p50,
                centerY: am5.p50,
                populateText: true,
                fill: am5.color("#ebdbce")
            }));

            return am5.Bullet.new(root, {
                sprite: container
            });
        });
          
          for (var i = 0; i < cities.length; i++) {
            let city = cities[i];
            addCity(city.longitude, city.latitude, city.title, city.count);
          }
          
          function addCity(longitude, latitude, title, count) {
            pointSeries.data.push({
              geometry: { type: "Point", coordinates: [longitude, latitude] },
              title: title,
              count: count
            });
          }

        // let bubbleSeries = chart.series.push(
        //     am5map.MapPointSeries.new(root, {
        //         valueField: "value",
        //         calculateAggregates: true,
        //         polygonIdField: "id"
        //     })
        // );

        // let circleTemplate = am5.Template.new({

        // });

        // bubbleSeries.bullets.push(function (root, series, dataItem) {
        //     let container = am5.Container.new(root, {});

        //     let circle = container.children.push(
        //         am5.Circle.new(root, {
        //             radius: 0,
        //             fillOpacity: 0.7,
        //             fill: am5.color(0xff0000),
        //             cursorOverStyle: "pointer",
        //             tooltipText: `{name}: [bold]{value}[/]`
        //         }, circleTemplate)
        //     );

        //     let countryLabel = container.children.push(
        //         am5.Label.new(root, {
        //             text: "{name}",
        //             paddingLeft: 5,
        //             populateText: true,
        //             fontWeight: "bold",
        //             fontSize: 10,
        //             centerY: am5.p50
        //         })
        //     );

        //     circle.on("radius", function (radius) {
        //         countryLabel.set("x", radius);
        //     })

        //     return am5.Bullet.new(root, {
        //         sprite: container,
        //         dynamic: true
        //     });
        // });

        // bubbleSeries.bullets.push(function (root, series, dataItem) {
        //     return am5.Bullet.new(root, {
        //         sprite: am5.Label.new(root, {
        //             text: "{value.formatNumber('#.')}",
        //             fill: am5.color(0xffffff),
        //             populateText: true,
        //             centerX: am5.p50,
        //             centerY: am5.p50,
        //             textAlign: "center",
        //             fontSize: 10
        //         }),
        //         dynamic: true
        //     });
        // });



        // // minValue and maxValue must be set for the animations to work
        // bubbleSeries.set("heatRules", [
        //     {
        //         target: circleTemplate,
        //         dataField: "value",
        //         min: 10,
        //         max: 20,
        //         minValue: 0,
        //         maxValue: 100,
        //         key: "radius"
        //     }
        // ]);

        // bubbleSeries.data.setAll(data);

        // updateData();

        // function updateData() {
        //     for (var i = 0; i < bubbleSeries.dataItems.length; i++) {
        //         bubbleSeries.data.setIndex(i, { value: Math.round(Math.random() * 100), id: data[i].id, name: data[i].name })
        //     }
        // }

        setChartRoot(root);

        return () => {
            if (chartRoot) {
                chartRoot.dispose();
            }
        }

    }, [])

    return <div id={id} style={{ width: '100%', height: height }}></div>;
}