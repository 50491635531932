import React, { useContext, useEffect, useState } from "react";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation, useNavigate } from "react-router-dom";
import homeLabel from "../../utils/property-file/componentOverview.json";
import sblogo from "./../../assets/img/sb_hero.jpg";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { generateSBEventId } from "../../services/speakerBriefService";
import listViewLoader from "./../../assets/img/loading-animation.gif";
import moment from "moment";
import { SpeakerBriefContext } from "../../services/context/speakerBriefContext";
import { getAppBehaviour } from "../../services/authService";
import { appendThemeProperty } from "../../services/commonService";
import ThemeContext from "../../services/context/Theme/themeContext";


export const SpeakerBriefHomeComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { eventList, viewType, setViewType, isLoading } = useContext(SpeakerBriefContext)
    const { theme } = useContext(ThemeContext)
    const nav = useNavigate()
    const location = useLocation();
    const [viewMode, setViewMode] = useState("LIST")

    useEffect(() => {
        applyTheme()
        setViewMode("LIST")
        setBreadcrumbMenu([
            { slug: '/speaker-brief', menuName: 'Speaker Brief', isActive: true, hasSubMenu: true },
            { slug: `/speaker-brief/dashboard`, menuName: "Events", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        CARD_TITLE: ""
    });
    const [allEvents, setAllEvents] = useState([])
    const [events, setEvents] = useState([])
    const [searchEvents, setSearchEvents] = useState("")

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "SPEAKER_BRIEF").items;
        setLabels(items)
    }, [homeLabel])

    const applyTheme = () => {
        const appThemes = getAppBehaviour();
        if (theme) {
            appendThemeProperty(theme, true, true)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme, true, true)
            }
        }
    }

    useEffect(() => {
        setAllEvents(eventList)
    }, [eventList])

    useEffect(() => {
        applyTheme()
        if(allEvents && allEvents.length){
            const filteredEvents = allEvents.filter(event => event.eventName.toLowerCase().includes(searchEvents.toLowerCase()));
            setEvents(filteredEvents)
        }
        else {
            setEvents([])
        }
    }, [searchEvents, allEvents])

    const getEventsInfo = (event) => {
        if (event.data.processingStatus.toLowerCase() === 'completed') {
            nav(`/speaker-brief/events/${generateSBEventId(event.data.eventId)}/info`)
        }
    }

    const tableValues = (info, field) => {
        const event = info;
        if (event) {
            if (field === 'NAME') {
                return (
                    <div className="d-flex align-items-center">
                        <div className="">
                            <p className="mb-0 text-nowrap">{event.eventName}
                                {event.processingStatus.toLowerCase() === 'pending' ?
                                    <img src="https://i.gifer.com/origin/4a/4a287dd4b9222ebb17dc354257d0ef79_w200.gif" className="ml-2" height="13px" /> :
                                    event.publishedStatus.toLowerCase() === 'draft' ? <i className="fa fa-fw fa-pencil-square-o text-danger ml-2" aria-hidden="true" title="Draft"></i> : null}
                            </p>
                        </div>
                    </div>
                )
            }
            else if (field === 'ID') {
                return <span>{generateSBEventId(event.eventId)}</span>
            }
            else if (field === 'STATUS') {
                return (
                    getEventStatus(event)
                )
            }
            else if (field === 'MODEL') {
                return event.modelDisplayName;
            }
            else if (field === 'CREATED_BY') {
                return event.createdUserName ? event.createdUserName : event.createdBy;
            }
            else if (field === 'MODIFIED_BY') {
                return event.lastModifiedUserName ? event.lastModifiedUserName : event.lastModifiedBy;
            }
            else if (field === 'CREATED_DATE') {
                return moment(event.createdOn).format('MMM DD, YYYY hh:mm A')
            }
            else if (field === 'MODIFIED_DATE') {
                return event.lastModifiedBy ? moment(event.modifiedOn).format('MMM DD, YYYY hh:mm A') : '';
            }
        }
        else return ''
    }

    const getEventStatus = (event) => {
        return (
            event.processingStatus.toLowerCase() === 'completed' ? event.publishedStatus.toLowerCase() === 'published' ? <span className="badge badge-info">Published</span> : <span className="text-danger">[ Draft ]</span>
                : event.processingStatus.toLowerCase() === 'pending' ? <span className="badge badge-warning">Processing</span> :
                    <span className="text-danger"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Failed</span>
        )
    }

    const getGridCardColorOnStatus = (event) => {
        if (event.processingStatus.toLowerCase() === 'completed') {
            if (event.publishedStatus.toLowerCase() === 'published') {
                return "bl-sb-tiles-bg-published";
            }
            else {
                return "border-danger";
            }
        }
        else {
            return "bl-sb-tiles-bg-process";
        }
    }

    const eventsInGridViewContent = () => {
        return (
            events && events.map((info, i) => {
                return (
                    <div key={`grid-view-enets-${i}`} className="col-2">
                        <div className={`card ${getGridCardColorOnStatus(info)} mb-3 cp`} onClick={() => getEventsInfo({data:info})}>
                            <div className="card-body">
                                <div className="card-title d-flex flex-row align-items-center justify-content-between">
                                    <span className="text-muted"><i className="fa fa-fw fa-calendar bl-font-larger" aria-hidden="true"></i> <span className="bl-sb-tiles_footer">Created {moment(info.createdOn).format('MMM DD, YYYY hh:mm A')}</span></span>
                                    <div className="dropdown no-arrow d-none">
                                        <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-left shadow-sm animated--fade-in" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="#"><i className="fa fa-pencil mr-2" aria-hidden="true"></i> Edit Event</a>
                                            <a className="dropdown-item" href="#"><i className="fa fa-trash-o mr-2" aria-hidden="true"></i> Delete</a>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="card-subtitle mt-5 mb-1 bl-truncate-line-2 bl-h-3rem" title={info.eventName}>{info.eventName}</h5>
                                <p className="text-muted mb-0 bl-truncate-line-1 font-small">{getEventStatus(info)}</p>
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    const eventsInListViewContent = () => {
        return (
            <div className="col-12">
                <DataTable value={events} rowClassName={"bl-source-overview-list position-relative"} className="cp table" selectionMode="single" onRowClick={getEventsInfo}>
                    <Column field="id" header="Id" body={(e) => tableValues(e, 'ID')}></Column>
                    <Column field="eventName" header="Name" body={(e) => tableValues(e, 'NAME')}></Column>
                    <Column field="publishedStatus" header="Status" body={(e) => tableValues(e, 'STATUS')}></Column>
                    <Column field="modelDisplayName" header="Model Name" body={(e) => tableValues(e, 'MODEL')}></Column>
                    <Column field="createdBy" header="Created By" body={(e) => tableValues(e, 'CREATED_BY')}></Column>
                    <Column field="createdOn" header="Created On" dataType="date" body={(e) => tableValues(e, 'CREATED_DATE')}></Column>
                </DataTable>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-12">
                        <section className="mb-3">
                            <div className="media p-2">
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src={sblogo} alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <div className="border-bottom">
                                                <h6 className="mb-3">{labels.CARD_TITLE}</h6>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex">
                                                        <span className={`badge bl-chip-btn cp bl-sb-datatableTabs ${viewType === 'ALL' ? 'active' : ''}`} onClick={() => setViewType('ALL')}>All Events</span>
                                                        <span className={`badge bl-chip-btn cp bl-sb-datatableTabs mx-2 ${viewType === 'DRAFT' ? 'active' : ''}`} onClick={() => setViewType('DRAFT')}><i className="fa fa-pencil-square-o text-danger" aria-hidden="true" title="Draft"></i>In Draft<i className="text-muted">(only visible to you)</i></span>
                                                        <button className="btn btn-danger btn-sm rounded" onClick={() => { nav(`/speaker-brief/events/manage`) }}><i className="fa fa-fw fa-plus-circle mr-1" aria-hidden="true"></i>Create New Event</button>
                                                    </div>
                                                    <div className="d-flex align-items-center">

                                                        <div className="btn-group btn-group-sm mr-2" role="group" aria-label="Small button group">
                                                            <button type="button" className="btn btn-secondary bg-white text-dark border-0" onClick={() => setViewMode("GRID")}><i className="fa fa-th-large" aria-hidden="true"></i></button>
                                                            <button type="button" className="btn btn-secondary bg-white text-dark border-0" onClick={() => setViewMode("LIST")}><i className="fa fa-bars" aria-hidden="true"></i></button>
                                                        </div>

                                                        <div className="input-group w-auto">
                                                            <input type="text" className="form-control form-control-sm" value={searchEvents} onChange={(e) => setSearchEvents(e.target.value)} placeholder="Search by event name" />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="fa fa-search font-small"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <section className="card-filter py-3 d-none">
                                                    <div className="col-xl-12 col-md-12">
                                                        <div className="form-row blc_button align-items-end">
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="inputState">Industry</label>
                                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                                    <option>All...</option>
                                                                    <option>...</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="emprangestart">Total Employee Range</label>
                                                                <div className="d-flex">
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="emprangestart" placeholder="From" />
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem blc_button" id="emprangeend" placeholder="To" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="scorerangestart">Score Range</label>
                                                                <div className="d-flex">
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="scorerangestart" placeholder="From" />
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem blc_button" id="scorerangeend" placeholder="To" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="ebitdarangestart">Adj EBITDA</label>
                                                                <div className="d-flex">
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="ebitdarangestart" placeholder="From" />
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem blc_button" id="ebitdarangeend" placeholder="To" />
                                                                </div>
                                                            </div>
                                                            <button className="btn btn-filter btn-sm">Apply Filter</button>
                                                            <button className="btn btn-link btn-sm bl-filter-clear">Clear All</button>

                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        {
                                            isLoading ? <div className="col text-center">
                                                <img className="bl-list-loader" src={listViewLoader} />
                                            </div> : viewMode === "GRID" ? eventsInGridViewContent() : eventsInListViewContent()
                                        }

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        </div>
    )
}