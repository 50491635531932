import React, { useState } from 'react';

export const ModelEntityContext = React.createContext();

export const ModelEntityContextProvider = ({ children }) => {

    const [tableList, setTableList] = useState([]);
    const [tableBasicInfo, setTableBasicInfo] = useState(null);
    const [selectedDataSource, setSelectedDataSource] = useState("snowflake");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <ModelEntityContext.Provider value={{ tableList, setTableList, tableBasicInfo, setTableBasicInfo, selectedDataSource, setSelectedDataSource, isLoading, setIsLoading }}>
            {children}
        </ModelEntityContext.Provider>
    );
};
